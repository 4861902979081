import { useCallback, useState, useEffect } from 'react';
import { ListFilterData } from './list-filter.component';

interface HookProps {
  filterValues?: ListFilterData;
}

export const useListFilter = ({ filterValues }: HookProps) => {
  // redux
  const [filter, setFilter] = useState<ListFilterData>(filterValues || {});

  const resetFilter = useCallback(() => {
    setFilter({});
  }, []);

  return {
    filter,
    setFilter,
    resetFilter,
  };
};

import { get, set } from 'lodash';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Button } from '@mui/material';
import { CampaignLeadItem, FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { ListFilterConfig } from 'src/app/common/components/list-filter/list-filter.component';
export const getColumns = ({
  t,
  update,
  activityStatusFeedback,
}: {
  t: (val: string) => string;
  update?: (item: CampaignLeadItem) => void;
  activityStatusFeedback: Array<{ key: string; value: string }>;
}): ProColumns<CampaignLeadItem>[] => [
  {
    title: t('name'),
    dataIndex: 'name',
    width: '290px',
    sorter: true,
  },
  {
    title: t('Servicing status'),
    dataIndex: 'feedback',
    width: '176px',
    sorter: true,
    render: (currValue: string, item) => {
      return getAtivityStatusFeedbackValue(currValue, activityStatusFeedback);
    },
  },
  {
    title: t('campaign.list.campaignName'),
    dataIndex: 'campaignName',
    width: '290px',
  },

  {
    title: t('campaign.list.lastUpdated'),
    dataIndex: 'updatedAt',
    width: '176px',

    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('prospect.appoinment.title.operation'),
    width: '176px',
    dataIndex: 'op',
    sticky: true,
    render: (currValue: string, item) => {
      return (
        <span>
          <Button
            variant="text"
            color="info"
            onClick={() => {
              update && update(item);
            }}
          >
            {t('app.button.update')}
          </Button>
        </span>
      );
    },
  },
];

export const getFilterConfig: (t: (val: string) => string, activityStatusFeedback: any) => Array<ListFilterConfig> = (
  t,
  activityStatusFeedback,
) => [
  {
    key: 'feedback',
    title: t('Servicing Activity Status'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    // items: [
    //   { itemKey: 'pedding', itemLabel: t('peddingCall') },
    //   {
    //     itemKey: 'contacted',
    //     itemLabel: t('contacted'),
    //   },
    // ],
    items: activityStatusFeedback
      ? activityStatusFeedback.map((item: any) => ({
          itemKey: item.key,
          itemLabel: item.value,
        }))
      : [],
  },
];

export const getAtivityStatusFeedbackValue = (
  key: string,
  activityStatusFeedback: Array<{ key: string; value: string }>,
) => {
  const item = activityStatusFeedback?.find((item) => item.key === key);
  return item?.value ?? '';
};

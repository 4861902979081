import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './prospect-basic-info.style';
import { useProspectBasicInfo } from './prospect-basic-info.hook';
import { ProspectDetail, ProspectGenderEnum, ProspectTypeEnum } from 'src/app/modules/Prospect/types/prospect-types';
import { ProspectCategoryLabel } from 'src/app/common/components/prospect-category/prospect-category.component';
import { reconstuctNameByLastName } from 'src/app/common/utils/string.util';
import { checkDateEquals, getEnumKeyByEnumValue } from 'src/app/modules/Prospect/util/common.util';
import { FemaleOutlined, MaleOutlined, CakeOutlined } from '@mui/icons-material';

type ComponentProps = {
  /* otherProp: string */
  datail: ProspectDetail;
  prospectCategory: any;
};

export const ProspectBasicInfo: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { datail, prospectCategory } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  return (
    <>
      {datail && (
        <div className={styles.wrap}>
          {prospectCategory && <ProspectCategoryLabel data={prospectCategory} />}
          <div className={styles.marginLeft}>
            <div className={styles.flex_row}>
              <Typography variant="h4" fontWeight={700}>
                {datail.displayName || reconstuctNameByLastName(datail.firstName, datail.lastName)}
              </Typography>
              {datail.gender === ProspectGenderEnum.salesFemale ? <FemaleOutlined className={styles.icon} /> : null}
              {datail.gender === ProspectGenderEnum.salesMale ? <MaleOutlined className={styles.icon} /> : null}
              {datail.birthDate && checkDateEquals(datail.birthDate, new Date()) ? (
                <CakeOutlined className={styles.icon} />
              ) : null}
            </div>
            <div className={styles.flex_row}>
              {datail.types &&
                datail.types.map((type, index) => (
                  <React.Fragment key={type}>
                    <Typography fontSize={'16px'} color={'#666666'} lineHeight={'24px'}>
                      {Translation(type)}
                    </Typography>
                    {index !== datail.types.length - 1 ? (
                      <Typography fontSize={'16px'} color={'#666666'} lineHeight={'24px'} margin={'0 8px'}>
                        {' '}
                        |{' '}
                      </Typography>
                    ) : null}
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
});

import React, { memo, useMemo } from 'react';
import { Box } from '@mui/material';
import { useStyles } from './tag.style';

export type TagProps<T extends string | number> = {
  value: T;
  configs: Record<T, { color: string; bgColor: string; text: string }>;
};

const TagComp = <T extends string | number>({ value, configs }: TagProps<T>): JSX.Element => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <Box
      height={24}
      borderRadius={'4px'}
      paddingLeft={'8px'}
      paddingRight={'8px'}
      color={configs[value]?.color}
      bgcolor={configs[value]?.bgColor}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <span className={styles.tagText}>{configs[value]?.text ?? ''}</span>
    </Box>
  );
};

export const Tag = memo(TagComp) as typeof TagComp;

import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Card } from '@mui/material';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
  TimelineDot,
  timelineOppositeContentClasses,
} from '@mui/lab';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-approval.style';
import { useTabApproval } from './tab-approval.hook';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { WorkflowApprovalStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import { getDisplayDate } from 'src/app/common/utils/time-utils';
import RemarksItem from '../../Details/approval/components/RemarksItem';
import { LouTemplate } from '../../Details/approval/components/LouTemplate';
import { isEmptyArray } from 'formik';

type ComponentProps = ParamsProps & {
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  approvalHistoryList: any;
  campaignObjId: string | undefined;
  remarkList: any;
  submitData?: { submitDate: Date; submitBy: string; cancelDate: Date; canceledBy: string; cancelReason?: string };
  approvalData?: any;
  previousApprovalData?: any;
};

export const TabApprovalComponent: React.FC<ComponentProps> = memo(
  ({
    campaignObjId,
    approvalHistoryList,
    remarkList,
    submitData,
    approvalData,
    previousApprovalData = [],
  }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { remarks, isCanceled } = useTabApproval({ approvalHistoryList, submitData, remarkList });
    return (
      <>
        <Card elevation={3} className={styles.approvalCard}>
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.1,
              },
            }}
          >
            {submitData ? (
              <>
                {!!submitData.canceledBy && (
                  <TimelineItem>
                    <TimelineOppositeContent className={styles.time}>{`${getDisplayDate(
                      submitData.cancelDate,
                      'DD/MM/YYYY HH:mm',
                    )}`}</TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" className={styles.dot} />
                      {!isCanceled && approvalHistoryList.length === 0 ? null : (
                        <TimelineConnector className={styles.connector} />
                      )}
                    </TimelineSeparator>
                    <TimelineContent className={styles.context}>
                      <div>{Translation('agencyCampaign.common.status.canceled')}</div>
                      <div>
                        {`${Translation('agencyCampaign.common.status.canceled')} ${Translation('common.by')} ${
                          submitData.canceledBy
                        }`}
                      </div>
                      <div>{submitData.cancelReason}</div>
                    </TimelineContent>
                  </TimelineItem>
                )}
                {approvalHistoryList.reverse().map((approvalHistory: any, index: number) => (
                  <TimelineItem
                    className={`${
                      index !== approvalHistoryList.length - 1 &&
                      approvalHistory.status === WorkflowApprovalStatusEnum.APPROVED
                        ? styles.olderRecord
                        : ''
                    }`}
                    key={`approvalHistoryList${index}`}
                  >
                    <TimelineOppositeContent className={styles.time}>
                      {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                        `${getDisplayDate(approvalHistory.submitDate, 'DD/MM/YYYY HH:mm')}`}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot color="secondary" className={styles.dot} />
                      <TimelineConnector className={styles.connector} />
                    </TimelineSeparator>
                    <TimelineContent className={styles.context}>
                      <div>
                        {approvalHistory.role.join(', ')} {Translation('agencyCampaign.approval')}
                      </div>
                      <div>
                        {Translation(`agencyCampaign.common.status.${approvalHistory.status}`)}
                        {approvalHistory.status !== WorkflowApprovalStatusEnum.PENDING &&
                          ` ${Translation('common.by')} ${approvalHistory.assigneeName}`}
                      </div>
                      {approvalHistory.comment && (
                        <div className={styles.comment}>
                          {Translation('agencyCampaign.approval.comment')}: {approvalHistory.comment}
                        </div>
                      )}
                    </TimelineContent>
                  </TimelineItem>
                ))}
                <TimelineItem
                  className={`
                  ${approvalHistoryList.length > 0 ? styles.olderRecord : ''}`}
                >
                  <TimelineOppositeContent className={styles.time}>
                    <div>{`${getDisplayDate(submitData.submitDate, 'DD/MM/YYYY HH:mm')}`}</div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" className={styles.dot} />
                    {!isCanceled && previousApprovalData.length === 0 ? null : (
                      <TimelineConnector className={styles.connector} />
                    )}
                  </TimelineSeparator>
                  <TimelineContent className={styles.context}>
                    <div>{Translation('agencyCampaign.approval.submit')}</div>
                    <div>{`${Translation('agencyCampaign.approval.submittedBy')} ${submitData.submitBy}`}</div>
                  </TimelineContent>
                </TimelineItem>
              </>
            ) : (
              //approval not submit yet
              <>
                {approvalData?.length
                  ? approvalData.reverse().map((data: any, idx: number) => (
                      <TimelineItem
                        className={`${approvalData.length > 0 ? styles.olderRecord : ''}`}
                        key={`approvalData${idx}`}
                      >
                        <TimelineOppositeContent className={styles.time}>-</TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineDot color="secondary" className={styles.dot} />
                          <TimelineConnector className={styles.connector} />
                        </TimelineSeparator>
                        <TimelineContent className={styles.context}>
                          <div>{String(data.labelName)}</div>
                        </TimelineContent>
                      </TimelineItem>
                    ))
                  : null}
                <TimelineItem className={`${approvalData.length > 0 ? styles.olderRecord : ''}`}>
                  <TimelineOppositeContent className={styles.time}>-</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" className={styles.dot} />
                    {previousApprovalData.length === 0 ? null : <TimelineConnector className={styles.connector} />}
                  </TimelineSeparator>
                  <TimelineContent className={styles.context}>
                    <div>{Translation('agencyCampaign.approval.submit')}</div>
                    <div>{Translation('agencyCampaign.common.status.pending')}</div>
                  </TimelineContent>
                </TimelineItem>
              </>
            )}
            {previousApprovalData?.length > 0 &&
              previousApprovalData.reverse().map((approval: any, index: number) => (
                <TimelineItem
                  className={`${
                    index !== previousApprovalData.length - 1 && approval.status === WorkflowApprovalStatusEnum.APPROVED
                      ? styles.olderRecord
                      : ''
                  }`}
                  key={`previousApprovalData${index}`}
                >
                  <TimelineOppositeContent className={styles.time}>
                    {approval.status !== WorkflowApprovalStatusEnum.PENDING &&
                      `${getDisplayDate(approval.submitDate, 'DD/MM/YYYY HH:mm')}`}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot color="secondary" className={styles.dot} />
                    {index === previousApprovalData.length - 1 ? null : (
                      <TimelineConnector className={styles.connector} />
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent className={styles.context}>
                    <div>
                      {approval.role.join(', ')}
                      {approval.type === 'submit'
                        ? Translation('agencyCampaign.approval.submit')
                        : approval.type === 'cancel'
                          ? Translation('agencyCampaign.common.status.canceled')
                          : ` ${Translation('agencyCampaign.approval')}`}
                    </div>
                    <div>
                      {approval.type === 'cancel'
                        ? Translation('agencyCampaign.common.status.canceled')
                        : approval.type === 'submit'
                          ? Translation('agencyCampaign.approval.submittedBy')
                          : Translation(`${approval.status}`)}
                      {approval.status !== WorkflowApprovalStatusEnum.PENDING &&
                        ` ${Translation('common.by')} ${approval.assigneeName} ${getDisplayDate(
                          approval.submitDate,
                          'DD/MM/YYYY HH:mm',
                        )}`}
                      <div>{approval.reason}</div>
                    </div>
                    {approval.comment && (
                      <div>
                        {Translation('agencyCampaign.approval.comment')}: {approval.comment}
                      </div>
                    )}
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </Card>
        {!isEmptyArray(remarks) && (
          <Card elevation={3} className={styles.remarkCard}>
            <div className={styles.remarkCardContainer}>
              <div className={classes.sectionTitle}>{Translation('agencyCampaign.common.remarks')}</div>
              <div className={styles.remarkCardContentContainer}>
                <div className={styles.flex_1}>
                  {remarks &&
                    remarks.map((item: any, index: Number) => {
                      return (
                        <React.Fragment key={`reamark${index}`}>
                          {item.sendTypes.includes('applicant') || item.sendTypes.includes('participant') ? (
                            <div className={classes.level2}>
                              {`${item.updatedBy} ${getDisplayDate(item.updatedAt, 'DD/MM/YYYY HH:mm')}`}
                              <div className={styles.remarkCardFilesContainer}>
                                {item.resourceIds &&
                                  (item.resourceIds as string[]).map((item, index) => {
                                    return <RemarksItem RemarksItem={item} index={index} key={index} />;
                                  })}
                                {item.type === 'variableContent' && (
                                  <LouTemplate campaignObjId={campaignObjId} remarksItem={item} />
                                )}
                              </div>
                            </div>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </Card>
        )}
      </>
    );
  },
);

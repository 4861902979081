import { ProColumns } from 'src/app/common/components/ProTable';
import { Box, Typography } from '@mui/material';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { DateFormat, checkDateEquals, formatDate, reconstuctNameByLastName } from 'src/app/common/utils';
import CakeIcon from '@mui/icons-material/Cake';
import { GenderMap, ProspectSourceTypesConfig, ProspectTypesConfig } from 'src/app/modules/AgencyCampaign/constants';
import {
  Prospect,
  ProspectCategory,
  ProspectSourceTypes,
  ProspectTypes,
} from 'src/app/modules/Prospect/types/prospect-types';
import { getDateKey } from 'src/app/modules/Prospect/util/purge-prospect.util';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import moment from 'moment';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';

export const getColumns = ({
  t,
  startDate,
  campaignOwner,
  disabledEdit,
  disabled,
  setRemoveOption,
}: {
  t: (val: string) => string;
  startDate?: string;
  campaignOwner: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  setRemoveOption: (val: any) => void;
}): ProColumns<AgentItem>[] => [
  {
    title: t('agencyCampaign.common.designation'), // todo： change to Unit
    dataIndex: 'unit',
    width: '176px',
    sorter: true,
  },
  {
    title: t('agencyCampaign.common.businessName'),
    dataIndex: 'agencyCampaign.common.businessName',
    width: '176px',
    sorter: true,
    render: (currValue: string, item) => {
      return item.name?.enUs?.displayName as string;
    },
  },
  {
    title: t('agencyCampaign.common.agentCode'),
    dataIndex: 'agentCode',
    width: '176px',
    sorter: true,
  },
  {
    title: t('agencyCampaign.common.rsvp'),
    dataIndex: 'rsvp',
    width: '176px',
    render: (currValue: string[], item) => {
      const { invitationTime, rsvpIndicator } = item;
      return invitationTime !== undefined && rsvpIndicator !== undefined && rsvpIndicator !== 'Pending'
        ? rsvpIndicator
        : moment().isAfter(startDate)
          ? 'No Response' // todo
          : invitationTime !== undefined && rsvpIndicator !== undefined
            ? rsvpIndicator
            : '-';
    },
  },
  {
    title: t('agencyCampaign.common.action'),
    dataIndex: 'action',
    width: '176px',
    render: (_, item) => {
      if (disabledEdit || disabled) return ''; // todo: previous portal is to hide this column
      if (item.agentCode === campaignOwner) return '';
      return (
        <ProTableOperationBtnComponent
          label={t('app.button.remove')}
          onClick={() => {
            //TODO: do single delete
            setRemoveOption({
              open: true,
              applicantId: item.agentCode,
            });
          }}
        />
      );
    },
  },
];

import React, { FC, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { AgentItem, CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { checkAgentImportFail } from '../../../Details/application/components/ImportParticipantPopup';
import { getDisplayDate, toAbsoluteUrl } from 'src/app/common/utils';
import { Button } from 'src/app/common/components/button/button.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useStyles } from './participant-add-file.style';

interface HookProps {
  campaignObjId: string;
  campaignTypeStructureData: CampaignStructureItem;
  doneImport: (data: AgentItem[]) => void;
}

interface ImportItem {
  agentCode: string;
  businessName?: string;
  unitCode?: string;
  unit?: string;
  reason?: string;
}

type ManualUpdateFormState = {
  file: File;
  participantListfromFile: any[];
  participantListFormatError: string[];
  successImportList: AgentItem[];
  failImportList: AgentItem[];
  failEligibilityList: AgentItem[];
  successEligibilityList: AgentItem[];
  importedAgentList: AgentItem[];
};

type ManualUpdateFormAction = {
  type: 'MODIFY_FIELD';
  payload: {
    field: keyof ManualUpdateFormState;
    value: any;
  };
};

const formReducer = (state: ManualUpdateFormState, action: ManualUpdateFormAction): ManualUpdateFormState => {
  switch (action.type) {
    case 'MODIFY_FIELD':
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

const initialState: any = {
  participantListfromFile: [],
  participantListFormatError: [],
  successImportList: [],
  failImportList: [],
  failEligibilityList: [],
  successEligibilityList: [],
  importedAgentList: [],
};

const REACT_APP_AGENT_CODE_LENGTH = window.envConfig['REACT_APP_AGENT_CODE_LENGTH'];
const agentCodeLength = Number.parseInt(REACT_APP_AGENT_CODE_LENGTH) || 5;

export const useParticipantAddFile = ({ campaignObjId, campaignTypeStructureData, doneImport }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const TranslationWithVariable = (key: string, count: number | string) =>
    intl.formatMessage({ id: key }, { num: count });
  const { t } = useTranslation();

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // redux
  const dispatch = useDispatch();
  const [formState, formDispatch] = useReducer(formReducer, initialState);
  // const [importFile, setImportFile] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);
  const [toggleECheckDialog, setToggleECheckDialog] = useState(false);
  const [handlingFile, setHandlingFile] = useState(false);

  const participantSectionTypeStructureData = campaignTypeStructureData.sections.filter(
    (item) => item.key === 'participant',
  )[0];
  const participantNameRules =
    participantSectionTypeStructureData.sectionFields.filter((item) => item.key === 'participantName').length > 0
      ? participantSectionTypeStructureData.sectionFields.filter((item) => item.key === 'participantName')[0].rules
      : [];
  const eligibilityRules: string[] =
    participantNameRules.filter((item) => item.key === 'eligibility').length > 0
      ? participantNameRules.filter((item) => item.key === 'eligibility')[0].value
      : [];
  const needEligibilityCheck = (eligibilityRules as string[]).length > 0;

  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHandlingFile(true);
    if (e.target.files) {
      let reader = new FileReader();
      reader.onload = (evnet) => {
        let csvData = String(reader.result);
        let row = csvData.split('\n');
        let participantList: ImportItem[] = [];
        let arrParticipantListFormatError: any[] = [];
        row.shift();
        row.forEach((item, index) => {
          if (!item) {
            return;
          }
          // item format: 10006,Name F,U102(AgentCode|BusinessName|UnitCode)
          let [agentCode, businessName, unitCode] = item.split(',');
          agentCode = agentCode?.trim()?.padStart(agentCodeLength, '0');
          businessName = businessName?.trim();
          unitCode = unitCode?.trim();
          participantList.push({ agentCode, businessName, unitCode });
          let err = TranslationWithVariable('agencyCampaign.import.row', index + 1);
          if (!agentCode) {
            err += Translation('agencyCampaign.import.agentcode.required');
          } else if (agentCode.length > agentCodeLength) {
            err += TranslationWithVariable('agencyCampaign.import.agentode.invalid', agentCodeLength);
          }
          if (!businessName) {
            err += Translation('agencyCampaign.import.businessName.required');
          }
          if (!unitCode) {
            err += Translation('agencyCampaign.import.unitCode.required');
          }
          if (err.length > 10) {
            arrParticipantListFormatError.push(err);
          }
          // let rowItems = item.split(',');
          // if (rowItems[0]) {
          //   participantList.push(preFillZero(rowItems[0].replace(/\D/g,'')));
          // }
        });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: participantList } });
        formDispatch({
          type: 'MODIFY_FIELD',
          payload: { field: 'participantListFormatError', value: arrParticipantListFormatError },
        });
      };
      if (e.target.files) {
        reader.readAsBinaryString(e.target.files[0]);
        // setImportFile(e.target.files[0]);
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'file', value: e.target.files[0] } });
      }
    }
    setHandlingFile(false);
  };

  const onImportFromFile = async () => {
    // downline and eligiblity check
    setLoading(true);
    const { failList, successList } = await checkAgentImportFail(
      formState.participantListfromFile,
      campaignTypeStructureData,
      campaignObjId,
      eligibilityRules,
      dispatch,
      locale,
    ).catch(() => {
      setLoading(false);
      return { failList: [], successList: [] };
    });
    setLoading(false);
    if (needEligibilityCheck && failList.length > 0) {
      formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: failList } });
      if (successList.length > 0) {
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successImportList', value: successList } });
        formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: successList } });
      }
    } else {
      doneImport(needEligibilityCheck ? successList : successList.concat(failList as any));
    }
  };

  const removeFile = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'file', value: null } });
    // setImportFile(undefined);
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'participantListfromFile', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: [] } });
  };

  const removeAndProceed = async () => {
    doneImport(formState.importedAgentList);
  };

  const onPreviousFromFailImport = () => {
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'failImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'successImportList', value: [] } });
    formDispatch({ type: 'MODIFY_FIELD', payload: { field: 'importedAgentList', value: [] } });
  };

  const formatFileSize = (bytes: number) => {
    const kb = 1024;
    const mb = kb * 1024;
    const gb = mb * 1024;

    if (bytes < kb) {
      return `${bytes} B`;
    } else if (bytes < mb) {
      return `${(bytes / kb).toFixed(2)} KB`;
    } else if (bytes < gb) {
      return `${(bytes / mb).toFixed(2)} MB`;
    } else {
      return `${(bytes / gb).toFixed(2)} GB`;
    }
  };

  const _renderUploadedFileDetail = () => {
    return (
      <>
        <div className={styles.uploadFileDetailContainer}>
          <div className={styles.uploadFileDetailButton}>
            <span className={styles.uploadFileDetailTitle}>
              {Translation('agencyCampaign.lead.upload.fileName')}:
              <span className={styles.uploadFileDetailContent}>{formState.file?.name}</span>
            </span>
            <Button variant="outlined" onClick={removeFile}>
              {Translation('app.button.delete')}
            </Button>
          </div>
          <div className={styles.uploadFileSize}>
            <span className={styles.uploadFileDetailTitle}>
              {Translation('agencyCampaign.lead.upload.fileSize')}:
              <span className={styles.uploadFileDetailContent}>{formatFileSize(formState.file?.size as number)}</span>
            </span>
          </div>
          <div className={styles.mt_12}>
            <span className={styles.uploadFileDetailTitle}>
              {Translation('agencyCampaign.lead.upload.updateTime')}:
              <span className={styles.uploadFileDetailContent}>
                {formState.file?.lastModified
                  ? getDisplayDate(new Date(formState.file?.lastModified), 'DD-MM-YYYY HH:mm')
                  : getDisplayDate(new Date(), 'DD-MM-YYYY HH:mm')}
              </span>
            </span>
          </div>
          {/* {_renderLeadTable()} */}
        </div>
      </>
    );
  };

  useEffect(() => {
    setToggleECheckDialog(formState.failImportList && formState.failImportList.length > 0);
  }, [formState]);

  return {
    loading,
    formState,
    toggleECheckDialog,
    handlingFile,
    // importFile,
    t,
    setToggleECheckDialog,
    formDispatch,
    handleFile,
    onImportFromFile,
    removeAndProceed,
    removeFile,
    onPreviousFromFailImport,
    _renderUploadedFileDetail,
  };
};

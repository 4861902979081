import { Dispatch } from 'react';
import { AGENT_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { CampaignTypeEnum } from '../types/campaign-types';
import { GlobalHelper } from 'src/app/common/helpers/GlobalHelpers';

export const fetchCampaignUnreadCount = async (query?: any, dispatch?: Dispatch<any>) => {
  const params = { agentCode: GlobalHelper.getAgentCode() };
  const path = `${AGENT_URL}/agents/me/campaigns/all-unread-campaign-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMarketingCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
    type: CampaignTypeEnum.normal,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/onboard-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchServicingCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/servicing-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchMassCampaignList = async (query?: any, dispatch?: Dispatch<any>) => {
  const params: any = {
    ...query,
    limit: 1000,
    page: 1,
    type: CampaignTypeEnum.mass,
  };
  const path = `${AGENT_URL}/agents/me/campaigns/onboard-count`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchCampaignDetail = async (id: string, dispatch?: Dispatch<any>) => {
  const params: any = {
    page: 1,
    limit: 20,
    sortBy: 'leadStatus',
  };
  //https://phkl-apim-nprd.prudential.com.hk/pruforce/agent/coedev/agents/me/campaigns/658e2c39d5abdbe28f406b6a
  const path = `${AGENT_URL}/agents/me/campaigns/${id}`;
  return apiClient
    .get<any>(path, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const campaignAcceptLeads = async (ids: string[], dispatch?: Dispatch<any>) => {
  const requestBody = { ids: ids, agentStatus: 'accepted', rejectedReason: '' };
  const path = `${AGENT_URL}/agents/me/leads/agent-status`;
  return apiClient
    .patch<any>(path, requestBody)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const fetchActivityStatusFeedback = async (campaignId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const path = `${AGENT_URL}/agents/me
  }/campaigns/feedbacks/${campaignId}`;

  return apiClient
    .get<any>(path)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const patchLeadDetail = async (id: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  let path = `${AGENT_URL}/agents/me/leads/${id}/update`;
  return apiClient
    .patch<any>(path, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getUnreadCampaign = async () => {
  const agentCode = GlobalHelper.getAgentCode();
  const path = `${AGENT_URL}/agents/me/campaigns/all-unread-campaign-count`;
  return apiClient
    .get<any>(path, { params: { agentCode, groupByType: true } })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err);
    });
};

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './pro-table-operation-btn.style';
import { useProTableOperationBtn } from './pro-table-operation-btn.hook';

type ComponentProps = ParamsProps & {
  label: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

export const ProTableOperationBtnComponent: React.FC<ComponentProps> = memo(({ label, onClick }: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  // const { loading, data, run, handlePress } = useProTableOperationBtn({ id: 1 });
  return (
    <Button onClick={onClick} className={styles.operationBtn}>
      {label}
    </Button>
  );
});

import React, { FC, useState, useMemo, ComponentProps, useImperativeHandle } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './prospect-information.style';
import { useProspectInformation } from './prospect-information.hook';
import Form, { Item } from 'src/app/common/components/Form';
import { ProspectCategoryPicker } from '../../../add-prospect/components/prospect-category-picker/prospect-category-picker.component';
import { ProspectDetail } from 'src/app/modules/Prospect/types/prospect-types';
import { is } from 'date-fns/locale';
import { ReferredBy } from '../../../components/referred-by/referred-by.component';
import { CategoryHistoryComponent } from '../category-history/category-history.component';
import { ProspectCategoryProps } from 'src/app/common/components/prospect-category/prospect-category.component';
import { DeleteProspectDialog } from '../delete-prospect-dialog/delete-prospect-dialog.component';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

type PageProps = ParamsProps &
  RouteComponentProps & {
    prospectDetail: ProspectDetail;
    readOnly?: boolean;
  } & ComponentProps<'base'>;

type InputItemProps = {
  name: string;
  label: string;
  defaultValue?: string | number | boolean;
  required?: boolean;
  isSelect?: boolean;
  selectOptions?: { label: string; value: string | boolean }[];
  readonly?: boolean;
  filedError?: {
    error?: string;
  };
};

const InputItem = (props: InputItemProps) => {
  const { name, label, defaultValue, required, isSelect, selectOptions, readonly, filedError } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  return (
    <Item name={name} hideError>
      <TextField
        label={label}
        variant="outlined"
        defaultValue={defaultValue}
        required={required}
        className={styles.input}
        size="medium"
        select={isSelect}
        SelectProps={{
          size: 'medium',
        }}
        inputProps={{
          readOnly: readonly,
        }}
        error={Boolean(filedError)}
        helperText={filedError?.error ?? ''}
      >
        {isSelect &&
          selectOptions &&
          selectOptions.map((item, index) => (
            <MenuItem key={`${item.value}_${index}`} value={item.value.toString()}>{`${item.label}`}</MenuItem>
          ))}
      </TextField>
    </Item>
  );
};

export const ProspectInformationPage = React.forwardRef((props: PageProps, ref) => {
  const { prospectDetail, readOnly } = props;
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const {
    form,
    formModel,
    selectionPools,
    showReferrby,
    selectedReferrBy,
    region,
    cityList,
    requiredFields,
    filedErrors,
    showDeleteDialog,
    onSelectReferrBy,
    setShowReferrby,
    onFiledChange,
    onCategoryChange,
    onDeleteDialogClose,
    deleteProspect,
    submit,
    onReferredByClick,
    onBirthDateChange,
  } = useProspectInformation({
    prospectDetail: prospectDetail,
  });

  const submitForm = () => {
    submit();
  };

  const resetForm = () => {
    form.resetFields();
  };

  useImperativeHandle(ref, () => ({
    submitForm: submitForm,
    resetForm: resetForm,
  }));

  return (
    <div>
      <Form initialValues={formModel} form={form} onFieldsChange={onFiledChange}>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.personal_infomation')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <InputItem
                name={'firstName'}
                label={'First name'}
                defaultValue={formModel.firstName}
                readonly={readOnly}
                required={requiredFields.firstName}
                filedError={filedErrors['firstName']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name={'lastName'}
                label={'Last name'}
                defaultValue={formModel.lastName}
                readonly={readOnly}
                required={requiredFields.lastName}
                filedError={filedErrors['lastName']}
              />
            </Grid>
          </Grid>
          <div className={styles.categoryPicker}>
            <div className={styles.categoryTitle}>
              <span>{Translation('prospect.category')}</span>
            </div>
            <ProspectCategoryPicker
              onChanage={onCategoryChange}
              defaultCategory={formModel.prospectCategory}
              editable={!readOnly}
            />
          </div>

          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <InputItem
                name="gender"
                label="Gender"
                isSelect
                selectOptions={selectionPools.genderPools}
                readonly={readOnly}
                defaultValue={formModel.gender}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Birth of Date"
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    size: 'medium',
                    fullWidth: true,
                  },
                }}
                readOnly={readOnly}
                onChange={onBirthDateChange}
                defaultValue={formModel.birthDate ? dayjs(formModel.birthDate) : undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name={'phoneNumber'}
                label={'Phone Number'}
                defaultValue={formModel.phoneNumber}
                readonly={readOnly}
                required={requiredFields.phoneNumber}
                filedError={filedErrors['phoneNumber']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="maritalStatus"
                label="Marital Status"
                isSelect
                selectOptions={selectionPools.maritalStatusPools}
                readonly={readOnly}
                defaultValue={formModel.maritalStatus}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name={'occupation'}
                label={'Occupation'}
                readonly={readOnly}
                defaultValue={formModel.occupation}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name={'company'}
                label={'Company of Employment'}
                readonly={readOnly}
                defaultValue={formModel.company}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name={'workPosition'}
                label={'Work Place'}
                readonly={readOnly}
                defaultValue={formModel.workPosition}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name={'income'}
                label={'Income'}
                readonly={readOnly}
                defaultValue={formModel.income}
                filedError={filedErrors['income']}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name={'language'}
                label={'Preferred Language'}
                isSelect
                selectOptions={selectionPools.languagePools}
                readonly={readOnly}
                defaultValue={formModel.language}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="race"
                label="Race"
                isSelect
                selectOptions={selectionPools.racePools}
                readonly={readOnly}
                defaultValue={formModel.race}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="nationality"
                label="Nationality"
                readonly={readOnly}
                defaultValue={formModel.nationality}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="permanentResident"
                label="Permanent Resident"
                isSelect
                selectOptions={selectionPools.permanentResidentPools}
                readonly={readOnly}
                defaultValue={formModel.permanentResident}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem name="education" label="Education" readonly={readOnly} defaultValue={formModel.education} />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="smokeHabit"
                label="Smoker Indicator"
                isSelect
                selectOptions={selectionPools.smokerIndicatorPools}
                readonly={readOnly}
                defaultValue={formModel.smokeHabit}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="salutation"
                label="Salutation"
                isSelect
                selectOptions={selectionPools.salutationPools}
                readonly={readOnly}
                defaultValue={formModel.salutation}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="countryNationalId"
                label="Country National ID"
                readonly={readOnly}
                defaultValue={formModel.countryNationalId}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem name="chinaId" label="China ID" readonly={readOnly} defaultValue={formModel.chinaId} />
            </Grid>

            <Grid item xs={6}>
              <InputItem
                name="passportNumber"
                label="Passport Number"
                readonly={readOnly}
                defaultValue={formModel.passportNumber}
              />
            </Grid>

            <Grid item xs={6}>
              <InputItem name={'notes'} label={'Notes'} readonly={readOnly} defaultValue={formModel.notes} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label={'Referred by'}
                variant="outlined"
                className={styles.input}
                size="medium"
                onClick={() => {
                  onReferredByClick(readOnly);
                }}
                InputProps={{
                  readOnly: true,
                }}
                required={requiredFields.referredBy}
                value={selectedReferrBy?.displayName ?? ''}
              ></TextField>
            </Grid>
          </Grid>
        </div>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.contact_information')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <InputItem
                name="regionCode"
                label="Phone Number-Region code"
                isSelect
                selectOptions={region}
                required={requiredFields.regionCode}
                readonly={readOnly}
                defaultValue={formModel.regionCode}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="phoneNumber"
                label="Phone number"
                required={requiredFields.phoneNumber}
                readonly={readOnly}
                defaultValue={formModel.phoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="secondPhoneNumber"
                label="Second Phone Number"
                readonly={readOnly}
                defaultValue={formModel.secondPhoneNumber}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="email"
                label="Email Address"
                required={requiredFields.email}
                readonly={readOnly}
                defaultValue={formModel.email}
                filedError={filedErrors['email']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="secondEmail"
                label="Second Email Address"
                readonly={readOnly}
                defaultValue={formModel.secondEmail}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="fbProfile"
                label="Facebook Profile"
                readonly={readOnly}
                defaultValue={formModel.fbProfile}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem name="wechatId" label="WeChat ID" readonly={readOnly} defaultValue={formModel.wechatId} />
            </Grid>
            <Grid item xs={6}>
              <InputItem name="address" label="Address" readonly={readOnly} defaultValue={formModel.address} />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="state"
                label="State"
                isSelect
                selectOptions={selectionPools.statePools}
                readonly={readOnly}
                defaultValue={formModel.state}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="city"
                label="City"
                isSelect
                selectOptions={cityList}
                readonly={readOnly}
                defaultValue={formModel.city}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="optedOutContactStatus"
                label="Opt Out"
                isSelect
                selectOptions={selectionPools.optedOutContactStatusPools}
                readonly={readOnly}
                defaultValue={formModel.optedOutContactStatus}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem name="zipCode" label="Zip Code" readonly={readOnly} defaultValue={formModel.zipCode} />
            </Grid>
          </Grid>
        </div>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.family_information')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <InputItem
                name="totalChild"
                label="Number of Children"
                defaultValue={formModel.totalChild}
                readonly={readOnly}
                filedError={filedErrors['totalChild']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="childrenDetails"
                label="Children Details"
                defaultValue={formModel.childrenDetails}
                readonly={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="spouseDetails"
                label="Spouse Details"
                defaultValue={formModel.spouseDetails}
                readonly={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="parentDetails"
                label="Parent Details"
                defaultValue={formModel.parentDetails}
                readonly={readOnly}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="otherFamilyDetails"
                label="Other Family Details"
                defaultValue={formModel.otherFamilyDetails}
                readonly={readOnly}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.coverage_information')}</span>
          </div>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <InputItem
                name="existingCISumAssured"
                label="Sum assured of existing CI policy"
                defaultValue={formModel.existingCISumAssured}
                readonly={readOnly}
                filedError={filedErrors['existingCISumAssured']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="expectedIncomeCoverMonths"
                label="Expected months of income loss due to CI"
                defaultValue={formModel.expectedIncomeCoverMonths}
                readonly={readOnly}
                filedError={filedErrors['expectedIncomeCoverMonths']}
              />
            </Grid>
            <Grid item xs={6}>
              <InputItem
                name="preferredCISumAssured"
                label="CI of concern"
                defaultValue={formModel.preferredCISumAssured}
                readonly={readOnly}
              />
            </Grid>
          </Grid>
        </div>
        <div className={styles.sectionBox}>
          <div className={styles.sectionTitle}>
            <span>{Translation('prospect.category_history')}</span>
            <div style={{ marginTop: '24px' }}>
              <CategoryHistoryComponent updateLog={prospectDetail.categoryUpdatedLog ?? []} />
            </div>
          </div>
        </div>

        <Button onClick={deleteProspect} variant="outlined">
          {Translation('prospect.delete_prospect').toUpperCase()}
        </Button>
      </Form>
      <ReferredBy
        defaultSelectedRows={selectedReferrBy}
        open={showReferrby}
        onClose={() => setShowReferrby(false)}
        onConfirm={onSelectReferrBy}
      />
      <DeleteProspectDialog prospectDetail={prospectDetail} onClose={onDeleteDialogClose} open={showDeleteDialog} />
    </div>
  );
});

import React, { FC, memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { ALL_OPTION, useSelectDownline } from './select-downline.hook';
import { CascadingSelector, Option } from '../cascading-selector/cascading-selector.component';
import { FilterState } from '../ProTable/ProFilter';

export type SelectDownlineProps = ParamsProps & {
  /* otherProp: string */
  campaignId: string;
  onChange?: (option: Array<Option>, codeArr: Array<string>) => void;
  isCampaignOwner?: boolean;
  currentOption?: Option[];
};

export const SelectDownline: React.FC<SelectDownlineProps> = memo((props: SelectDownlineProps) => {
  const { onChange } = props;
  const { loading, cascaderData, run, defaultSelected } = useSelectDownline(props);

  const onSelectComplete = (selected: any) => {
    const [unitNode, agentNode] = selected;
    if (agentNode.value === ALL_OPTION.ALL_AGENT) {
      const allAgentCode = unitNode.child
        .filter((item: any) => item.value !== ALL_OPTION.ALL_AGENT)
        .map((item: any) => item.value);
      onChange && onChange(selected, allAgentCode);
    } else {
      onChange && onChange(selected, [agentNode.value]);
    }
  };

  return (
    <CascadingSelector data={cascaderData} onSelectComplete={onSelectComplete} defaultSelected={defaultSelected} />
  );
});

import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { get as lodashGet } from 'lodash';
import { CampaignStructureItem, campaignSectionFieldItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { AgencyCampaignTypeStatusEnum } from 'src/app/modules/AgencyCampaign/constants';
import moment from 'moment';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network/crud/resourceCrud';
import { fileUpload } from 'src/app/common/resource/resource-utils';
import { AttachmentUploaderComponent } from '../attachment-uploader/attachment-uploader.component';
import { Card } from '@mui/material';
import { useStyles } from './tab-document.style';
import { convertArrayToObject } from 'src/app/common/utils/common-utils';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

interface HookProps {
  campaignTypeStructureData: CampaignStructureItem;
  campaign: any;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  errorState: any;
  disabledEdit?: boolean;
  validationHandler: any;
  onSubmitDocuments: Function;
  disabled?: boolean;
  toggleSubmit: (val: boolean) => void;
}

export const useTabDocument = ({
  campaignTypeStructureData,
  campaign,
  formState,
  formDispatch,
  sectionKey,
  errorState,
  validationHandler,
  onSubmitDocuments,
  disabled,
  disabledEdit,
  toggleSubmit,
}: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const { t: Translation } = useTranslation();

  const { classes } = useStyles();

  // redux
  const dispatch = useDispatch();
  const [submitted, setDataSubmmitted] = useState(false);
  const [uploadEventDocumentProgress, setUploadEventDocumentProgress] = useState<number>();
  const eventDocumentData = campaignTypeStructureData.sections.filter((item) => item.key === sectionKey)[0];
  const [uploading, setUploading] = useState(false);

  const isMandatoryFieldsSatisfied = useMemo(() => {
    if (eventDocumentData) {
      const mandatoryFields = eventDocumentData.sectionFields.filter(
        (item) => item.rules?.[0]?.key === 'mandatory' && item.rules?.[0]?.value === true,
      );
      return mandatoryFields.every((item) => formState[sectionKey]?.[item.key]?.resourceIds?.length);
    } else {
      return false;
    }
  }, [eventDocumentData, formState, sectionKey]);

  const getMimeType = (file: File) => {
    if (file.type) {
      return file.type;
    }
    const mime = {
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      docm: 'application/vnd.ms-word.document.macroEnabled.12',
      dotm: 'application/vnd.ms-word.template.macroEnabled.12',
      xls: 'application/vnd.ms-excel',
      xlt: 'application/vnd.ms-excel',
      xla: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
      xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
      xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
      xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    };
    type MimeTypes = keyof typeof mime;
    const fileName = file.name;
    const fileExtension = (fileName?.split('.')?.pop()?.toLowerCase() || '') as MimeTypes;
    return mime[fileExtension];
  };

  const getTranslations = () => {
    return {
      are_you_sure_to_submit: Translation('are_you_sure_to_submit'),
      please_make_sure_all_the_documents_are_correct: Translation('please_make_sure_all_the_documents_are_correct'),
    };
  };

  const uploadEventDocument = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const fieldId: string = e.target.getAttribute('id') ?? '';
    const arrFile: File[] = lodashGet(e, 'target.files', []);
    if (arrFile.length > 0) {
      let arrResource: unknown[] = [],
        arrResourceIds: unknown[] = [];
      for (let index = 0; index < arrFile.length; index++) {
        const file: File = arrFile[index];
        const mimeType = getMimeType(file);
        if (!mimeType) {
          return dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('app.button.submitError'),
                content: Translation('common.fileNotSupport'),
              },
            ]),
          );
        }
        try {
          const createBlobRes = await createBlobAsAgent(
            { mimeType, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
            dispatch,
          );
          await fileUpload(createBlobRes[0].url, file, setUploadEventDocumentProgress);
          const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldId}`, result);
            arrResource.push(result);
            arrResourceIds.push({ id: result.blobId });
            // formDispatch({ type: "MODIFY_FIELD", payload: { section: sectionKey, field: fieldId, value: {resources:[result],resourceIds:[result.blobId] } } });
          }
        } catch (err) {
        } finally {
          setUploadEventDocumentProgress(undefined);
        }
      }

      const source: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
      const sourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          section: sectionKey,
          field: fieldId,
          value: { resources: [...source, ...arrResource], resourceIds: [...sourceId, ...arrResourceIds] },
        },
      });
    }
    setUploading(false);
  };

  const removeFile = (fieldId: string, index: number) => {
    const arrSource: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
    const arrSourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);

    arrSource.splice(index, 1);
    arrSourceId.splice(index, 1);
    // trigger warning: 'This field is mandatory' when no attachment
    const value = arrSource.length > 0 ? { resources: arrSource, resourceIds: arrSourceId } : undefined;
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: {
        section: sectionKey,
        field: fieldId,
        value: value,
      },
    });
  };

  const viewFile = (url: string) => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action" : "loadurlwithtarget", "payload" : { "target" : "_blank", "url" : "${encodeURIComponent(url)}" } }`,
      );
    } else {
      window.open(url, '_blank');
    }
  };

  const _renderEventDocumentSection = (fieldItem: campaignSectionFieldItem, loading: boolean) => {
    let ruleObject = convertArrayToObject(fieldItem.rules, 'title');
    const source: any[] = lodashGet(formState, `${sectionKey}.${fieldItem.key}.resources`, []);
    const sourceIdMapSubmitDate: any = {};
    const sourceIds: any[] = lodashGet(formState, `${sectionKey}.${fieldItem.key}.resourceIds`, []);
    for (let index = 0; index < sourceIds.length; index++) {
      const resource: any = sourceIds[index];
      sourceIdMapSubmitDate[resource.id] = resource.submitDate;
    }

    const allSubmitted = Boolean(sourceIds?.length && sourceIds.every((item: any) => item.submitDate));
    setDataSubmmitted(allSubmitted);
    return (
      <Card elevation={3} style={{ marginTop: 24 }} key={fieldItem.key}>
        <AttachmentUploaderComponent
          fieldItem={fieldItem}
          sectionKey={sectionKey}
          formState={formState}
          errorState={errorState}
          disabled={disabled}
          disabledEdit={disabledEdit}
          isAttachmentUpdated={false}
          isProofUpdated={false}
          onUpload={uploadEventDocument}
          removeFile={removeFile}
          viewFile={viewFile}
          headerNode={
            <div className={classes.attachmentTitle}>
              {Translation(`agencyCampaign.field.${fieldItem.key}`)}
              {ruleObject.Mandatory && ruleObject.Mandatory.value ? <span>*</span> : null}
            </div>
          }
        />
      </Card>
    );
  };

  const cachedRenderEventSection = useMemo(() => {
    if (eventDocumentData) {
      return eventDocumentData.sectionFields.map((fieldItem) => {
        return _renderEventDocumentSection(fieldItem, uploading);
      });
    } else {
      return null;
    }
  }, [eventDocumentData?.sectionFields, formState, uploading]);

  useEffect(() => {
    toggleSubmit(
      !disabled &&
        isMandatoryFieldsSatisfied &&
        !submitted &&
        [AgencyCampaignTypeStatusEnum.APPROVED, AgencyCampaignTypeStatusEnum.ACTIVE].includes(
          campaign.campaignStatus,
        ) &&
        moment(new Date()).isAfter(campaign.startDate),
    );
  }, [submitted, formState, campaign.campaignStatus, isMandatoryFieldsSatisfied, disabled, campaign.startDate]);

  return { eventDocumentData, cachedRenderEventSection, lodashGet, getTranslations, onSubmitDocuments };
};

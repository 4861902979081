import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '16px',
    paddingRight: '16px',
    display: 'flex',
    flex: 1,
    backgroundColor: '#FFF4E5',
    borderRadius: '4px',
    height: '48px',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  icon: {
    width: '22px',
    height: '22px',
    color: '#EF6C00',
  },
  text: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#663C00',
    marginLeft: '12px',
  },
  checkText: {
    fontSize: '13px',
    fontWeight: 500,
    color: '#663C00',
  },
  textIcon: {
    display: 'flex',
  },
}));

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { useStyles } from './import-existing-customers.style';
import {
  batchProspectsFromCustomers,
  fetchClientList,
  getProspectInfosFromCustomers,
  getProspectSpecifiedColumns,
} from 'src/app/modules/Prospect/network/prospectCurd';
import { Customer, getTableColumns, queryCustomerData } from './util/existing-customers.util';
import { RequestData } from 'src/app/common/components/ProTable';
import { ProspectCategoryProps } from 'src/app/common/components/prospect-category/prospect-category.component';
import { getImported } from './util/existing-customers.util';
import { ExistingProspect, Prospect } from 'src/app/modules/Prospect/types/prospect-types';
import { useHistory } from 'react-router-dom';
import { PROSPECT_BASE_PATH } from 'src/app/modules/Prospect/constants';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';

export enum ErrorType {
  DUPLICATED,
  NOT_ALL_SUCCESS,
}

export enum PolicyListSearchOptions {
  ALL = '1',
  CLIENT_NO = '2',
  SUBMISSION_DATE = '3',
  HKID = '4',
  CLIENT_NAME = '5',
}

const languageConvertor = (language: string) => {
  if (language === 'en') {
    return 'en-US';
  } else {
    return 'zh-HK';
  }
};

interface HookProps {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  // other params
}

export const useImportExistingCustomers = ({ cacheParams }: HookProps) => {
  const locale = useLang();
  const dispatch = useDispatch();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const history = useHistory();

  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [keyword, setKeyword] = useState('');
  const selectedCustomers = useRef<Customer[]>([]);
  const [hasSelected, setHasSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorType, setErrorType] = useState<ErrorType>();
  const [failedCustomers, setFailedCustomers] = useState<ExistingProspect[]>([]);

  const duplicatedImportingCustomersRef = useRef<ExistingProspect[]>();
  const canImportingCustomersRef = useRef<ExistingProspect[]>();

  const [showErrorDialog, setShowErrorDialog] = useState(false);

  const { user } = useSelector((state: any) => state.auth);

  const importedClientCodes = useRequest(() => getProspectSpecifiedColumns('clientCode', dispatch), {
    manual: false,
  });

  const importedPhoneNumbers = useRequest(() => getProspectSpecifiedColumns('phoneNumber', dispatch), {
    manual: false,
  });

  const disableConfirmButton = useMemo(() => {
    return !hasSelected;
  }, [hasSelected]);

  const tableColumns = useMemo(() => {
    const importedList = importedClientCodes.data
      ? (importedClientCodes.data
          .filter((item: any) => item?.clientCode)
          .map((item: any) => {
            return item.clientCode;
          }) as string[])
      : [];
    return getTableColumns({ t: Translation, keyword: keyword, styles: styles, importedClientCodes: importedList });
  }, [importedClientCodes, keyword]);

  const onSelectedRowsChange = useCallback((selectedRows: any[]) => {
    selectedCustomers.current = selectedRows;
    setHasSelected(selectedRows.length > 0);
  }, []);

  const rowSelection = useMemo(() => {
    return {
      onChange: onSelectedRowsChange,
      getCheckboxProps: (row: Customer) => {
        return {
          disabled: importedClientCodes.data?.find((item: any) => item.clientCode === row.clientCode),
        };
      },
    };
  }, [importedClientCodes]);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<Customer>> => {
      const { page, pageSize, keyword: searchkey } = params;
      const { types: filterTypes, sources: filterSourceTypes, categories: filterCategoryIds, dateRange } = filter ?? {};

      const queryParam = {
        lang: languageConvertor(locale),
        agentCode: user.username,
        searchOpt: PolicyListSearchOptions.ALL,
        policyNo: undefined,
        clientCode: undefined,
        idNo: undefined,
        submissionDtFrom: undefined,
        submissionDtTo: undefined,
      };

      // todo: check this is asc or desc
      let sortBy = '-nameWeight -firstName';
      let flag = '';

      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];

        if (val === 'desc') {
          flag = '-';
        }

        if (key === 'displayName') {
          if (flag) {
            sortBy = '-nameWeight -firstName';
          } else {
            sortBy = 'nameWeight firstName';
          }
        } else {
          sortBy = `${flag}${key}`;
        }
      }

      const res = await fetchClientList(queryParam, dispatch);

      if (res) {
        let data = res;
        if (searchkey) {
          data = queryCustomerData(searchkey, res);
        }
        data = data.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);

        return {
          success: true,
          data: data ?? [],
          total: data.length,
        };
      }

      return {
        success: false,
        data: [],
        total: 0,
      };
    },
    [dispatch],
  );

  const onSelectCategory = useCallback((category: ProspectCategoryProps) => {
    setSelectedCategory(category.id);
  }, []);

  const submit = useCallback(() => {
    if (loading) return;
    const clientCodes = selectedCustomers.current.map((item) => item.clientCode);
    const prospectFromCustomers = {
      prospectCategory: selectedCategory,
      clientCodes,
    };
    setLoading(true);
    getProspectInfosFromCustomers(prospectFromCustomers, locale, dispatch)
      .then((res) => {
        // setSelectedCustomerDetail(res.data ?? []);
        const selectedCustomerDetail: ExistingProspect[] = res.data ?? [];
        if (selectedCustomerDetail && importedPhoneNumbers.data) {
          const imported = getImported(importedPhoneNumbers.data, selectedCustomerDetail);
          const duplicatedImportingCustomers: any[] = [];
          const canImportingCustomers: any[] = [];
          selectedCustomerDetail.forEach((clientProspectInfo) => {
            if (imported.includes(clientProspectInfo.clientCode)) {
              duplicatedImportingCustomers.push(clientProspectInfo);
            } else {
              canImportingCustomers.push(clientProspectInfo);
              // setCanImportingCustomers(canImportingCustomers);
            }
          });

          duplicatedImportingCustomersRef.current = duplicatedImportingCustomers;
          canImportingCustomersRef.current = canImportingCustomers;
          if (duplicatedImportingCustomers.length > 0) {
            setFailedCustomers(duplicatedImportingCustomers);
            setErrorType(ErrorType.DUPLICATED);
            setShowErrorDialog(true);
          } else {
            submitCustomers(canImportingCustomers);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [selectedCategory, selectedCustomers.current, importedPhoneNumbers.data]);

  const resetStatus = useCallback(() => {
    setShowErrorDialog(false);
  }, []);

  const submitCustomers = useCallback(async (customers?: ExistingProspect[]) => {
    if (customers && customers.length > 0) {
      setLoading(true);
      const result = await batchProspectsFromCustomers(customers as [], dispatch);
      setLoading(false);
      if (result) {
        if (result.fail && result.fail.length > 0) {
          setFailedCustomers(result.fail);
          setErrorType(ErrorType.NOT_ALL_SUCCESS);
          setShowErrorDialog(true);
        } else {
          setShowErrorDialog(false);
          history.push(PROSPECT_BASE_PATH);
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('prospect.import_successfully'),
                content: '',
              },
            ]),
          );
        }
      }
    } else {
      history.push(PROSPECT_BASE_PATH);
    }
  }, []);

  const onDialogCancelClick = useCallback(() => {
    if (errorType === ErrorType.DUPLICATED) {
      resetStatus();
    } else {
      history.push(PROSPECT_BASE_PATH);
    }
  }, [errorType]);

  const onDialogConfirmClick = useCallback(
    (selectedCustomers?: ExistingProspect[]) => {
      if (errorType === ErrorType.DUPLICATED) {
        submitCustomers(canImportingCustomersRef.current);
      } else {
        if (selectedCustomers && selectedCustomers.length > 0) {
          submitCustomers(selectedCustomers);
        }
      }
    },
    [errorType, canImportingCustomersRef.current],
  );

  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);
      setKeyword(keyword);
    },
    [cacheParams],
  );

  return {
    loading,
    rowSelection,
    tableColumns,
    failedCustomers,
    errorType,
    showErrorDialog,
    disableConfirmButton,
    getData,
    submit,
    onDialogCancelClick,
    onDialogConfirmClick,
    onSelectCategory,
    onFilterChange,
  };
};

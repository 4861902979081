import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  toBox: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
  },
  toFont: {
    fontWeight: 400,
    fontSize: 16,
  },
}));

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-lead-list.style';
import { useCampaignLeadList } from './campaign-lead-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { UpdateServicingCampaignDialog } from '../update-servicing-campaign-dialog/update-servicing-campaign-dialog.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: CampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
};

export const ServicingCampaignLeadListComponent: React.FC<ComponentProps> = memo(
  ({ data, cacheParams, campaignId }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    // custom-hook
    const {
      filterConfig,
      columns,
      getData,
      rowSelection,
      params,
      onFilterChange,
      actionRef,
      showUpdatingDialog,
      onDialogConfirmClick,
      onDialogCancelClick,
    } = useCampaignLeadList({
      campaginDetail: data,
      cacheParams,
    });
    return (
      <>
        <ProFilterTable
          filterConfigs={filterConfig}
          filterState={params}
          Filter={ProSearchFilter}
          filterPlaceholder={Translation('pruleads.prospect.list.search.placeholder')}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns}
          request={getData}
          enableRefresh={false}
          // ActionNode={ActionItems}
          showSelect={true}
          onFilterChange={onFilterChange}
          actionRef={actionRef}
          // defaultSelectedRows={props.defaultSelectedRows}
        />
        <UpdateServicingCampaignDialog
          campaignId={campaignId}
          open={showUpdatingDialog}
          onConfirm={onDialogConfirmClick}
          onCancel={onDialogCancelClick}
        />
      </>
    );
  },
);

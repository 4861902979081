import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    borderRadius: 5,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: 'red',
  },
  dialogContainer: {
    margin: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    maxHeight: '100%',
    height: '100%',
    maxWidth: 608,
    width: 608,
    borderRadius: 0,
  },
  dialogRoot: {
    left: 'auto',
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: 20,
    height: 68,
    alignItems: 'center',
  },
  dialogContentContainer: {
    display: 'flex',
    flexGrow: 0,
    height: 'auto',
    borderBottom: 0,
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 80,
    paddingRight: 24,
  },
  dialogButtonContainer: {
    display: 'flex',
    width: 240,
    justifyContent: 'space-between',
  },
  dialogButton: {
    width: 102,
    height: 48,
  },
  fullWidth: {
    width: '100%',
  },
  formLabel: {
    fontSize: 16,
    fontWeight: 600,
    color: 'rgba(51, 51, 51, 1)',
  },
  mt_8: {
    marginTop: 8,
  },
  mt_24: {
    marginTop: 24,
  },
  ml_8: {
    marginLeft: 8,
  },
}));

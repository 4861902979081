import React, { PropsWithChildren } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useIntl } from 'react-intl';

interface BottonDialogProps {
  dialogTitle?: string;
  onCancel?: () => void;
  onOk: () => void;
  canncelBtnText?: string | React.ReactNode;
  confirmBtnText?: string | React.ReactNode;
  canCloseDialog?: boolean;
  open?: boolean;
  hideCancelBtn?: boolean;
  hideConfirmBtn?: boolean;
  loading?: boolean;
  disableConfirmBtn?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
    display: 'flex',
  },
  container_paddingTop: {
    paddingTop: '20px',
  },
  btnContainer: {
    padding: '0 20px 20px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  cancelbtn: {
    marginRight: 24,
    borderColor: '#F0F0F0',
    borderRadius: '4px',
  },
  confirmBtn: {
    borderRadius: '4px',
  },
  loading: {
    marginLeft: '8px',
    color: '#FCF3F3',
  },
}));

const PruDialog: React.FC<PropsWithChildren<BottonDialogProps>> = ({
  onCancel = () => {},
  onOk,
  canncelBtnText,
  canCloseDialog,
  confirmBtnText,
  children,
  dialogTitle,
  open = true,
  hideCancelBtn,
  hideConfirmBtn,
  loading,
  disableConfirmBtn,
}) => {
  const { classes, cx } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Dialog open={open} onClose={canCloseDialog ? onCancel : () => {}}>
      {dialogTitle ? <DialogTitle style={{ fontWeight: 'bold', fontSize: '18px' }}>{dialogTitle}</DialogTitle> : null}
      <div className={cx(classes.container, { [classes.container_paddingTop]: dialogTitle == null })}>{children}</div>
      <div className={classes.btnContainer}>
        {hideCancelBtn ? null : (
          <Button className={classes.cancelbtn} variant="outlined" color="inherit" onClick={onCancel}>
            {canncelBtnText || Translation('global.text.no')}
          </Button>
        )}
        {hideConfirmBtn ? null : (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (loading) return;
              onOk();
            }}
            className={classes.confirmBtn}
            disabled={disableConfirmBtn}
          >
            {confirmBtnText || Translation('global.text.yes')}
            {loading && <CircularProgress className={classes.loading} size={14} />}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export default PruDialog;

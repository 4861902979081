import React, { memo } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const SearchIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.31 15.9L20.71 19.29C20.8993 19.4778 21.0058 19.7334 21.0058 20C21.0058 20.2666 20.8993 20.5222 20.71 20.71C20.5222 20.8993 20.2666 21.0058 20 21.0058C19.7334 21.0058 19.4778 20.8993 19.29 20.71L15.9 17.31C14.5025 18.407 12.7767 19.0022 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11C19.0022 12.7767 18.407 14.5025 17.31 15.9ZM11 5C7.68629 5 5 7.68629 5 11C5 14.3137 7.68629 17 11 17C14.3137 17 17 14.3137 17 11C17 7.68629 14.3137 5 11 5Z"
          fill="#919EAB"
        />
      </svg>
    </SvgIcon>
  );
});

export const FilterIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.26654 0H16.7805C17.387 0 17.8786 0.492487 17.8786 1.1C17.8786 1.3629 17.7846 1.61711 17.6136 1.81657L12.6713 7.5L12.672 17.5C11.61 17.0747 8.22975 15.7874 6.80313 15.2451C6.4148 15.0974 6.15802 14.7253 6.15802 14.3098V7.5L0.489925 1.87766C0.0918051 1.47869 0.0634872 0.849655 0.404886 0.417954L0.490234 0.322028C0.696152 0.115832 0.975389 0 1.26654 0ZM14.8246 2H3.43441L8.15455 6.5V13.5L10.6748 14.5L10.6752 6.5L14.8246 2Z"
          fill="#212B36"
        />
      </svg>
    </SvgIcon>
  );
});

export const ClearIcon: React.FC<Omit<SvgIconProps, 'color'> & { color?: string }> = memo(
  (props: Omit<SvgIconProps, 'color'> & { color?: string }) => {
    const { color = '#E8192C', ...rest } = props;
    return (
      <SvgIcon {...rest}>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
          <rect x="-0.00537109" y="3" width="17.9687" height="2" fill={color} />
          <rect x="3.98779" width="9.98261" height="2" fill={color} />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.99121 5C1.99121 3.89543 2.88508 3 3.98773 3H13.9703C15.073 3 15.9669 3.89543 15.9669 5V17C15.9669 18.1046 15.073 19 13.9703 19H3.98773C2.88508 19 1.99121 18.1046 1.99121 17V5ZM3.98773 5V17H13.9703V5H3.98773Z"
            fill={color}
          />
          <rect x="7.98096" y="8" width="6" height="1.99652" transform="rotate(90 7.98096 8)" fill={color} />
          <rect x="11.9736" y="8" width="6" height="1.99652" transform="rotate(90 11.9736 8)" fill={color} />
        </svg>
      </SvgIcon>
    );
  },
);

export const EditIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2628 0.174957L17.8315 5.73028L5.57303 17.9593L0.0536351 18.0229L-0.00976562 12.4181L12.2628 0.174957ZM1.99964 13.2386L12.2628 3L14.9996 5.73028L4.73649 15.9688L2.03088 16L1.99964 13.2386Z"
          fill={'#E8192C'}
        />
      </svg>
    </SvgIcon>
  );
});

export const MergeIcon: React.FC<SvgIconProps> = memo((props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <rect x="4" y="9" width="11" height="11" stroke="#E8192C" strokeWidth="2" />
        <rect x="9" y="4" width="11" height="11" stroke="#E8192C" strokeWidth="2" />
      </svg>
    </SvgIcon>
  );
});

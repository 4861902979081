import React, { FC } from 'react';
import { toAbsoluteUrl } from 'src/app/common/utils';

type information = {
  agentName: string;
  agentTitle: string;
};

let agentInfoLogo = toAbsoluteUrl('/media/pruforce-logo/survey_agentInfo_card.png');
const pageHeight = window.innerHeight * (window.visualViewport?.scale || 1);

console.log('pageHeight', pageHeight, pageHeight < 1180 ? (pageHeight < 667 ? '78px' : '110px') : '78px');

export const AgentInformation: FC<information> = ({ agentName, agentTitle }) => {
  return (
    <div style={{ marginBottom: '16px' }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 4px 10px #00000019',
        }}
      >
        <div
          style={{
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            padding: '16px 0px 16px 16px',
          }}
        >
          <text
            style={{ fontStyle: 'normal', fontWeight: 600, fontSize: '16px', lineHeight: '24px', color: '#000000' }}
          >
            {agentName}
          </text>
          <text
            style={{ fontStyle: 'normal', fontWeight: 500, fontSize: '12px', lineHeight: '16px', color: '#999999' }}
          >
            {agentTitle}
          </text>
        </div>
        <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end', alignItems: 'center', paddingRight: 20 }}>
          <img
            src={agentInfoLogo}
            style={{
              height: pageHeight < 1180 ? (pageHeight < 667 ? '68px' : '58px') : '68px',
            }}
          ></img>
        </div>
      </div>
    </div>
  );
};

import React, { FC } from 'react';
import { TableRow, TableCell } from '@mui/material';
import { useIntl } from 'react-intl';
import { toAbsoluteUrl } from '../../utils';

type PruTableEmptyRowProps = {
  isEmpty: boolean;
  type?: string;
};

const PruTableEmptyRow: FC<PruTableEmptyRowProps> = ({ isEmpty, type }) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return isEmpty ? (
    <TableRow>
      <TableCell colSpan={12} align="center">
        <div
          style={{ height: '360px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <img style={{ width: '180px' }} alt="logo" src={toAbsoluteUrl('/media/svg/empty.svg')} />
        </div>
      </TableCell>
    </TableRow>
  ) : (
    <></>
  );
};

export default PruTableEmptyRow;

import { get, set } from 'lodash';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Button } from '@mui/material';
import { CampaignLeadItem, FollowUpStatusEnum, LeadLabel } from 'src/app/modules/Campaign/types/campaign-types';
import { TranslationWithParams } from 'src/app/i18n';
import { styles } from './lead-column.style';

interface IssuedPolicy {
  issuedDate: string;
  policyNumber: string;
}

export const getColumns = ({
  t,
  reject,
  accept,
  newAppointment,
  update,
}: {
  t: (val: string) => string;
  reject?: () => void;
  accept?: () => void;
  newAppointment?: () => void;
  update?: () => void;
}): ProColumns<CampaignLeadItem>[] => [
  {
    title: t('campaign.list.name'),
    dataIndex: 'name',
    width: '290px',
  },
  {
    title: t('campaign.list.leadLabel'),
    dataIndex: 'labels',
    width: '176px',
    render: (currValue: string[]) => {
      if (currValue.length > 0) {
        return leadLabelsTag(currValue, t);
      } else return '';
    },
  },
  {
    title: t('campaign.list.campaignName'),
    dataIndex: 'campaignName',
    width: '290px',
  },
  {
    title: t('campaign.list.sla'),
    dataIndex: 'sla',
    width: '176px',
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('campaign.list.leadSource'),
    dataIndex: 'leadSource',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return <span>{currValue}</span>;
    },
  },
  {
    title: t('campaign.list.status'),
    dataIndex: 'status',
    width: '176px',
    render: (currValue: string) => {
      return <span>{t(`campaign.lead.followUp.${currValue}`)}</span>;
    },
  },
  {
    title: t('campaign.list.remark'),
    dataIndex: 'remark',
    width: '290px',
    render: (currValue: any[], item) => {
      if (currValue?.length > 0) {
        if (item.status === FollowUpStatusEnum.dead) {
          return (
            <div>{`${t('lead_remark_deadreason')}${currValue.map((item) => {
              const reasonArray = item.split('&');
              return TranslationWithParams(`lead_dead_reason_${reasonArray[0]}`, { days: reasonArray?.[1] });
            })}`}</div>
          );
        } else if (item.status === FollowUpStatusEnum.policy) {
          return <div>{`${t('lead_remark_policy')}${currValue.map((item: IssuedPolicy) => item.policyNumber)}`}</div>;
        } else {
          return currValue.map((i) => <div>{i}</div>);
        }
      }
    },
  },
  {
    title: t('campaign.list.lastUpdated'),
    dataIndex: 'updatedAt',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('campaign.list.lastView'),
    dataIndex: 'lastViewedAt',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('prospect.appoinment.title.operation'),
    width: '176px',
    dataIndex: 'op',
    render: (currValue: string, item) => {
      if (item.status === FollowUpStatusEnum.pending) {
        return (
          <span>
            <Button variant="text" color="info" onClick={reject}>
              {t('app.button.reject')}
            </Button>
            <Button variant="text" color="info" onClick={accept}>
              {t('app.button.accept')}
            </Button>
          </span>
        );
      } else {
        return (
          <span>
            <Button variant="text" color="info" onClick={newAppointment}>
              {t('app.button.newAppointment')}
            </Button>
            <Button variant="text" color="info" onClick={update}>
              {t('app.button.update')}
            </Button>
          </span>
        );
      }
    },
  },
];

const leadLabelsTag = (labels: string[], t: (val: string) => string) => {
  const labelStyles: { [key in LeadLabel]?: { backgroundColor: string; color: string } } = {
    [LeadLabel.potentialCustomer]: styles.potentialCustomerColor,
    [LeadLabel.existingCustomer]: styles.existingCustomerColor,
    [LeadLabel.referralCode]: styles.referralCodeColor,
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-2">
      {labels.map((label) => {
        const style = labelStyles[label as LeadLabel] || styles.potentialCustomerColor;
        return (
          <div
            style={{
              ...style,
              ...styles.labelView,
            }}
          >
            {t(`campaign.lead.label.${label}`)}
          </div>
        );
      })}
    </div>
  );
};

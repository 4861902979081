import { get, set } from 'lodash';
import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { Button } from '@mui/material';
import { CampaignLeadItem, FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
export const getColumns = ({
  t,
  reject,
  accept,
}: {
  t: (val: string) => string;
  reject?: () => void;
  accept?: () => void;
}): ProColumns<CampaignLeadItem>[] => [
  {
    title: t('campaign.list.name'),
    dataIndex: 'name',
    width: '290px',
  },
  {
    title: t('campaign.list.campaignName'),
    dataIndex: 'campaignName',
    width: '290px',
  },
  {
    title: t('campaign.list.status'),
    dataIndex: 'status',
    width: '176px',
  },
  {
    title: t('campaign.list.lastUpdated'),
    dataIndex: 'updatedAt',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('prospect.appoinment.title.operation'),
    width: '176px',
    dataIndex: 'op',
    render: (currValue: string, item) => {
      return (
        <span>
          <Button variant="text" color="info" onClick={reject && reject}>
            {t('app.button.reject')}
          </Button>
          <Button variant="text" color="info" onClick={accept && accept}>
            {t('app.button.accept')}
          </Button>
        </span>
      );
    },
  },
];

import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { useStyles } from './dialog.style';
import PruDialog from '../../PruDialog';

export type DialogProps = {
  open: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  content: string;
  title?: string;
  loading?: boolean;
};

export const Dialog: React.FC<DialogProps> = memo(
  ({ title, open, onConfirm, onClose, content, loading }: DialogProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    return (
      <PruDialog
        open={open}
        dialogTitle={title}
        confirmBtnText={Translation('app.button.confirm')}
        canncelBtnText={Translation('app.button.cancel')}
        onOk={() => {
          onConfirm?.();
        }}
        onCancel={() => {
          onClose?.();
        }}
        loading={loading}
      >
        <Box className={styles.container}>
          <Typography className={styles.text}>{content}</Typography>
        </Box>
      </PruDialog>
    );
  },
);

import { useRequest } from 'ahooks';

import { checkPurgeProspects } from '../../../network/prospectCurd';
import { getAutoDeleteProspectsConfig } from '../../../util/purge-prospect.util';

export const usePurgeProspectReminder = () => {
  const { data, error, loading } = useRequest(() => checkPurgeProspects(), { manual: false });

  const { notifyDays } = getAutoDeleteProspectsConfig();

  return {
    notifyDays,
    showReminder: !error && !loading && data && data?.totalDocs > 0,
    total: data?.totalDocs,
  };
};

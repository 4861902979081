import moment from 'moment';
import { useIntl } from 'react-intl';

const Translation = (id: string) => useIntl().formatMessage({ id });

const now = () => {
  return moment();
};

const endOfToday = () => {
  return now().endOf('day').toISOString();
};

export const last_30_days_rages = () => {
  return { startDate: now().startOf('day').subtract(29, 'days').toISOString(), endDate: endOfToday() };
};

export const last_7_days_ranges = () => {
  return { startDate: now().startOf('day').subtract(6, 'days').toISOString(), endDate: endOfToday() };
};

export const last_12_month_ranges = () => {
  return { startDate: now().startOf('month').subtract(11, 'month').toISOString(), endDate: endOfToday() };
};

export enum dateType {
  ALL = 'ALL',
  WTD = 'WTD',
  MTD = 'MTD',
  YTD = 'YTD',
  today = 'today',
  last_7_days = 'last_7_days',
  last_30_days = 'last_30_days',
  last_12_months = 'last_12_months',
  customize = 'customize',
}

export const getConfigs = () => {
  const configs = [
    {
      title: Translation('filter.date.all'),
      key: dateType.ALL,
      value: () => {
        return { startDate: '', endDate: '' };
      },
    },
    {
      title: Translation('filter.date.WTD'),
      key: dateType.WTD,
      value: () => {
        const isSunday = now().startOf('week').isoWeekday() === 7;
        const startDate = isSunday
          ? now().subtract(1, 'days').startOf('week').add(1, 'days')
          : now().startOf('week').add(1, 'days');
        return { startDate: startDate.startOf('day').toISOString(), endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.MTD'),
      key: dateType.MTD,
      value: () => {
        const startDate = now().startOf('month').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.YTD'),
      key: dateType.YTD,
      value: () => {
        const startDate = now().startOf('year').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.today'),
      key: dateType.today,
      value: () => {
        const startDate = now().startOf('day').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.last_7_days'),
      key: dateType.last_7_days,
      value: () => {
        return last_7_days_ranges();
      },
    },
    {
      title: Translation('filter.date.last_30_days'),
      key: dateType.last_30_days,
      value: () => {
        const startDate = now().startOf('day').subtract(29, 'days').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.customize'),
      key: dateType.customize,
      value: () => {
        const startDate = now().startOf('day').subtract(7, 'days').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
  ];

  return configs;
};

export const getDateConfigsByType = (rangeType?: dateType) => {
  const configs = [
    {
      title: Translation('filter.date.all'),
      key: dateType.ALL,
      value: () => {
        return { startDate: '', endDate: '' };
      },
    },
    {
      title: Translation('filter.date.WTD'),
      key: dateType.WTD,
      value: () => {
        const isSunday = now().startOf('week').isoWeekday() === 7;
        const startDate = isSunday
          ? now().subtract(1, 'days').startOf('week').add(1, 'days')
          : now().startOf('week').add(1, 'days');
        return { startDate: startDate.startOf('day').toISOString(), endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.MTD'),
      key: dateType.MTD,
      value: () => {
        const startDate = now().startOf('month').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.YTD'),
      key: dateType.YTD,
      value: () => {
        const startDate = now().startOf('year').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.today'),
      key: dateType.today,
      value: () => {
        const startDate = now().startOf('day').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.last_7_days'),
      key: dateType.last_7_days,
      value: () => {
        return last_7_days_ranges();
      },
    },
    {
      title: Translation('filter.date.last_30_days'),
      key: dateType.last_30_days,
      value: () => {
        const startDate = now().startOf('day').subtract(29, 'days').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.last_12_months'),
      key: dateType.last_12_months,
      value: () => {
        const startDate = now().startOf('month').subtract(11, 'month').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
    {
      title: Translation('filter.date.customize'),
      key: dateType.customize,
      value: () => {
        const startDate = now().startOf('day').subtract(7, 'days').toISOString();
        return { startDate, endDate: endOfToday() };
      },
    },
  ];

  if (rangeType) {
    return configs
      .map((item) => {
        return item;
      })
      .filter((item) => rangeType.includes(item.key));
  }

  return configs;
};

export interface CampaignDetail {
  _id: string;
  campaignId: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  image: ImageItem;

  customerIncentive?: string;
  agentIncentive?: string;
  flyers: Array<FlyerItem>;
  faq: Array<FaqItem>;
  links: Array<LinkItem>;
}
interface ImageItem {
  blobId: string;
  filename: string;
  url: string;
}

export interface FlyerItem {
  title: string;
  attachment: ImageItem;
}

export interface FaqItem {
  question: string;
  answer: string;
}

export interface LinkItem {
  title: string;
  link: string;
}
export enum CampaignTypeEnum {
  normal = 'normal',
  mass = 'mass',
  agency = 'agency',
  servicing = 'servicing',
}

export interface CampaignListItem {}

// export interface CampaignLeadItem {
//   _id: string;
//   agentStatus: string;
//   prospectDetail: LeadProspectDetailInfo;
//   leadSource: string;
//   updatedAt: string;
//   expiredAt?: string;
//   lastViewedAt?: string;
// }

export interface CampaignLeadItem {
  _id: string;
  name: string;
  labels: string[];
  campaignName: LeadProspectDetailInfo;
  sla: string;
  leadSource: string;
  status: string;
  remark: string;
  updatedAt: string;
  lastViewedAt?: string;
  feedback?: string;
}

export interface LeadProspectDetailInfo {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  types: string[];
  alias: string;
}

export enum FollowUpStatusEnum {
  engagement = 'engagement',
  contact = 'contact',
  met = 'met',
  quote = 'quote',
  proposal = 'proposal',
  policy = 'policy',
  issued = 'issued',
  dead = 'dead',
  contactFailure = 'contactFailure',
  pendingToContact = 'pendingToContact',
  pending = 'pending', // only for UI mapping, no this one in DB
}

export interface CampaignListResponse {
  campaigns: CampaignElement[];
  totalDocs: number;
  limit: number;
  totalPages: number;
  page: number;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: number;
}
export interface CampaignElement {
  campaignId: string;
  _id: string;
  name: string;
  type: string;
  startDate: Date;
  endDate: Date;
  status: string;
  image: ImageItem;
  leads: number;
  lastViewedTime: string;
}

export interface ServicingCampaignLeadItem {
  _id: string;
  name: string;
  campaignName: string;
  status: string;
  lastViewedAt: string;
  updatedAt: string;
}

export interface MassCampaignLeadItem {
  _id: string;
  name: string;
  campaignName: string;
  status: string;
  lastViewedAt: string;
  updatedAt: string;
  feedback: CampaignFeedback;
}

export interface CampaignFeedback {
  key: string;
  value: string;
}

export enum LeadLabel {
  potentialCustomer = `potentialCustomer`,
  existingCustomer = `existingCustomer`,
  referralCode = `referralCode`,
}

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, Grid, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './prospect-category-picker.style';
import { useProspectCategoryPicker } from './prospect-category-picker.hook';
import {
  ProspectCategory,
  ProspectCategoryProps,
} from 'src/app/common/components/prospect-category/prospect-category.component';

export type ProspectCategoryPickerProps = {
  /* otherProp: string */
  onChanage?: (category: ProspectCategoryProps) => void;
  defaultCategory?: string;
  editable?: boolean;
};

export const ProspectCategoryPicker: React.FC<ProspectCategoryPickerProps> = memo(
  (props: ProspectCategoryPickerProps) => {
    const { editable = true } = props;
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { prospectCategories, selectedCatagory, onChooseCategory } = useProspectCategoryPicker(props);
    return (
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: -1 }}>
        {prospectCategories.map((item, index) => {
          return (
            <Grid item xs={6} key={item.id}>
              <div className={styles.radioBox}>
                <Radio
                  checked={selectedCatagory === item.id}
                  onChange={onChooseCategory}
                  value={item.id}
                  className={styles.radio}
                  disabled={!editable}
                />
                <ProspectCategory data={item} showDetail />
              </div>
            </Grid>
          );
        })}
      </Grid>
    );
  },
);

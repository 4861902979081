import React, { FC, useEffect, useReducer, useMemo, CSSProperties, Fragment, useState } from "react";
import { ParamsProps } from "../ParamsProvider";
import { useCommonStyles } from "../../styles/common-styles";
import useFilterStyles from "../../styles/filter-styles";
import { useIntl } from "react-intl";
import { Button, TextField, FormControl, Select } from "@mui/material";
import { useUpdateEffect } from "../../utils/hook-utils";
import { useErrorHandler, ErrorFieldType, ErrorFieldDef } from "../../utils/form-error-utils";
import moment from "moment";
import { PruDatePicker, PruDateTimePicker } from 'src/app/common/components/PruDatePicker';
import { DATE_ERROR_TEXT } from "../../constants";
import AsyncAutocomplete from "../AsyncAutocomplete";

export enum PruFilterItemType {
  FREE_TEXT = "FREE_TEXT",
  DATE_RANGE = "DATE_RANGE",
  DATE_TIME_RANGE = "DATE_TIME_RANGE",
  DROPDOWN = "DROPDOWN",
  ASYNC_DROPDOWN = "ASYNC_DROPDOWN",
  DATE = "DATE",
}

const ITEM_LENGTH = {
  [PruFilterItemType.FREE_TEXT]: 1,
  [PruFilterItemType.DATE_RANGE]: 2,
  [PruFilterItemType.DATE_TIME_RANGE]: 2,
  [PruFilterItemType.DROPDOWN]: 1,
  [PruFilterItemType.ASYNC_DROPDOWN]: 1,
  [PruFilterItemType.DATE]: 1,
};

type PruFilterProps = {
  title: string;
  disableReset?: boolean;
  enableBack?: boolean;
  itemDef: PruFilterItemDef[];
  onChangeFilter: (filterState: PruFilterState) => void;
  fetchData: () => void;
  onBack?: () => void;
  downloadExcel?: () => void;
} & ParamsProps;

export type PruFilterFreeTextDef = {
  type: PruFilterItemType.FREE_TEXT;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
}

export type PruFilterDateRangeDef = {
  type: PruFilterItemType.DATE_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
}

export type PruFilterDateTimeRangeDef = {
  type: PruFilterItemType.DATE_TIME_RANGE;
  fieldFrom: string;
  fieldTo: string;
  displayName: string;
  initialDateFrom?: Date | null;
  initialDateTo?: Date | null;
  defaultDateFrom?: Date | null;
  defaultDateTo?: Date | null;
  enableIfExist?: string[];
}

export type PruFilterDropdownItem = {
  displayName: string;
  value: string;
}

export type PruFilterDropdownDef = {
  type: PruFilterItemType.DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  choices: PruFilterDropdownItem[];
}

export type PruFilterAsyncDropdownDef = {
  type: PruFilterItemType.ASYNC_DROPDOWN;
  style?: CSSProperties;
  field: string;
  displayName: string;
  initialValue?: string;
  defaultValue?: string;
  enableIfExist?: string[];
  fetchList: () => Promise<PruFilterDropdownItem[]>;
}

export type PruFilterDateDef = {
  type: PruFilterItemType.DATE;
  field: string;
  displayName: string;
  initialDate?: Date | null;
  defaultDate?: Date | null;
  enableIfExist?: string[];
}

type PruFilterItemDef = PruFilterFreeTextDef | PruFilterDateRangeDef | PruFilterDateTimeRangeDef |
  PruFilterDropdownDef | PruFilterAsyncDropdownDef | PruFilterDateDef;

type PruFilterState = {
  [id: string]: any;
}

type ModifyFilterAction = {
  type: 'CHANGE_FILTER';
  payload: {
    field: keyof PruFilterState;
    value: Array<Date | null> | string | Date | null;
  }
};

type ResetFilterAction = {
  type: 'RESET_FILTER';
  payload: {
    itemDef: PruFilterItemDef[];
  }
};

type PruFilterAction = ModifyFilterAction | ResetFilterAction;

const paramsInitiator = (mode: "INIT" | "DEFAULT", itemDef: PruFilterItemDef[]): PruFilterState => {
  let state: PruFilterState = {};
  itemDef.forEach(item => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        state[item.field] = (mode === "INIT" ? item.initialValue || item.defaultValue : item.defaultValue) || "";
        break;
      case PruFilterItemType.DATE_RANGE:
        state[item.fieldFrom] = (mode === "INIT" ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === "INIT" ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DATE_TIME_RANGE:
        state[item.fieldFrom] = (mode === "INIT" ? item.initialDateFrom || item.defaultDateFrom : item.defaultDateFrom) || null;
        state[item.fieldTo] = (mode === "INIT" ? item.initialDateTo || item.defaultDateTo : item.defaultDateTo) || null;
        break;
      case PruFilterItemType.DROPDOWN:
        state[item.field] = (mode === "INIT" ? item.initialValue || item.defaultValue : item.defaultValue) || "";
        break;
      case PruFilterItemType.ASYNC_DROPDOWN:
        state[item.field] = (mode === "INIT" ? item.initialValue || item.defaultValue : item.defaultValue) || "";
        break;
      case PruFilterItemType.DATE:
        state[item.field] = (mode === "INIT" ? item.initialDate || item.defaultDate : item.defaultDate) || null;
        break;
    }
  });
  return state;
}

const filterReducer = (state: PruFilterState, action: PruFilterAction) => {
  switch (action.type) {
    case 'CHANGE_FILTER': {
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    }
    case 'RESET_FILTER': {
      return paramsInitiator("DEFAULT", action.payload.itemDef);
    }
  }
}

type ErrorState = {
  mandatory: Record<string, boolean>;
  immediate: Record<string, boolean>;
};

const PruFilter: FC<PruFilterProps> = ({
  title,
  itemDef,
  disableReset,
  enableBack,
  onChangeFilter,
  fetchData,
  onBack,
  downloadExcel,
}) => {
  const { classes: commonClasses } = useCommonStyles();
  const { classes: filterClasses } = useFilterStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [filterState, filterDispatch] = useReducer(filterReducer, paramsInitiator("INIT", itemDef));
  const [showDatePicker, setShowDatePicker] = useState<Boolean>(false);
  const [textDate, setTextDate] = useState<any>();

  const errorDefinition: ErrorFieldDef[] = useMemo(() => {
    let errorDef: ErrorFieldDef[] = [];
    itemDef.forEach(item => {
      if (item.type === PruFilterItemType.DATE_RANGE || item.type === PruFilterItemType.DATE_TIME_RANGE) {
        errorDef.push({
          name: `${item.fieldTo}Before${item.fieldFrom}`,
          fieldType: ErrorFieldType.IMMEDIATE,
          condition: () => {
            const startDate = filterState[item.fieldFrom];
            const endDate = filterState[item.fieldTo];
            if (startDate && endDate) {
              return !!(moment(new Date(startDate)).isAfter(moment(new Date(endDate))));
            } else {
              return false;
            }
          }
        });
      }
    });
    return errorDef;
  }, [itemDef, filterState]);

  const checkIfFilterEnable = (enableIfExist?: string[]) => {
    if (enableIfExist) {
      let enable = true;
      enableIfExist.forEach(item => {
        if (filterState[item] === "" || filterState[item] === null || filterState[item] === undefined) {
          enable = false;
        }
      });
      return enable;
    } else {
      return true;
    }
  }

  const filterItemRender = (item: PruFilterItemDef) => {
    switch (item.type) {
      case PruFilterItemType.FREE_TEXT:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            {showDatePicker ? textDate ?
              <>
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  format="DD/MM/YYYY"
                  value={textDate.startDate}
                  onChange={date => {
                    if (textDate) {
                      setTextDate({
                        ...textDate,
                        startDate: date ? date : moment(new Date()).format("MM/DD/YYYY"),
                      })
                      filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: [date, textDate.endDate] } });
                    }
                    else {
                      filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } });
                    }
                  }}
                />
                <div className="PruFilter-date-divider" />
                <PruDatePicker
                  disabled={!checkIfFilterEnable(item.enableIfExist)}
                  slotProps={{
                    textField: {
                      style: { marginRight: 20 },
                    },
                  }}
                  format="DD/MM/YYYY"
                  value={textDate.endDate}
                  onChange={date => {
                    setTextDate({
                      ...textDate,
                      endDate: date ? date : moment(new Date()).format("MM/DD/YYYY"),
                    })
                    filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: [textDate.startDate, date] } });
                  }}
                />
              </>
              :
              <PruDatePicker
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                format="DD/MM/YYYY"
                value={filterState[item.field]}
                onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })}
              />
              :
              <TextField
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                style={{
                  ...item.style,
                  marginRight: 20
                }}
                margin="dense"
                variant="outlined"
                value={filterState[item.field]}
                onChange={e => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: e.target.value } })}
              />
            }
          </>
        )
      case PruFilterItemType.DATE_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldFrom]}
              onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })}
            />
            <div className="PruFilter-date-divider" />
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              format="DD/MM/YYYY"
              value={filterState[item.fieldTo]}
              onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })}
            />
          </>
        )
      case PruFilterItemType.DATE_TIME_RANGE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldFrom]}
              onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldFrom, value: date } })}
            />
            <div className="PruFilter-date-divider" />
            <PruDateTimePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              slotProps={{
                textField: {
                  error: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`],
                  helperText: errorState.immediate[`${item.fieldTo}Before${item.fieldFrom}`] && DATE_ERROR_TEXT,
                  style: { marginRight: 20 },
                },
              }}
              ampm={false}
              format="DD/MM/YYYY HH:mm"
              value={filterState[item.fieldTo]}
              onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.fieldTo, value: date } })}
            />
          </>
        )
      case PruFilterItemType.DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <FormControl
              style={{
                ...item.style,
                marginRight: 20
              }}
              margin="dense"
              variant="outlined"
            >
              <Select
                native
                disabled={!checkIfFilterEnable(item.enableIfExist)}
                value={filterState[item.field]}
                onChange={e => {
                  if (e.target.value == "Start Date" || e.target.value == "Campaign Start Date" || e.target.value == "Create Date" || e.target.value == "Assigned Date") {
                    setShowDatePicker(true);
                    setTextDate({
                      startDate: moment(new Date()).format("MM/DD/YYYY"),
                      endDate: moment(new Date()).format("MM/DD/YYYY")
                    });
                  }
                  else {
                    setShowDatePicker(false);
                    setTextDate(undefined);
                  };
                  filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: e.target.value as string } });
                }}
              >
                {item.choices.map(choice => (
                  <option key={`dropdown-choice-${choice.value}`} value={choice.value}>{choice.displayName}</option>
                ))}
              </Select>
            </FormControl>
          </>
        );
      case PruFilterItemType.ASYNC_DROPDOWN:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <AsyncAutocomplete
              style={{
                ...item.style,
                marginRight: 20
              }}
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              value={String(filterState[item.field])}
              onChange={(value) => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: value } })}
              getData={item.fetchList}
            />
          </>
        )
      case PruFilterItemType.DATE:
        return (
          <>
            <span className="PruFilter-criteria">{item.displayName} :</span>
            <PruDatePicker
              disabled={!checkIfFilterEnable(item.enableIfExist)}
              format="DD/MM/YYYY"
              value={filterState[item.field]}
              onChange={date => filterDispatch({ type: 'CHANGE_FILTER', payload: { field: item.field, value: date } })}
            />
          </>
        )
      default:
        return <></>;
    }
  }

  const renderFilter = () => {
    let filterRows: React.ReactNode[] = [];
    let tempArr: React.ReactNode[] = [];
    let lengthCount = 0;
    itemDef.forEach((item, index) => {
      if (lengthCount + ITEM_LENGTH[item.type] <= 4) {
        lengthCount += ITEM_LENGTH[item.type];
        tempArr.push(
          <Fragment key={`filter-item-${index}`}>
            {filterItemRender(item)}
          </Fragment>
        )
        if (index >= itemDef.length - 1) {
          filterRows.push([...tempArr]);
        }
      } else {
        filterRows.push([...tempArr]);
        lengthCount = 0;
        tempArr = [];

        lengthCount += ITEM_LENGTH[item.type];
        tempArr.push(
          <Fragment key={`filter-item-${index}`}>
            {filterItemRender(item)}
          </Fragment>
        );
        if (!itemDef[index + 1]) {
          filterRows.push([...tempArr]);
        }
      }
    });
    return filterRows.map((filterRow, index) => (
      <div className="PruFilter-row" key={`PruFilter-row-${index}`}>{filterRow}</div>
    ))
  }

  const {
    errorState,
    onSubmitErrorValidator,
    immediateErrorValidator
  } = useErrorHandler<ErrorState>(filterState, errorDefinition);

  // useUpdateEffect(() => {
  //   onChangeFilter(filterState);
  //   immediateErrorValidator();
  // }, [filterState]);

  const getFilterSearch = () => {
    onChangeFilter(filterState);
    immediateErrorValidator();
  }

  const filterList = () => {
    getFilterSearch();
    const { hasError } = onSubmitErrorValidator();
    if (!hasError) {
      fetchData();
    }
  };

  const keyPressSearch = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      filterList();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyPressSearch);
    return () => {
      window.removeEventListener('keydown', keyPressSearch);
    }
    // eslint-disable-next-line
  }, [filterState]);

  return (
    <div style={{ marginBottom: 20 }} className={filterClasses.root}>
      <div style={{ marginBottom: 5 }} className="PruFilter-header-container">
        <div className={commonClasses.header}>
          {title}
        </div>
        <div className="PruFilter-row">
          {enableBack &&
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={() => { if (onBack) onBack(); }}
            >
              {Translation('app.button.back')}
            </Button>
          }
          {downloadExcel &&
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={downloadExcel}
            >
              Download
            </Button>
          }
          {!disableReset && (
            <Button
              style={{ marginRight: 20 }}
              variant="contained"
              color="inherit"
              onClick={() => filterDispatch({ type: 'RESET_FILTER', payload: { itemDef } })}
            >
              {Translation('app.button.reset')}
            </Button>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={filterList}
          >
            {Translation('app.button.search')}
          </Button>
        </div>
      </div>
      {renderFilter()}
    </div>
  )
}

export default PruFilter;
import { Box } from '@mui/material';
import { get, set } from 'lodash';
import { ProColumns } from 'src/app/common/components/ProTable';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';

interface Params {
  id: number;
  name: string;
}

export const getColumns = ({
  t,
  keyword,
}: {
  t: (val: string) => string;
  keyword?: string;
}): ProColumns<AgentItem>[] => {
  return [
    {
      title: t('agencyCampaign.common.unit'),
      dataIndex: 'unit',
      sorter: true,
      width: '290px',
      render: (val, row) => {
        return (
          <Box>
            <HighlightMatchedText highLightText={keyword ?? ''}>
              {row.unit ? (row.unit as string) : '-'}
            </HighlightMatchedText>
          </Box>
        );
      },
    },
    {
      title: t('agencyCampaign.common.businessName'),
      dataIndex: 'businessName',
      width: '290px',
      render: (val, row) => {
        return (
          <Box>
            <HighlightMatchedText highLightText={keyword ?? ''}>
              {row?.name?.enUs?.displayName ?? '-'}
            </HighlightMatchedText>
          </Box>
        );
      },
    },
    {
      title: t('agencyCampaign.common.agentCode'),
      dataIndex: 'agentCode',
      sorter: true,
      width: '176px',
      render: (val, row) => {
        return (
          <Box>
            <HighlightMatchedText highLightText={keyword ?? ''}>
              {row.agentCode ? (row.agentCode as string) : '-'}
            </HighlightMatchedText>
          </Box>
        );
      },
    },
  ];
};

import apiClient from "../axios";
import { Dispatch } from "react";
import { apiErrorHandler } from "../apiErrorHandler";
import { AgentDesignation } from "../../types/common-types";
import { ADMIN_URL } from 'src/app/common/network';

const ENABLE_DESIGNATION = window.envConfig["ENABLE_DESIGNATION"] === 'true';
const ENABLE_ZONE = window.envConfig["ENABLE_ZONE"] === 'true';


export const getDesignations = async (dispatch?: Dispatch<any>): Promise<AgentDesignation> => {
  return apiClient.get<AgentDesignation>(`${ADMIN_URL}/designation`)
    .then(response => response.data)
    .catch(err => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getZones = async (dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/agents/zones`);
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }

}

export const getAgentDesignations = async (dispatch?: Dispatch<any>) => {
  try {
    return apiClient.get(`${ADMIN_URL}/agents/designations`);
  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
}

export enum TargetApplicantGroup {
  RM_AND_UP = 'RM & Up',
  ALL_AGENT_EXCLUDE_LA = 'All Designations exclude LA',
  ALL_AGENT_INCLUDE_LA = 'All Designations include LA'
};

const getDesignationValueMap = (designationsData: any) => {
  const DesignationValueMap: any = {};

  designationsData && designationsData.forEach((item: any) => {
    DesignationValueMap[item.shortName] = {
      code: item.code,
      description: item.description,
      departmentCode: item.departmentCode,
      shortName: item.shortName
    }
  });

  return DesignationValueMap;
}

const formatDesignations = (DesignationValueMap: any) => {
  const RM_AND_UP_Items = ['SRD', 'RD', 'SRM', 'RM']

  let AllDesignationExcludeLA: any[] = [];
  let AllDesignationIncludeLA: any[] = [];
  let RMupDesignations: any[] = [];

  DesignationValueMap && Object.keys(DesignationValueMap).forEach((designation) => {
    if (RM_AND_UP_Items.includes(designation)) {
      RMupDesignations.push(DesignationValueMap[designation]);
    }

    if (designation !== 'LA' && !RM_AND_UP_Items.includes(designation)) {
      AllDesignationExcludeLA.push(DesignationValueMap[designation]);
    }

    if (designation === 'LA') {
      AllDesignationIncludeLA.push(DesignationValueMap[designation]);
    }
  });

  const designation = {
    [TargetApplicantGroup.RM_AND_UP]: RMupDesignations,
    [TargetApplicantGroup.ALL_AGENT_EXCLUDE_LA]: AllDesignationExcludeLA,
    [TargetApplicantGroup.ALL_AGENT_INCLUDE_LA]: AllDesignationIncludeLA
  };

  return designation;
}

const formatZones = (zonesData: any) => {
  const zones: string[] = [];
  zonesData && zonesData.forEach((item: any) => {
    zones.push(item.code);
  });

  zones.sort();

  return zones;
}


export const fetchDesignationAndZone = async (dispatch?: Dispatch<any>): Promise<any> => {

  try {
    const designationsRes = ENABLE_DESIGNATION ? await getAgentDesignations() : { data: [] };
    const zonesRes = ENABLE_ZONE ? await getZones() : { data: [] };

    const designationsData = designationsRes.data;
    const zonesData = zonesRes.data;

    const DesignationValueMap: any = getDesignationValueMap(designationsData);

    const designations = formatDesignations(DesignationValueMap);
    const zones = formatZones(zonesData);

    const dataMap = {
      "designation": designations,
      "designationValue": DesignationValueMap,
      "zone": zones,
    };
    return dataMap;

  } catch (error: any) {
    throw apiErrorHandler(error, dispatch);
  }
}

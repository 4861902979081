import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  infoBox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: 'white',
    height: 90,
    marginBottom: 12,
  },
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 5,
  },
  value: {},
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    width: '20%',
    minWidth: '200px',
    paddingRight: 15,
  },
  sectionTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fieldContainer: {
    width: '40%',
    minWidth: 200,
    boxSizing: 'border-box',
    marginRight: 15,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 56,
  },
  field: {
    fontSize: '1rem',
    marginRight: 10,
  },
  mandatory: {
    color: 'red',
  },
  fieldSectionContainer: {
    flex: 1,
    backgroundColor: 'white',
    padding: 20,
  },
  errorText: {
    fontSize: 10,
    color: '#f018a6',
    paddingLeft: 14,
  },
  formInput: { width: '100%', height: 45, marginBottom: 8 },
  mt_24: {
    marginTop: 24,
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

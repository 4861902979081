import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { get as lodashGet } from 'lodash';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { createBlobAsAgent, getBlobAsAgent } from 'src/app/common/network/crud/resourceCrud';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { useIntl } from 'react-intl';
import { fileUpload } from 'src/app/common/resource/resource-utils';

export type AttachmentProps = {
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  formState: any;
  sectionKey: string;
  errorState: any;
  disabledEdit?: boolean;
  disabled?: boolean;
  validationHandler: any;
  formStateUpdated?: boolean; // todo: other field's update will affect other
  onSaveCampaign: (val: any) => void;
  campaignObjId?: string;
  // onStepChange: () => void;
  isProofUpdated?: boolean;
  isAttachmentUpdated?: boolean;
  onSaveAttachment: (fieldKey: string) => void;
};

export const useAttachment = ({
  formState,
  campaignTypeStructureData,
  validationHandler,
  sectionKey,
  formDispatch,
}: AttachmentProps) => {
  const intl = useIntl();
  const { t: Translation } = useTranslation();
  const dispatch = useDispatch();
  const [uploadAttachmentProgress, setUploadAttachmentProgress] = useState<number>();
  const [uploading, setUploading] = useState(false);

  const getMimeType = (file: File) => {
    if (file.type) {
      return file.type;
    }
    const mime = {
      doc: 'application/msword',
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
      docm: 'application/vnd.ms-word.document.macroEnabled.12',
      dotm: 'application/vnd.ms-word.template.macroEnabled.12',
      xls: 'application/vnd.ms-excel',
      xlt: 'application/vnd.ms-excel',
      xla: 'application/vnd.ms-excel',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
      xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
      xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
      xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
      xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    };
    type MimeTypes = keyof typeof mime;
    const fileName = file.name;
    const fileExtension = (fileName?.split('.')?.pop()?.toLowerCase() || '') as MimeTypes;
    return mime[fileExtension];
  };

  const attachmentData = campaignTypeStructureData.sections.filter((item) => item.key === sectionKey)[0];

  const uploadAttachment = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setUploading(true);
    const fieldId: string = e.target.getAttribute('id') ?? '';
    const arrFile: File[] = lodashGet(e, 'target.files', []);
    if (arrFile.length > 0) {
      let arrResource: unknown[] = [],
        arrResourceIds: unknown[] = [];
      for (let index = 0; index < arrFile.length; index++) {
        const file: File = arrFile[index];
        const mimeType = getMimeType(file);
        const filename = file.name;
        const extensions = ['png', 'jpg', 'jpeg', 'xlsx', 'docx', 'pdf'];

        if (!filename || !extensions.some((ext) => filename.toLowerCase().includes(`.${ext}`))) {
          return dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                title: Translation('global.max.file.type.notSupported'),
                content: intl.formatMessage(
                  {
                    id: 'global.max.file.type.allow',
                  },
                  {
                    num: extensions.join('/'),
                  },
                ),
              },
            ]),
          );
        }

        try {
          const createBlobRes = await createBlobAsAgent(
            { mimeType, accessLevel: 'anonymous', module: 'agencyCampaign', originalFilename: file.name },
            dispatch,
          );
          await fileUpload(createBlobRes[0].url, file, setUploadAttachmentProgress);
          const blobDetail = await getBlobAsAgent({ resourceIds: createBlobRes[0].blobId }, dispatch);
          const result = blobDetail[0];
          if (result) {
            validationHandler.onDismissErrorHandler(`${sectionKey}_${fieldId}`, result);
            arrResource.push(result);
            arrResourceIds.push(result.blobId);
          }
        } catch (err) {
        } finally {
          setUploadAttachmentProgress(undefined);
        }
      }

      const source: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
      const sourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);
      formDispatch({
        type: 'MODIFY_FIELD',
        payload: {
          section: sectionKey,
          field: fieldId,
          value: { resources: [...source, ...arrResource], resourceIds: [...sourceId, ...arrResourceIds] },
        },
      });
    }
    setUploading(false);
  };

  const removeFile = (fieldId: string, index: number) => {
    const arrSource: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resources`, []);
    const arrSourceId: any[] = lodashGet(formState, `${sectionKey}.${fieldId}.resourceIds`, []);

    arrSource.splice(index, 1);
    arrSourceId.splice(index, 1);
    // trigger warning: 'This field is mandatory' when no attachment
    const value = arrSource.length > 0 ? { resources: [...arrSource], resourceIds: [...arrSourceId] } : undefined;
    formDispatch({
      type: 'MODIFY_FIELD',
      payload: {
        section: sectionKey,
        field: fieldId,
        value: value,
      },
    });
  };

  const viewFile = (url: string) => {
    if ((window as any).ReactNativeWebView) {
      (window as any).ReactNativeWebView.postMessage(
        `{ "action" : "loadurlwithtarget", "payload" : { "target" : "_blank", "url" : "${encodeURIComponent(url)}" } }`,
      );
    } else {
      window.open(url, '_blank');
    }
  };

  return {
    attachmentData,
    Translation,
    uploadAttachment,
    removeFile,
    viewFile,
  };
};

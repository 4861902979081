import moment from 'moment';

export enum DateFormat {
  date = 'DD/MM/yyyy',
  shortDate = 'DD/MM',
  datetime = 'DD/MM/yyyy HH:mm',
  longDatetime = 'DD/MM/yyyy HH:mm:ss',
  month = 'MM/yyyy',
  time = 'HH:mm',
  longTime = 'HH:mm:ss',
  utcTime = 'YYYY-MM-DDTHH:mm:ssZ',
}

/**
 * Formats a date string into a specific format.
 *
 * @param {string} date - The date string to format.
 * @returns {string} The formatted date string.
 * @description For example, "2023-10-31T01:43:05.254Z" becomes "31/10/2023 01:43".
 */
export const formatDate = (date: string, dateTye: DateFormat = DateFormat.datetime) => {
  if (!date) return date;
  if (!moment(date).isValid()) return date;
  return moment(date).format(dateTye);
};

export const checkDateEquals = (
  date1: number | string | Date,
  date2: number | string | Date,
  format: string = 'MM/DD',
): boolean => {
  const d1 = moment(new Date(date1)).format(format);
  const d2 = moment(new Date(date2)).format(format);
  return d1 === d2;
};

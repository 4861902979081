import React, { memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-application.style';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { CampaignInfoComponent } from './campaign-info/campaign-info.component';
import {
  CampaignParticipantComponent,
  CampaignParticipantComponentProps,
} from './campaign-participant/campaign-participant.component';
import { CampaignBriefComponent } from './campaign-brief/campaign-brief.component';
import { Attachment } from './attachment/attachment.component';

type ComponentProps = ParamsProps & {
  campaign: any;
  campaignTypeStructureData: CampaignStructureItem;
  formDispatch: (data: any) => void;
  formState: any;
  errorState: any;
  campaignStatus?: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  validationHandler: any;
  campaignEndDateEqualToStartDate?: any;
  campaignEndDateGreaterThanTarget?: any;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  onSaveCampaign: (val: any) => void;
  formStateUpdated?: boolean;
  isCampaignOwner: boolean;
  isParticipantUpdated?: boolean;
  isProofUpdated?: boolean;
  isAttachmentUpdated?: boolean;
  isCampaignInfoUpdated?: boolean;
  updateRSVP: (rsvpStatus: string) => void;
  checkStepHaveError: () => Record<string, boolean>;
  onSaveInfo: () => void;
  onSaveAttachment: (fieldKey: string) => void;
} & Omit<CampaignParticipantComponentProps, 'sectionKey' | 'onStepChange'>;

export const TabApplicationComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  // const {} = useTabApplication(props);
  const {
    cacheParams,
    campaignTypeStructureData,
    campaign,
    formState,
    errorState,
    disabled,
    disabledEdit,
    validationHandler,
    campaignEndDateEqualToStartDate,
    campaignEndDateGreaterThanTarget,
    isCampaignOwner,
    formDispatch,
    onSaveCampaign,
    formStateUpdated,
    isProofUpdated,
    isAttachmentUpdated,
    isCampaignInfoUpdated,
    checkStepHaveError,
    updateRSVP,
    onSaveInfo,
    onSaveAttachment,
    ...rest
  } = props;

  return (
    <>
      <CampaignBriefComponent
        campaignTypeStructureData={campaignTypeStructureData}
        campaign={campaign}
        updateRSVP={updateRSVP}
        isCampaignOwner={isCampaignOwner}
      />

      <CampaignInfoComponent
        onSaveCampaign={onSaveCampaign}
        campaignTypeStructureData={campaignTypeStructureData}
        campaign={campaign}
        formState={formState}
        formDispatch={formDispatch}
        errorState={validationHandler.errorState}
        disabled={disabled}
        disabledEdit={disabledEdit}
        validationHandler={validationHandler}
        campaignEndDateEqualToStartDate={campaignEndDateEqualToStartDate}
        campaignEndDateGreaterThanTarget={campaignEndDateGreaterThanTarget}
        formStateUpdated={isCampaignInfoUpdated}
        isCampaignOwner={isCampaignOwner}
        campaignObjId={rest.campaignObjId}
        checkStepHaveError={checkStepHaveError}
        onSaveInfo={onSaveInfo}
      />
      {isCampaignOwner ? (
        <>
          <CampaignParticipantComponent
            campaignTypeStructureData={campaignTypeStructureData}
            formDispatch={formDispatch}
            formState={formState}
            errorState={errorState}
            disabled={disabled}
            disabledEdit={disabledEdit}
            validationHandler={validationHandler}
            sectionKey="participant"
            {...rest}
          />
          <Attachment
            campaignObjId={rest.campaignObjId}
            sectionKey="attachments"
            campaignTypeStructureData={campaignTypeStructureData}
            formDispatch={formDispatch}
            formState={formState}
            errorState={errorState}
            disabledEdit={disabledEdit}
            disabled={disabled}
            validationHandler={validationHandler}
            formStateUpdated={formStateUpdated}
            onSaveCampaign={onSaveCampaign}
            isAttachmentUpdated={isAttachmentUpdated}
            isProofUpdated={isProofUpdated}
            onSaveAttachment={onSaveAttachment}
          />
        </>
      ) : null}
    </>
  );
});

import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { fetchMassCampaignList } from '../../../network/campaignCurd';
import { getColumns } from './util/list.util';
import { queryData, generateCompareFunction } from '../../../util/campaign-list.util';
import { useHistory } from 'react-router-dom';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { CampaignElement } from '../../../types/campaign-types';
import { MASS_DETAIL_PATH } from '../mass-campaign-routes';
import { useStyles } from './list.style';

interface HookProps {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  // other params
}

export const useList = ({ cacheParams }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const history = useHistory();
  const [keyword, setKeyword] = useState('');
  const dispatch = useDispatch();

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
        keyword,
        styles,
      }),
    [Translation, keyword, styles],
  );
  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const query = {};
      const res = await fetchMassCampaignList(query, dispatch);
      let data = res.campaigns;
      if (params.keyword) {
        data = queryData(params.keyword, data);
      }
      if (sort) {
        const compareFunction = generateCompareFunction(sort);
        data.sort(compareFunction);
      }
      data = data.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);
      if (res) {
        return {
          success: true,
          data: data,
          total: res.totalDocs,
        };
      } else {
        return {
          success: false,
          data: [],
          total: 0,
        };
      }
    },
    [],
  );

  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);
      setKeyword(keyword);
    },
    [cacheParams],
  );

  const toDetail = (e: CampaignElement) => {
    history.push(`${MASS_DETAIL_PATH}/${e._id}`);
  };

  return {
    getData,
    columns,
    params: {},
    toDetail,
    onFilterChange,
  };
};

import { Paper } from '@mui/material';
import { get as lodashGet } from 'lodash';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { AlertType, appendAlertItem } from '../../../../../../../../redux/common/commonSlice';
import { createBlobAsAgent, getBlobAsAgent } from '../../../../../../../common/network/crud/resourceCrud';
import { fileUpload } from '../../../../../../../common/resource/resource-utils';
import { CampaignStructureItem } from '../../../../../types/campaign-types';
import { SectionMask } from 'src/app/common/components/section-mask/section-mask.component';
import { useAttachment } from './attachment.hook';
import { useStyles } from './attachment.style';
import { AttachmentUploaderComponent } from '../../attachment-uploader/attachment-uploader.component';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { AttachmentProps } from './attachment.hook';

export const Attachment: React.FC<AttachmentProps> = ({
  sectionKey,
  campaignTypeStructureData,
  formDispatch,
  formState,
  errorState,
  disabledEdit,
  disabled,
  validationHandler,
  formStateUpdated,
  onSaveCampaign,
  campaignObjId,
  // onStepChange,
  isProofUpdated,
  isAttachmentUpdated,
  onSaveAttachment,
}) => {
  const { classes } = useStyles();

  const { attachmentData, uploadAttachment, removeFile, viewFile, Translation } = useAttachment({
    sectionKey,
    campaignTypeStructureData,
    formDispatch,
    formState,
    errorState,
    disabledEdit,
    disabled,
    validationHandler,
    formStateUpdated,
    onSaveCampaign,
    campaignObjId,
    isProofUpdated,
    isAttachmentUpdated,
    onSaveAttachment,
  });

  if (lodashGet(attachmentData, 'sectionFields', []).length < 1) {
    return <span className={classes.p_10}>{Translation('agencyCampaign.create.noAttachmentRequired')}</span>;
  }

  return (
    <>
      {attachmentData.sectionFields.map((fieldItem: any, index) => {
        return (
          <SectionMask key={index} showMask={!campaignObjId} text={Translation('agencyCampaign.detail.screen.mask')}>
            <Paper elevation={3} sx={{ marginBottom: '24px' }}>
              <AttachmentUploaderComponent
                fieldItem={fieldItem}
                sectionKey={sectionKey}
                formState={formState}
                errorState={errorState}
                disabled={disabled}
                disabledEdit={disabledEdit}
                isAttachmentUpdated={isAttachmentUpdated}
                isProofUpdated={isProofUpdated}
                onUpload={uploadAttachment}
                removeFile={removeFile}
                viewFile={viewFile}
                onSave={onSaveAttachment}
              />
            </Paper>
          </SectionMask>
        );
      })}
    </>
  );
};

import React, { FC } from "react";
import { makeStyles } from 'tss-react/mui';
import { useIntl } from "react-intl";

const useStyles = makeStyles()((theme) => ({
  root: {
    fontSize: 20,
    fontWeight: "bold",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

const LandingPage: FC = () => {
  const { classes } = useStyles();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <div className={classes.root}>
      {Translation("landing.title")}
    </div>
  )
}

export default LandingPage;
import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, Checkbox, createSvgIcon } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './add-prospect-dialog.style';
import { useAddProspectDialog } from './add-prospect-dialog.hook';
import PruDialog from 'src/app/common/components/PruDialog';
import { useHistory } from 'react-router-dom';
import { CheckCircleSharp, CheckCircleOutlineSharp, CircleOutlined } from '@mui/icons-material';
import { SOURCE_TYPE } from '../../../../constants';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  open: boolean;
  onCancel: () => void;
  onConfirm?: () => void;
};

const AddMemberIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 6C14 8.76142 11.7614 11 9 11C6.23858 11 4 8.76142 4 6C4 3.23858 6.23858 1 9 1C11.7614 1 14 3.23858 14 6ZM12 6C12 7.65685 10.6569 9 9 9C7.34315 9 6 7.65685 6 6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6Z"
      fill="currentColor"
    />
    <path d="M20 11V8H18V11H15V13H18V16H20V13H23V11H20Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 12C6.23858 12 4 14.2386 4 17V23H14V17C14 14.2386 11.7614 12 9 12ZM12 21V17C12 15.3431 10.6569 14 9 14C7.34315 14 6 15.3431 6 17V21H12Z"
      fill="currentColor"
    />
  </svg>,
  'addMember',
);

const AddCustomerIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 11C11.7614 11 14 8.76142 14 6C14 3.23858 11.7614 1 9 1C6.23858 1 4 3.23858 4 6C4 8.76142 6.23858 11 9 11ZM9 9C10.6569 9 12 7.65685 12 6C12 4.34315 10.6569 3 9 3C7.34315 3 6 4.34315 6 6C6 7.65685 7.34315 9 9 9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4 17C4 14.2386 6.23858 12 9 12C11.7614 12 14 14.2386 14 17V23H4V17ZM12 17V21H6V17C6 15.7034 6.82255 14.5989 7.9743 14.1799L7.5 17.5L9 18.5L10.5 17.5L10.0257 14.1799C11.1775 14.5989 12 15.7034 12 17Z"
      fill="currentColor"
    />
    <path d="M16 7H21V9H16V7Z" fill="currentColor" />
    <path d="M16 11H21V13H16V11Z" fill="currentColor" />
    <path d="M16 15H21V17H16V15Z" fill="currentColor" />
  </svg>,
  'addCustomer',
);

export const AddProspectDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, onConfirm, onCancel } = props;
  const { type, checkedTerms, onCheckChangen, setType } = useAddProspectDialog();
  const history = useHistory();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const onOk = () => {
    if (type && checkedTerms) {
      onConfirm && onConfirm();
      history.push(`/prospect/create/${type}`);
    }
  };

  return (
    <PruDialog
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      dialogTitle={Translation('prospect.important_reminder')}
      canncelBtnText={Translation('app.button.cancel').toUpperCase()}
      confirmBtnText={Translation('app.button.confirm').toUpperCase()}
      disableConfirmBtn={!(type && checkedTerms)}
    >
      <div className={styles.wrap}>
        <div className={styles.btnContainer}>
          <Button
            onClick={() => setType(SOURCE_TYPE.NEW_PROSPECT)}
            startIcon={<AddMemberIcon />}
            variant="outlined"
            className={cx(styles.btn, { [styles.active]: type === SOURCE_TYPE.NEW_PROSPECT })}
          >
            {Translation('prospect.add_new_prospect')}
          </Button>
          <Button
            onClick={() => setType(SOURCE_TYPE.EXISTING_CUSTOMER)}
            startIcon={<AddCustomerIcon />}
            variant="outlined"
            className={cx(styles.btn, { [styles.active]: type === SOURCE_TYPE.EXISTING_CUSTOMER })}
          >
            {Translation('prospect.import_from_customers')}
          </Button>
        </div>
        <span className={styles.description}>{Translation('prospect.add_propspect_important_reminder_desc')}</span>
        <div className={styles.radioContainer}>
          <Checkbox
            checked={checkedTerms}
            onChange={onCheckChangen}
            className={styles.checkbox}
            icon={
              <CircleOutlined
                style={{
                  color: '#CCCCCC',
                }}
              />
            }
            checkedIcon={<CheckCircleSharp />}
          />
          <span>{Translation('prospect.add_prospect_important_reminder_confirm_tip')}</span>
        </div>
      </div>
    </PruDialog>
  );
});

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get, forEach } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchCampaignDetail } from '../../../network/campaignCurd';
import { CampaignDetail, CampaignLeadItem, FollowUpStatusEnum } from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';

interface HookProps {
  id: string;
  // other params
}

export const useDetailPage = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const history = useHistory();
  // redux
  const dispatch = useDispatch();

  const { data, error, loading, run, runAsync } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: true,
  });

  const convertLeadData = (data: any): CampaignLeadItem[] => {
    if (!data) return [];
    const leads: CampaignLeadItem[] = [];
    data.leads.forEach((lead: any) => {
      const isDead = [FollowUpStatusEnum.dead, FollowUpStatusEnum.contactFailure].includes(lead.followUpStatus);
      const isNew = lead.agentStatus === FollowUpStatusEnum.pending;
      let remark = '';
      if (isDead) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.reasons;
      }
      if (lead.followUpStatus === FollowUpStatusEnum.issued) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.issuedPolicy;
      }

      leads.push({
        _id: lead._id,
        name: lead.prospectDetail.alias,
        labels: lead.labels,
        campaignName: data.name,
        sla: lead?.expiredAt ?? '',
        leadSource: data.name,
        status: isNew ? FollowUpStatusEnum.pending : mapLeadStatus(lead),
        remark: remark ?? [],
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt,
        feedback: lead.feedback,
      });
    });
    return leads;
  };

  // notes: followUpStatus is lead next status not currently status.
  const mapLeadStatus = (lead: any) => {
    if (lead.followUp.length < 1) {
      return FollowUpStatusEnum.pendingToContact;
    } else {
      return lead.followUp[lead.followUp.length - 1].status;
    }
  };

  const convertCampaignInfo = (data: any): CampaignDetail => {
    const newCampaignData: CampaignDetail = { ...data } as CampaignDetail;
    return newCampaignData;
  };

  useEffect(() => {
    run();
  }, []);

  const navToList = () => {
    history.goBack();
  };

  return {
    loading,
    data: data,
    leadsData: convertLeadData(data),
    run,
    navToList,
  };
};

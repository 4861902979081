import React, { FC, memo, useMemo, useState } from 'react';
import { Button, Grid, List, ListItemButton, ListItemText, Popover, TextField } from '@mui/material';
import { ChevronRight, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useStyles } from './cascading-selector.style';
import { useCascadingSelector } from './cascading-selector.hook';

export type Option = {
  label: string;
  value: string;
  child?: Option[];
};

export type ComponentProps = {
  data: Option[];
  placeholder?: string;
  onSelectComplete?: (selected: Option[]) => void;
  splitChar?: string;
  defaultSelected?: Option[];
};

export const CascadingSelector = memo((props: ComponentProps) => {
  const {
    sourceData,
    mainOpen,
    mainAnchorEl,
    selectedItem,
    displayText,
    inputRef,
    showDisplayText,
    handleMainClick,
    handleMainClose,
    handleListItemClick,
    onClickDisplayText,
    onInputBlur,
    onInputChanged,
    mainAnchorElRef,
    inputValue,
  } = useCascadingSelector(props);

  const { classes, cx } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div>
      <div aria-describedby="main-popover" ref={mainAnchorElRef} onClick={handleMainClick} className={styles.container}>
        {showDisplayText ? (
          <div onClick={onClickDisplayText} className={styles.displayText}>
            {displayText}
          </div>
        ) : null}
        <TextField
          value={inputValue}
          inputRef={inputRef}
          placeholder="input "
          id="outlined-basic"
          variant="outlined"
          onClick={(event) => event.stopPropagation()}
          className={cx(styles.input, { [styles.hideInput]: showDisplayText })}
          onBlur={onInputBlur}
          onChange={onInputChanged}
          autoComplete="off"
          inputProps={{ style: { padding: '0px' } }}
        />
        {mainOpen ? <ExpandMore /> : <ExpandLess />}
      </div>
      <Popover
        id="main-popover"
        open={mainOpen}
        anchorEl={mainAnchorEl}
        onClose={handleMainClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={styles.popover}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <Grid container>
          <Grid item className={styles.parentGrid}>
            <List className={styles.list}>
              {sourceData &&
                sourceData.map((item) => {
                  const className = `${styles.selectItem} ${
                    selectedItem && selectedItem[0] && selectedItem[0].value === item.value ? styles.active : ''
                  }`;
                  return (
                    <ListItemButton onClick={() => handleListItemClick(item)} key={item.value} className={className}>
                      <span className={styles.selectContent}>
                        <ListItemText primary={item.label} />
                        <ChevronRight />
                      </span>
                    </ListItemButton>
                  );
                })}
            </List>
          </Grid>
          <>
            {selectedItem &&
              selectedItem.map((subItem: Option, index) => {
                const level = index + 1 + 1;
                if (subItem.child) {
                  return (
                    <Grid key={subItem.value} item className={styles.parentGrid}>
                      <List className={styles.list}>
                        {subItem &&
                          subItem.child.map((child: Option) => {
                            const className = `${styles.selectItem} ${
                              selectedItem && selectedItem[level - 1] && selectedItem[level - 1].value === child.value
                                ? styles.active
                                : ''
                            }`;
                            return (
                              <ListItemButton
                                key={child.value}
                                onClick={() => handleListItemClick(child, level)}
                                className={className}
                              >
                                <span className={styles.selectContent}>
                                  <ListItemText primary={child.label} />
                                  {child.child && <ChevronRight />}
                                </span>
                              </ListItemButton>
                            );
                          })}
                      </List>
                    </Grid>
                  );
                }
              })}
          </>
        </Grid>
      </Popover>
    </div>
  );
});

import { ProspectSourceTypes, ProspectTypes } from './types/prospect-types';

export const PROSPECT_TITLE = 'menu.title.prospects';

export const PROSPECT_NAMESPACE = 'PROSPECT';

export const PROSPECT_BASE_PATH = '/prospect';
export const PROSPECT_LIST_PATH = '/list';
export const AUTO_PURGE_LIST = '/purgelist';
export const PROSPECT_ADD_PROSPECT_PATH = '/create';
export const PROSPECT_DETAIL_PATH = '/detail';
export const PROSPECT_REFERRER = '/referredBy';

export enum SOURCE_TYPE {
  NEW_PROSPECT = 'newProspect',
  EXISTING_CUSTOMER = 'existingCustomer',
}

export const GenderMap = {
  M: 'pruleads.prospect.list.male',
  F: 'pruleads.prospect.list.female',
};

export const ProspectTypesConfig = {
  [ProspectTypes.prospect_type_agent]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeAgent',
  },
  [ProspectTypes.prospect_type_candidate]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeCandidate',
  },
  [ProspectTypes.prospect_type_customer]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeCustomer',
  },
  [ProspectTypes.prospect_type_non_candidate]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCandidate',
  },
  [ProspectTypes.prospect_type_non_customer]: {
    i18nKey: 'pruleads.prospect.list.prospectTypeNonCustomer',
  },
};

export const ProspectSourceTypesConfig = {
  [ProspectSourceTypes.prospect_source_agent]: {
    i18nKey: 'pruleads.prospect.list.salesAgent',
  },
  [ProspectSourceTypes.prospect_source_marketing]: {
    i18nKey: 'pruleads.prospect.list.salesMarketing',
  },
};

export enum PROSPECT_DETAIL_TABS {
  LEAD,
  APPOINTMENT,
  INFORMATION,
}

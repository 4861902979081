import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './simple-date-range-picker.style';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export type DateRangePickerType = {
  startDate: string;
  endDate: string;
};

type ComponentProps = ParamsProps & {
  value?: DateRangePickerType;
  onChange?: (value: DateRangePickerType) => void;
};

export const SimpleDateRangePickerComponent: React.FC<ComponentProps> = memo(({ value, onChange }: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        label={Translation('agencyCampaign.common.startDate')}
        value={value?.startDate && dayjs(value?.startDate)}
        format="DD/MM/YYYY"
        onChange={(val) => {
          onChange?.({
            startDate: val ? dayjs(val).toISOString() : '',
            endDate: value?.endDate || '',
          });
        }}
        slotProps={{
          field: {
            clearable: true,
            onClear: () => {
              onChange?.({
                startDate: '',
                endDate: value?.endDate || '',
              });
            },
          },
        }}
      />
      <div className={styles.toBox}>
        4<span className={styles.toFont}>to</span>
      </div>
      <DatePicker
        label={Translation('agencyCampaign.common.endDate')}
        value={value?.endDate && dayjs(value?.endDate)}
        onChange={(val) => {
          onChange?.({
            startDate: value?.startDate || '',
            endDate: val ? dayjs(val).toISOString() : '',
          });
        }}
        format="DD/MM/YYYY"
        slotProps={{
          field: {
            clearable: true,
            onClear: () => {
              onChange?.({
                startDate: '',
                endDate: value?.endDate || '',
              });
            },
          },
        }}
      />
    </LocalizationProvider>
  );
});

import React, { useMemo } from 'react';
import { LeadLabel } from 'src/app/modules/Campaign/types/campaign-types';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

const tagConfig = {
  // currently only support these two types
  [LeadLabel.existingCustomer]: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
    i18nKey: 'campaign.lead.label.existingCustomer',
  },
  [LeadLabel.potentialCustomer]: {
    color: '#4386E5',
    bgColor: '#EBF3FF',
    i18nKey: 'campaign.lead.label.potentialCustomer',
  },
  [LeadLabel.referralCode]: {
    color: '#24C791',
    bgColor: '#DFF7EF',
    i18nKey: 'campaign.lead.label.referralCode',
  },
};

export const useLeadTypesTag = () => {
  // i18n
  const { t: Translation } = useTranslation();

  const configs = useMemo(() => {
    return Object.fromEntries(
      Object.entries(tagConfig).map(([key, value]) => [key, { ...value, text: Translation(value.i18nKey) }]),
    );
  }, [Translation]);

  return {
    configs,
  };
};

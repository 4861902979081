import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  CircularProgress,
  FormControlLabel,
  LinearProgress,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './delete-prospect-dialog.style';
import { useDeleteProspectDialog } from './delete-prospect-dialog.hook';
import PruDialog from 'src/app/common/components/PruDialog';
import { ProspectDetail } from 'src/app/modules/Prospect/types/prospect-types';
import { deleteSpecificProspect } from 'src/app/modules/Prospect/network/prospectCurd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PROSPECT_BASE_PATH } from 'src/app/modules/Prospect/constants';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  open: boolean;
  onClose: () => void;
  prospectDetail: ProspectDetail;
};

export const DeleteProspectDialog: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { open, prospectDetail, onClose } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const Translation = (id: string) => intl.formatMessage({ id });
  const history = useHistory();

  const [shouDialog, setShowDialog] = React.useState(open);
  const [deleting, setDeleting] = React.useState(false);

  useEffect(() => {
    setShowDialog(open);
  }, [open]);

  const onCancel = () => {
    setShowDialog(false);
    onClose();
  };

  const onOk = () => {
    if (deleting) return;
    if (prospectDetail.activeLeads) {
      onCancel();
    } else {
      //delete
      setDeleting(true);
      deleteSpecificProspect(prospectDetail.id, dispatch)
        .then((res) => {
          onCancel();
          history.push(PROSPECT_BASE_PATH);
        })
        .finally(() => {
          setDeleting(false);
        });
    }
  };

  const title = useMemo(() => {
    if (prospectDetail.activeLeads) {
      return Translation('prospect.deleteFailed');
    }
    return Translation('app.button.delete');
  }, [prospectDetail.activeLeads]);

  const description = useMemo(() => {
    if (prospectDetail.activeLeads) {
      return Translation('prospect.deleteFailed.withLeads');
    }
    return Translation('prospect.delete.confirm');
  }, [prospectDetail.activeLeads]);

  return (
    <PruDialog
      open={shouDialog}
      onOk={onOk}
      onCancel={onCancel}
      dialogTitle={title}
      canncelBtnText={Translation('app.button.cancel').toUpperCase()}
      hideCancelBtn={Boolean(prospectDetail.activeLeads)}
      confirmBtnText={
        deleting ? <CircularProgress size={18} color="inherit" /> : Translation('app.button.confirm').toUpperCase()
      }
    >
      <span>{description}</span>
    </PruDialog>
  );
});

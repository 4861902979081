import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    margin: theme.spacing(1),
    borderRadius: 5,
  },
  error: {
    backgroundColor: 'red',
  },
  newTag: {
    fontSize: 10,
    fontWeight: 500,
    color: 'white',
    backgroundColor: '#E8192C',
    borderRadius: 29,
    padding: '2px 4px 2px 4px',
  },
}));

import React, { FC, ReactNode, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './action-item.style';
import { useActionItem } from './action-item.hook';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { ActionNodeTypeProps } from 'src/app/common/components/ProTable';

type ComponentProps = { onClick: (rows: any) => void };

export const ActionItem: React.FC<ComponentProps> = memo(({ onClick }: ComponentProps) => {
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  return (
    <Button onClick={onClick} variant="text">
      <RemoveCircleOutlineIcon className={styles.icon} />
      <Typography className={styles.iconButtonText}>{Translation('app.button.remove')}</Typography>
    </Button>
  );
});

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './mass-campaign-lead-list.style';
import { useMassCampaignLeadList } from './mass-campaign-lead-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { data } from 'jquery';
import { CampaignLeadItem, MassCampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionItems } from './action-items/action-items.component';

type ComponentProps = ParamsProps & {
  data: MassCampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
};

export const MassCampaignLeadListComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  // custom-hook
  const { columns, getData, rowSelection, params } = useMassCampaignLeadList({
    campaginDetail: props.data,
    cacheParams: props.cacheParams,
  });
  return (
    <>
      <ProFilterTable
        filterConfigs={[]}
        filterState={params}
        showFilter={false}
        rowKey="_id"
        rowSelection={rowSelection}
        columns={columns}
        request={getData}
        enableRefresh={false}
        ActionNode={ActionItems}
        showSelect={true}
        // onFilterChange={onFilterChange}
        // defaultSelectedRows={props.defaultSelectedRows}
      />
    </>
  );
});

import { FC, useRef } from 'react';
import { RouteComponentProps, Switch, Redirect } from 'react-router-dom';
import PruRoute from 'src/app/common/components/PruRoute';
import { NOT_FOUND_ERROR_PAGE_PATH } from 'src/app/common/constants';
import { GAData } from 'src/app/common/ga/redux/gaSlice';
import { CAMPAIGN_BASE_PATH, MASS_CAMPAIGN_DETAIL_PATH, MASS_CAMPAIGN_LIST_PATH } from '../../constants';
import { DetailPagePage } from './Detail/detail-page.screen';
import { ListPage } from './List/list.screen';

// TODO: update GA data
const createCampaignPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'add_new_agency_campaign',
  stage: 'create_campaign',
  screen_name: 'CreateCampaignPage',
};

const viewCampaignGPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'view_campaign_detail',
  stage: 'view',
  screen_name: 'CampaignDetailPage',
};

const campaignListPageGAData: GAData = {
  module: 'PRULeads',
  feature: 'ACM',
  journey: 'view_all_campaigns',
  stage: 'view',
  screen_name: 'CampaignListPage',
};
export const MASS_LIST_PATH = `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}`;
export const MASS_DETAIL_PATH = `${CAMPAIGN_BASE_PATH}${MASS_CAMPAIGN_LIST_PATH}${MASS_CAMPAIGN_DETAIL_PATH}`;

const MassCampaignRoutes: FC<RouteComponentProps> = (props) => {
  const cacheParams = useRef();

  return (
    <Switch>
      <PruRoute
        exact
        // gaData={createCampaignPageGAData}
        path={`${MASS_DETAIL_PATH}/:id`}
        render={(props) => <DetailPagePage {...props} />}
      />
      <PruRoute
        exact
        // gaData={campaignListPageGAData}
        path={MASS_LIST_PATH}
        render={(props) => <ListPage {...props} />}
      />
      <Redirect to={NOT_FOUND_ERROR_PAGE_PATH} />
    </Switch>
  );
};

export default MassCampaignRoutes;

import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    overflow: 'hidden',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
    position: 'absolute',
    backgroundColor: '#FFE9E0',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },
  icon: { color: '#EB3A34' },
  checkInput: {},
  checkbox: {
    marginLeft: '7px',
  },
  selectText: { flexGrow: 1, color: '#E8192C', fontSize: '14px', fontWeight: 700 },
}));

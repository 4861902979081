import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-lead-filter-table.style';
import { useCampaignLeadFilterTable } from './campaign-lead-filter-table.hook';
import ProFilterTable, { ProCoreActionType } from 'src/app/common/components/ProTable';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { AddApoointmentDialogComponent } from 'src/app/modules/Prospect/pages/appointment/add-apoointment-dialog/add-apoointment-dialog.component';
import { FormMode } from 'src/app/common/utils';
import { AddLeadSettingItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { OperatorEnum } from 'src/app/modules/AgencyCampaign/types/common-types';

export type LeadFilterProps = FilterProps<any> & {
  campaignId: string;
  campaignOwner: string;
  agentCode: string;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
  isACMApproved: boolean;
  isInSchedule: boolean;
};

type ComponentProps = ParamsProps & {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  filter?: React.ComponentType<LeadFilterProps>;
  campaignId: string;
  agentCode: string;
  campaignOwner: string;
  tableRef?: React.MutableRefObject<ProCoreActionType | undefined>;
  isACMApproved: boolean;
  isInSchedule: boolean;
  acmAddLeadSetting:
    | AddLeadSettingItem
    | {
        selfAddEnabled: true;
        needACMApproved: false;
        startDateSetting: {};
        endDateSetting: {
          operator: OperatorEnum.GTE;
          dependsOn: string;
          value: number;
        };
        startDate: string;
        endDate: string;
      };
};

export const CampaignLeadFilterTableComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  // custom-hook
  const {
    filter: InitFilter,
    campaignId,
    agentCode,
    cacheParams,
    campaignOwner,
    tableRef,
    acmAddLeadSetting,
    isACMApproved,
    isInSchedule,
  } = props;
  const {
    columns,
    selectedProspectId,
    showApptDialog,
    showUpdateDialog,
    setShowApptDialog,
    setShowUpdateDialog,
    getData,
    onFilterChange,
  } = useCampaignLeadFilterTable({
    campaignId,
    agentCodeLocal: agentCode,
    cacheParams,
  });

  const Filter = useMemo(() => {
    return (props: FilterProps<any>) =>
      InitFilter ? (
        <InitFilter
          {...props}
          campaignId={campaignId}
          agentCode={agentCode}
          cacheParams={cacheParams}
          campaignOwner={campaignOwner}
          acmAddLeadSetting={acmAddLeadSetting}
          isACMApproved={isACMApproved}
          isInSchedule={isInSchedule}
        />
      ) : null;
  }, [InitFilter, campaignId, agentCode, cacheParams, campaignOwner, acmAddLeadSetting, isACMApproved, isInSchedule]);
  return (
    <>
      <ProFilterTable
        rowKey="_id"
        columns={columns}
        request={getData}
        Filter={Filter}
        enableRefresh={false}
        filterState={cacheParams?.current}
        onFilterChange={onFilterChange}
        actionRef={tableRef}
      />
      <AddApoointmentDialogComponent
        open={showApptDialog}
        onClose={() => setShowApptDialog(false)}
        mode={FormMode.CREATE}
        prospect={selectedProspectId}
      />
      <Dialog open={showUpdateDialog} onClose={() => setShowUpdateDialog(false)}>
        <DialogTitle>{`Update Lead Status Placeholder`}</DialogTitle>
        <DialogContent>{`This is the placeholder for updating lead status, which is TBD in sprint2`}</DialogContent>
      </Dialog>
    </>
  );
});

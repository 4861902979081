import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  wrap: {
    display: 'flex',
    padding: '24px 0px',
    alignItems: 'center',
  },
  icon: {
    width: '24px',
    height: '24px',
    marginLeft: '16px',
  },
  marginLeft: {
    marginLeft: '24px',
  },
  flex_row: {
    display: 'flex',
    alignItems: 'center',
  },
}));

import React from 'react';
import { FilterComponentsEnum } from 'src/app/common/components/list-filter/filter-components.enum';
import { Box } from '@mui/material';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';
import { ListFilterConfig } from 'src/app/common/components/list-filter/list-filter.component';
import { formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { CampaignItem, CampaignTypeItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { AgencyCampaignTypeStatusEnum } from '../../../../constants';
import { CampaignStatusTagComponent } from '../../detail/tab-application/campaign-brief/campaign-status-tag/campaign-status-tag.component';
import { Unread } from '../components/unread/unread.component';

const campaignStatus: any[] = [
  { key: AgencyCampaignTypeStatusEnum.DRAFT },
  { key: AgencyCampaignTypeStatusEnum.PENDING },
  { key: AgencyCampaignTypeStatusEnum.APPROVED },
  { key: AgencyCampaignTypeStatusEnum.REJECTED },
];

export const getFilterConfig: (
  t: (val: string) => string,
  campaignType?: CampaignTypeItem[],
) => Array<ListFilterConfig> = (t, campaignType) => [
  {
    key: 'campaignType',
    title: t('agencyCampaign.common.campaignType'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items: campaignType?.map(({ _id: itemKey, name: itemLabel }) => ({ itemKey, itemLabel })) ?? [],
  },
  {
    key: 'status',
    title: t('agencyCampaign.list.screen.filter.status'),
    type: FilterComponentsEnum.CHECK_BOX_GROUP,
    items:
      campaignStatus?.map(({ key: itemKey }) => ({
        itemKey,
        itemLabel: t(`agencyCampaign.common.status.${itemKey}`),
      })) ?? [],
  },
];

export const renderUnread = (currValue: string) => {
  return !currValue ? <Unread /> : null;
};

export const renderHighlight = (currValue: string, keyword?: string) => {
  return <HighlightMatchedText highLightText={keyword ?? ''}>{currValue}</HighlightMatchedText>;
};

export const renderDateTime = (currValue: string) => formatDate(currValue);

export const renderCampaignType = (currValue: any, row: CampaignItem) => {
  return row.agencyCampaign?.typeId?.name;
};

export const renderRsvpStatus = (currValue: any, row: CampaignItem) => {
  return row.agencyCampaign?.rsvpStatus || '';
};

export const renderStatus = (currValue: string) => {
  return (
    <Box display="inline-block">
      <CampaignStatusTagComponent campaignStatus={currValue} />
    </Box>
  );
};

export const getColumns = ({
  t,
  keyword,
}: {
  t: (val: string) => string;
  keyword?: string;
  styles: Record<string, any>;
}): ProColumns<CampaignItem>[] => [
  {
    title: '',
    dataIndex: 'lastViewedTime',
    width: '78px',
    align: 'center',
    render: renderUnread,
  },
  {
    title: t('agencyCampaign.common.campaignId'),
    dataIndex: 'campaignId',
    width: '176px',
    sorter: true,
    render: (currValue: string) => renderHighlight(currValue, keyword),
  },
  {
    title: t('agencyCampaign.common.campaignName'),
    dataIndex: 'name',
    sorter: true,
    width: '290px',
    render: (currValue: string) => renderHighlight(currValue, keyword),
  },
  {
    title: t('agencyCampaign.common.startDate'),
    sorter: true,
    dataIndex: 'startDate',
    width: '176px',
    render: renderDateTime,
  },
  {
    title: t('agencyCampaign.common.endDate'),
    sorter: true,
    dataIndex: 'endDate',
    width: '176px',
    render: renderDateTime,
  },
  {
    title: t('agencyCampaign.common.campaignType'),
    dataIndex: 'campaignType',
    width: '176px',
    render: renderCampaignType,
  },
  {
    title: t('agencyCampaign.common.status'),
    dataIndex: 'status',
    width: '176px',
    render: renderStatus,
  },
  {
    title: t('agencyCampaign.common.rsvp'),
    dataIndex: 'rsvp',
    width: '176px',
    render: renderRsvpStatus,
  },
  {
    title: t('agencyCampaign.common.lastUpdatedTime'),
    dataIndex: 'updatedAt',
    width: '176px',
    sorter: true,
    render: renderDateTime,
  },
  {
    title: t('agencyCampaign.list.column.lastView'),
    sorter: true,
    dataIndex: 'lastViewedTime',
    width: '176px',
    render: renderDateTime,
  },
];

import { RequestData } from 'src/app/common/components/ProTable';
import { fetchCampaignList, fetchCampaignTypeList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { CampaignItem } from '../../../../types/campaign-types';

export const getCampaignTypeList = async (dispatch: React.Dispatch<any>) => {
  const res = await fetchCampaignTypeList(dispatch);
  return res.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export const getCampaignList = async (
  dispatch: React.Dispatch<any>,
  params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
  sort?: { [key: string]: any },
  filter?: { [key: string]: any },
): Promise<RequestData<CampaignItem>> => {
  const { page, pageSize: limit, keyword: name = '' } = params;
  const { status, campaignType } = filter ?? {};

  const res = await fetchCampaignList(
    {
      page: page + 1,
      limit,
      name,
      status,
      campaignType,
    },
    sort
      ? Object.entries(sort).map(([key, value]) => ({
          key,
          value,
        }))
      : [],
    dispatch,
  );

  if (res) {
    const { docs, totalDocs: total } = res;

    return {
      success: true,
      data: docs ?? [],
      total,
    };
  }

  return {
    success: false,
    data: [],
    total: 0,
  };
};

import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, IconButton, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles } from './list-filter.style';
import { FilterComponentsEnum } from './filter-components.enum';
import { useListFilter } from './list-filter.hook';
import {
  ListFilterDateRadioGroupComponent,
  DateRaidoGroupType,
} from '../list-filter-date-radio-group/list-filter-date-radio-group.component';
import { ListFilterCheckboxGroupComponent } from '../list-filter-checkbox-group/list-filter-checkbox-group.component';
import { isEmptyArray } from 'formik';
import { isEqual } from 'lodash';

type ApiKey = string;
type ApiItemKey = string;

export type ListFilterConfig = {
  key: ApiKey; // the identifier of filter field
  title: string;
  type: FilterComponentsEnum; // type of this filter field
  items: Array<{
    itemKey: ApiItemKey; // the identifier of the option in this field
    itemLabel: string; // value of the option
    extraCompo?: FilterComponentsEnum; // extra component type
  }>;
};

export type ListFilterData = {
  [key: ApiKey]: Array<ApiItemKey> | DateRaidoGroupType;
};

export interface ListFilterProps {
  open: boolean;
  filterValues?: ListFilterData;
  filterConfigs: Array<ListFilterConfig>;
  onClose: () => void;
  onConfirm?: (value: ListFilterData) => void;
  onCancel?: () => void;
}

export const ListFilterComponent: React.FC<ListFilterProps> = memo(
  ({ open, filterConfigs, filterValues, onClose, onConfirm }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { filter, setFilter, resetFilter } = useListFilter({ filterValues });

    const renderFilter = useCallback(
      (config: ListFilterConfig) => {
        switch (config.type) {
          case FilterComponentsEnum.RADIO_GROUP:
            return (
              <ListFilterDateRadioGroupComponent
                input={filter[config.key] as DateRaidoGroupType}
                fieldTitle={config.title}
                onChange={(val: DateRaidoGroupType) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: val,
                  }));
                }}
              />
            );
          case FilterComponentsEnum.CHECK_BOX_GROUP:
            return (
              <ListFilterCheckboxGroupComponent
                filterConfig={config}
                value={filter[config.key] as Array<ApiItemKey>}
                onChange={(val) => {
                  setFilter((oldValue) => ({
                    ...oldValue,
                    [config.key]: val,
                  }));
                }}
              />
            );
        }
      },
      [filter, setFilter],
    );

    const filterForm = useCallback(() => {
      if (filterValues) {
        return (
          <div className={styles.fullWidth}>
            {filterConfigs.map((config) => {
              return <React.Fragment key={config.key}>{renderFilter(config)}</React.Fragment>;
            })}
          </div>
        );
      } else {
        return null;
      }
    }, [filterValues, styles.fullWidth, filterConfigs, renderFilter]);

    return (
      <>
        <Dialog
          open={open}
          onClose={() => {
            resetFilter();
            onClose();
          }}
          classes={{ paper: styles.dialogContainer, root: styles.dialogRoot }}
        >
          <div className={styles.dialogHeader}>
            <DialogTitle>{Translation('agencyCampaign.dialog.filter')}</DialogTitle>
            <IconButton
              onClick={() => {
                resetFilter();
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent dividers classes={{ root: styles.dialogContentContainer }}>
            {filterForm()}
          </DialogContent>
          <div className={styles.dialogFooter}>
            <div className={styles.dialogButtonContainer}>
              <Button classes={{ root: styles.dialogButton }} variant="outlined" onClick={resetFilter}>
                {Translation('app.button.reset')}
              </Button>
              <Button
                classes={{ root: styles.dialogButton }}
                disabled={isEqual(filter, filterValues)}
                variant="contained"
                color="secondary"
                onClick={() => {
                  //remove empty item
                  const filteredList: ListFilterData = {};
                  Object.entries(filter).forEach((item) => {
                    if (!isEmptyArray(item[1])) {
                      filteredList[item[0]] = item[1];
                    }
                  });
                  onConfirm?.(filteredList);
                }}
              >
                {Translation('app.button.confirm')}
              </Button>
            </div>
          </div>
        </Dialog>
      </>
    );
  },
);

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { RequestData } from 'src/app/common/components/ProTable';
import { CampaignLead } from 'src/app/modules/AgencyCampaign/types/campaign-types';
import { getLeadList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';
import { getActionColumns, getNoActionColumns, i18nKeyMapping } from './util/campaign-lead-filter-table.util';
import { useStyles } from './campaign-lead-filter-table.style';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';

interface HookProps {
  campaignId: string;
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  agentCodeLocal: string;
}

export const useCampaignLeadFilterTable = ({ campaignId, cacheParams, agentCodeLocal }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [isSelf, setIsSelf] = useState(true);
  const [selectedProspectId, setSelectedProspectId] = useState('');
  const [showApptDialog, setShowApptDialog] = useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  // redux
  const dispatch = useDispatch();
  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        leadState?: string | undefined;
        agentCodes?: string[] | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<CampaignLead>> => {
      const { page, pageSize, leadState, agentCodes } = params;
      let sortBy = 'updatedAt';
      let sortOrder = -1;
      const searchAgentCode = agentCodes ? agentCodes : [agentCodeLocal];
      const leadQuery = i18nKeyMapping[leadState as keyof typeof i18nKeyMapping]?.query;
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];
        sortBy = key;
        if (val === 'desc') {
          sortOrder = -1;
        } else {
          sortOrder = 1;
        }
      }

      const res = await getLeadList(
        {
          campaignId,
          agentCode: searchAgentCode,
          page: page + 1,
          pageSize,
          leadQuery,
          sortBy,
          sortOrder,
        },
        dispatch,
      );

      if (res) {
        const { docs, totalDocs, ...rest } = res;
        return {
          success: true,
          data: docs ?? [],
          total: totalDocs,
          ...rest,
        };
      }

      return { success: false, data: [], total: 0 };
    },
    [agentCodeLocal, campaignId, dispatch],
  );
  const columns = useMemo(() => {
    if (isSelf) {
      return getActionColumns({
        t: Translation,
        styles,
        onAppt: (prospectId: string) => {
          setSelectedProspectId(prospectId);
          setShowApptDialog(true);
        },
        onUpdate: (prospectId: string) => {
          setSelectedProspectId(prospectId);
          setShowUpdateDialog(true);
        },
      });
    } else {
      return getNoActionColumns({ t: Translation, styles });
    }
  }, [Translation, isSelf, styles]);

  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      cacheParams && (cacheParams.current = filterState);
      if (
        !cacheParams?.current?.filterState.downline ||
        cacheParams?.current?.filterState.downline[1].value === agentCodeLocal
      ) {
        setIsSelf(true);
      } else {
        setIsSelf(false);
      }
    },
    [agentCodeLocal, cacheParams],
  );

  return {
    columns,
    selectedProspectId,
    showApptDialog,
    showUpdateDialog,
    setShowApptDialog,
    setShowUpdateDialog,
    getData,
    onFilterChange,
  };
};

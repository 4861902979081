import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { Tag } from 'src/app/common/components/tag/tag.component';

type ComponentProps = ParamsProps & {
  campaignStatus: string;
};

const tagConfig: { [key: string]: { color: string; bgColor: string } } = {
  draft: {
    color: '#333333',
    bgColor: '#F5F5F5',
  },
  rejected: {
    color: '#E8192C',
    bgColor: '#FFE4E6',
  },
  pending: {
    color: '#FF872E',
    bgColor: '#FFEFE3',
  },
  pendingApproval: {
    color: '#FF872E',
    bgColor: '#FFEFE3',
  },
  active: {
    color: '#24C791',
    bgColor: '#DFF7EF',
  },
  approved: {
    color: '#24C791',
    bgColor: '#DFF7EF',
  },
};

export const CampaignStatusTagComponent: React.FC<ComponentProps> = memo(({ campaignStatus }: ComponentProps) => {
  // i18n
  const intl = useIntl();
  const Translation = useCallback((id: string) => intl.formatMessage({ id }), [intl]);

  const configs = useMemo(() => {
    return Object.fromEntries(
      Object.entries(tagConfig).map(([key, value]) => [
        key,
        { ...value, text: Translation(`agencyCampaign.common.status.${key}`) },
      ]),
    );
  }, [Translation]);

  // custom-hook
  return <Tag<string> value={campaignStatus} configs={configs} />;
});

export interface I18nData {
  [key: string]: string | any;
}
/**
 * get i18nData by language, return en data if not found
 * @param i18nData sample: { "zh-Hant": "chinese 2", en: "en-item2", 'zh-cn': { name: 'tom'} } | "item 1",
 * @param lang  target language key
 * @param arrEnglishKey optional: english lang key, default: ['en', 'enUs', 'default']
 * @returns
 */
export function getI18nData(
  i18nData: I18nData | string | undefined,
  lang: string,
  arrEnglishKey: string[] = ['en', 'enUs', 'default'],
) {
  if (!i18nData) {
    return '';
  }
  if (typeof i18nData === 'string') {
    return i18nData;
  }
  const data = i18nData[lang];
  if (data) {
    return data;
  }
  // data in English
  let enData;
  for (let i = 0; i < arrEnglishKey.length; i++) {
    const defaultLang = arrEnglishKey[i];
    enData = i18nData[defaultLang];
    if (enData) {
      break;
    }
  }
  if (arrEnglishKey.includes(lang)) {
    return enData;
  }
  // data in non-English
  const arrKey = Object.keys(i18nData); // ['zh-Hant', 'zh-cn', 'default', 'en']
  const nonEnglishKey = arrKey.find((key) => !arrEnglishKey.includes(key));
  return nonEnglishKey ? i18nData[nonEnglishKey] : enData;
}

import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Box, Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { LayoutSplashScreen } from 'src/app/layout';
import { useStyles } from './detail-page.style';
import { useDetailPage } from './detail-page.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import { CampaignDetailViewComponent } from '../../../components/campaign-detail-view/campaign-detail-view.component';
import { MarketingCampaignLeadListComponent } from './marketing-campaign-lead-list/campaign-lead-list.component';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
    cacheParams: React.MutableRefObject<Record<string, any> | undefined>;
  };

export const DetailPagePage: React.FC<PageProps> = (props: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { id } = useParams<any>();

  // style
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { loading, data, leadsData, run, navToList } = useDetailPage({ id: id });
  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('campaign.detail_title')}
        rightNode={
          // todo: button style
          <Button variant="outlined" color="inherit" onClick={navToList}>
            {Translation('app.button.back')}
          </Button>
        }
      />
      {loading ? (
        <LayoutSplashScreen />
      ) : (
        <div>
          <CampaignDetailViewComponent campaignDetail={data} />
          <div className={styles.listContainer}>
            <MarketingCampaignLeadListComponent data={leadsData} cacheParams={props.cacheParams} campaignId={id} />
          </div>
        </div>
      )}
    </Page>
  );
};

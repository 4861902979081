import React, { memo } from 'react';
import { Box } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useLeadTypesTag } from './lead-types-tag.hook';
import { Tag } from 'src/app/common/components/tag/tag.component';

type ComponentProps = ParamsProps & {
  currValue: string[];
};

export const LeadTypesTagComponent: React.FC<ComponentProps> = memo(({ currValue }: ComponentProps) => {
  // custom-hook
  const { configs } = useLeadTypesTag();
  return (
    <Box display="inline-block">
      {currValue
        ? currValue.map((v, index) => (
            <Box key={index} marginTop={index > 0 ? '4px' : '0px'}>
              <Tag<string> value={v} configs={configs} />
            </Box>
          ))
        : ''}
    </Box>
  );
});

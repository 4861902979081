import { TableCell } from '@mui/material';
import { withStyles } from 'tss-react/mui';

export const PruTableHeader = withStyles(TableCell, (theme) => ({
  head: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    // backgroundColor: theme.palette.common.white,
    backgroundColor: '#F5F5F5',
    borderBottom: '1px solid #F0F0F0;',
    // color: '#858585', // todo
    color: '#666',
    fontFamily: 'Poppins, Helvetica, "sans-serif"',
  },
}));

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';

interface HookProps {
  id: number;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useSelectOption = ({ id }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });


  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  const [sortKey, setSortKey] = useState<{ key: string; value?: string }[]>([]);

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, run, runAsync } = useRequest(
    () => fetchCurrentAgentInfo(locale, dispatch),
    { manual: true },
  );

  useEffect(() => {
    // manual call api request
    if (sortKey && sortKey.length > 0) {
      run();
    }
  }, [sortKey]);

  const handlePress = useCallback(() => {
   setSortKey([{ key: 'updatedAt', value: 'desc' }]);
  }, []);


  return {
    loading,
    data,
    run,
    handlePress,
  };
};
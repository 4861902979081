import { Dispatch } from 'react';
import { AGENT_URL, MESSAGE_URL, apiClient, apiErrorHandler } from 'src/app/common/network';
import { Appointment, EventData } from '../types/appointment-types';

export const fetchProspectAppointments = async (prospectId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${MESSAGE_URL}/calendars/me/events/type/lead`;
  const params = {
    prospectId,
  };
  return apiClient
    .get<any>(queryPath, { params: params })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const postProspectAppointment = async (body: Appointment, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${MESSAGE_URL}/calendars/me/events`;
  return apiClient
    .post<any>(queryPath, {
      type: 'lead',
      agent_code: body.agentCode,
      date: body.startDate,
      content: body,
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const patchProspectAppointment = async (eventId: string, body: any, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${AGENT_URL}/agents/calendars/me/events/${eventId}`;

  return apiClient
    .patch<any>(queryPath, body)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const deleteProspectAppointment = async (eventId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${MESSAGE_URL}/calendars/me/events/${eventId}`;
  return apiClient
    .delete<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

export const getProspectAppointment = async (eventId: string, dispatch?: Dispatch<any>): Promise<any> => {
  const queryPath = `${MESSAGE_URL}/calendars/me/events/${eventId}`;
  return apiClient
    .get<any>(queryPath)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw apiErrorHandler(err, dispatch);
    });
};

import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './campaign-lead-list.style';
import { useCampaignLeadList } from './campaign-lead-list.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { ActionItems } from './action-items/action-items.component';
import { useSelector } from 'react-redux';
import { TokenInfo } from 'src/app/modules/Auth/types/auth-types';
import { RootState } from 'src/redux/store';
import { SelectDownline } from 'src/app/common/components/select-downline/select-downline.component';
import { CategorySelectorComponent } from 'src/app/common/components/category-selector/category-selector.component';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { GlobalHelper } from 'src/app/common/helpers/GlobalHelpers';
import { Option } from 'src/app/common/components/cascading-selector/cascading-selector.component';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  data: CampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
};

const TabLeadFilterComponent: React.FC<any> = memo(
  ({ cacheParams, campaignId, agentCode, filterState, setFilterState, onExtraFilterChange, onSave }: any) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const [agentCodes, setAgentCodes] = React.useState<string[]>([agentCode]);

    useEffect(() => {
      if (filterState.agentCodes) {
        setAgentCodes(filterState.agentCodes);
      }
    }, []);

    // custom-hook
    // const { filterState, setFilterState } = useTabLeadFilter({ campaignId });

    return (
      <div className={styles.filterContainer}>
        <div className={styles.selectorConatiner}>
          <SelectDownline
            campaignId={campaignId}
            currentOption={
              cacheParams?.current?.filterState.downline ?? [
                { label: '', value: '' },
                { label: '', value: GlobalHelper.getAgentCode() },
              ]
            }
            onChange={(option: Array<Option>, codeArr: Array<string>) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.downline = option;
              updatedLeadState.agentCodes = codeArr;
              setAgentCodes(codeArr);
              onExtraFilterChange?.({ agentCode: codeArr });
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
          />
        </div>

        <div className={styles.selectorConatiner}>
          <CategorySelectorComponent
            campaignId={campaignId}
            agentCodes={agentCodes}
            onChange={(e) => {
              onExtraFilterChange?.({ leadState: e });
            }}
            value={cacheParams?.current?.filter?.leadState || 'total'}
          />
        </div>
      </div>
    );
  },
);

export const MarketingCampaignLeadListComponent: React.FC<ComponentProps> = memo(
  ({ data, cacheParams, campaignId }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    // custom-hook
    const { columns, getData, rowSelection, params, onFilterChange } = useCampaignLeadList({
      campaginLeads: data,
      cacheParams,
      campaignId,
    });

    const user: any = useSelector<any>((state) => state.auth.user);
    const Filter = useMemo(() => {
      return (props: FilterProps<any>) => (
        <TabLeadFilterComponent
          {...props}
          campaignId={campaignId}
          agentCode={user?.agentCode ?? ''}
          cacheParams={cacheParams}
        />
      );
    }, [cacheParams, user.agentCode, campaignId]);

    return (
      <>
        <ProFilterTable
          Filter={Filter}
          filterState={cacheParams?.current}
          rowKey="_id"
          rowSelection={rowSelection}
          columns={columns}
          request={getData}
          enableRefresh={false}
          ActionNode={ActionItems}
          showSelect={true}
          onFilterChange={onFilterChange}
        />
      </>
    );
  },
);

import React, { useState } from 'react';
import { PruTableRow } from '../Table/PruTableRow';
import { Checkbox, IconButton, Radio } from '@mui/material';
import { CheckType, ProColumns, RowSelection } from '.';
import { TableCell } from '@mui/material';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import StickyTableCell from './StickyTableCell';

interface ProTableRowItemProps<T> {
  columns: ProColumns<T>[];
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  open: boolean;
  setOpen: (params: boolean) => void;
  level?: number;
  rowKey: string;
  className?: string;
  checkType?: CheckType;
  rowClickable?: boolean;
}

interface ProTableRowProps<T> {
  columns: ProColumns<T>[];
  rowSelection?: RowSelection<T>;
  row: T;
  rowIndex: number;
  selectedRow: T[];
  handleSelectSingleRow: (event: any, row: T) => void;
  level?: number;
  rowKey: string;
  className?: string;
  checkType?: CheckType;
  rowClickable?: boolean;
}

export const CHECK_CELL_WIDTH = 64;

const ProTableRowItem = <T extends { [key: string]: any } = {}>({
  columns,
  rowSelection,
  row,
  selectedRow,
  rowIndex,
  handleSelectSingleRow,
  open,
  setOpen,
  level = 0,
  rowKey,
  className,
  checkType,
  rowClickable,
}: ProTableRowItemProps<T>) => {
  const { getCheckboxProps } = rowSelection || { getCheckboxProps: () => ({ disabled: false }) };
  const renderTableCellItem = <T extends { [key: string]: any } = {}>(
    row: T,
    col: ProColumns<T>,
    rowIndex: number,
    colIndex: number,
    level: number,
  ) => {
    const { render, dataIndex, valueType } = col;
    let result: React.ReactNode;
    if (valueType === 'index') {
      result = rowIndex + 1;
    } else if (render) {
      result = render(dataIndex && row[dataIndex], row);
    } else {
      result = dataIndex ? row[dataIndex] : '';
    }
    if (colIndex === 0 && row.children) {
      result = (
        <span style={{ marginLeft: 18 * (level - 1) }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {!open ? <AddBoxOutlinedIcon /> : <IndeterminateCheckBoxOutlinedIcon />}
          </IconButton>
          {result}
        </span>
      );
    } else if (colIndex === 0) {
      result = <span style={{ marginLeft: 18 * level }}>{result}</span>;
    }
    return result;
  };

  const labelId = `enhanced-table-checkbox-${rowIndex}`;

  return (
    <PruTableRow
      className={className}
      onClick={(event) => handleSelectSingleRow(event, row)}
      selected={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
      style={rowClickable ? { cursor: 'pointer' } : undefined}
    >
      {rowSelection ? (
        <TableCell align="right" width={`${CHECK_CELL_WIDTH}px`}>
          {CheckType.radio === checkType ? (
            <Radio
              disabled={getCheckboxProps(row, selectedRow).disabled}
              checked={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
              inputProps={{ 'aria-labelledby': labelId }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, padding: 0 }}
            />
          ) : (
            <Checkbox
              disabled={getCheckboxProps(row, selectedRow).disabled}
              checked={selectedRow.findIndex((r) => r[rowKey] === row[rowKey]) > -1}
              inputProps={{ 'aria-labelledby': labelId }}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 24 }, padding: 0 }}
            />
          )}
        </TableCell>
      ) : null}
      {columns.map((col, colIndex) => {
        const width = colIndex === columns.length - 1 ? undefined : col.width;
        return col.sticky ? (
          <StickyTableCell
            component="td"
            scope="row"
            key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
            align={col.align}
            // width={col.width ?? '300px'}
            className={`sticky-cell`}
            sx={{
              minWidth: col.width,
              width,
              maxWidth: width,
            }}
          >
            {renderTableCellItem(row, col, rowIndex, colIndex, level)}
          </StickyTableCell>
        ) : (
          <TableCell
            component="td"
            scope="row"
            key={`cell-level${level}-${col.dataIndex}-${rowIndex}-${colIndex}`}
            align={col.align}
            sx={{
              minWidth: col.width,
              width,
              maxWidth: width,
            }}
          >
            {renderTableCellItem(row, col, rowIndex, colIndex, level)}
          </TableCell>
        );
      })}
    </PruTableRow>
  );
};

const ProTableRow = <T extends { [key: string]: any } = {}>({
  columns,
  rowSelection,
  row,
  selectedRow,
  rowIndex,
  handleSelectSingleRow,
  level = 0,
  rowKey,
  className,
  checkType,
  rowClickable,
}: ProTableRowProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <ProTableRowItem
        rowKey={rowKey}
        level={level}
        columns={columns}
        rowSelection={rowSelection}
        row={row}
        selectedRow={selectedRow}
        rowIndex={rowIndex}
        handleSelectSingleRow={handleSelectSingleRow}
        open={open}
        setOpen={setOpen}
        className={className}
        checkType={checkType}
        rowClickable={rowClickable}
      />
      {open
        ? ((row && row.children) || []).map((rowChild: T, i: number) => (
            <ProTableRow
              rowKey={rowKey}
              level={level + 1}
              key={`childrow-level${level}-${i}`}
              columns={columns}
              rowSelection={rowSelection}
              row={rowChild}
              selectedRow={selectedRow}
              rowIndex={rowIndex}
              handleSelectSingleRow={handleSelectSingleRow}
              className={className}
              checkType={checkType}
              rowClickable={rowClickable}
            />
          ))
        : null}
    </>
  );
};

export default ProTableRow;

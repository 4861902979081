import React, { FC, useState, useMemo } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './add-prospect.style';
import { AddNewProspectPage } from './components/add-new-prospect/add-new-prospect.screen';
import { SOURCE_TYPE } from '../../constants';
import { ImportExistingCustomersPage } from './components/import-existing-customers/import-existing-customers.screen';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const AddProspectPage: React.FC<PageProps> = (props: PageProps) => {
  const { type } = useParams<{ type: string }>();
  const { location } = props;
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  return (
    <div className={styles.wrap}>
      {type && type === SOURCE_TYPE.NEW_PROSPECT && <AddNewProspectPage {...props} />}
      {type && type === SOURCE_TYPE.EXISTING_CUSTOMER && <ImportExistingCustomersPage {...props} />}
    </div>
  );
};

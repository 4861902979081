import React, { useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import { Button } from 'src/app/common/components/button/button.component';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './auto-purge-list.style';
import { useAutoPurgeList } from './auto-purge-list.hook';
import { Page, PageTitle } from 'src/app/common/components/page/page.component';
import HeaderBreadCrumbs from 'src/app/common/components/layout/components/container/components/header/header-breadcrumbs';
import ProFilterTable from 'src/app/common/components/ProTable';
import { getAutoDeleteProspectsConfig } from '../../util/purge-prospect.util';
import { Prospect } from '../../types/prospect-types';
import { PROSPECT_BASE_PATH, PROSPECT_DETAIL_PATH } from '../../constants';

type PageProps = ParamsProps &
  RouteComponentProps & {
    /* otherProp: string */
  };

export const AutoPurgeListPage: React.FC<PageProps> = ({ history }: PageProps) => {
  const intl = useIntl();
  const Translation = (id: string, value?: any) => intl.formatMessage({ id }, value);
  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  // custom-hook
  const { columns, getData } = useAutoPurgeList();

  const { purgeDays } = getAutoDeleteProspectsConfig();

  return (
    <Page>
      <HeaderBreadCrumbs />
      <PageTitle
        title={Translation('pruleads.prospect.purge.title')}
        rightNode={
          // todo: button color
          <Button variant="contained" color="primary" onClick={() => history.goBack()}>
            {Translation('app.button.back')}
          </Button>
        }
      />
      <Typography className={styles.content}>
        {Translation('pruleads.prospect.purge.reminder.content', { num: purgeDays })}
      </Typography>
      <ProFilterTable
        showSelect={false}
        showFilter={false}
        rowKey="_id"
        columns={columns}
        request={getData}
        enableRefresh={false}
        onClickRow={(record: Prospect) => {
          history.push(`${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}/${record._id}`, {
            prospectBasicInfo: record,
          });
        }}
      />
    </Page>
  );
};

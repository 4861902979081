export const isAlphabet = (stringToCheck: string): boolean => {
  return Boolean(stringToCheck.match(/^([a-z]|[A-Z])+$/g));
};

export function reconstuctNameByLastName(firstName?: string, lastName?: string) {
  if (!lastName || isAlphabet(lastName)) {
    return `${firstName || ''} ${lastName || ''}`;
  }
  return `${lastName || ''} ${firstName || ''}`;
}

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FormLabel, Grid } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list-filter-date-radio-group.style';
import { ListFilterRadioGroupComponent } from '../list-filter-radio-group/list-filter-radio-group.component';
import { dateType, getConfigs, getDateConfigsByType } from './util/list-filter-date-radio.util';
import { SimpleDateRangePickerComponent } from '../simple-date-range-picker/simple-date-range-picker.component';

export type DateRaidoGroupType = {
  key: string;
  value: { label: string; startDate: string; endDate: string };
};

type ComponentProps = ParamsProps & {
  input?: DateRaidoGroupType;
  rangeType?: dateType;
  fieldTitle: string;
  onChange?: (value: DateRaidoGroupType) => void;
};

export const ListFilterDateRadioGroupComponent: React.FC<ComponentProps> = memo(
  ({ input, rangeType, fieldTitle, onChange }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id }); // todo: i18n

    // config
    const itemConfigs = !rangeType ? getConfigs() : getDateConfigsByType(rangeType);

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    const fieldValue = input || { key: '', value: { label: '', startDate: '', endDate: '' } };

    const configs: Array<{ itemKey: string; itemLabel: string }> = itemConfigs.map(
      ({ key: itemKey, title: itemLabel }) => {
        return { itemKey, itemLabel };
      },
    );

    return (
      <div className={styles.mt_24}>
        <FormLabel classes={{ root: styles.formLabel }}>{fieldTitle || ''}</FormLabel>
        <ListFilterRadioGroupComponent
          value={fieldValue.key}
          itemsConfig={configs}
          onChange={(selectedKey: string) => {
            const itemDateConfig = itemConfigs.find((item) => item.key === selectedKey)?.value() || {
              startDate: '',
              endDate: '',
            };
            onChange?.({
              ...fieldValue,
              key: selectedKey,
              value: {
                ...itemDateConfig,
                label: itemConfigs.find((item) => item.key === selectedKey)?.title || '',
              },
            });
          }}
        />
        <Grid container spacing={1} classes={{ root: `${styles.fullWidth} ${styles.mt_8} ${styles.ml_8}` }}>
          {fieldValue.key === dateType.customize ? (
            <SimpleDateRangePickerComponent
              value={fieldValue.value}
              onChange={(dateValue) => {
                onChange?.({
                  ...fieldValue,
                  value: {
                    ...fieldValue.value,
                    ...dateValue,
                  },
                });
              }}
            />
          ) : null}
        </Grid>
      </div>
    );
  },
);

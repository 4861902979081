import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

interface HookProps {
  approvalHistoryList: any;
  remarkList: any;
  submitData?: { submitDate: Date; submitBy: string; cancelDate: Date; canceledBy: string; cancelReason?: string };
}

export const useTabApproval = ({ approvalHistoryList, remarkList, submitData }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  // redux
  const dispatch = useDispatch();
  const [uploadApprovalProgress, setUploadApprovalProgress] = useState<number>();
  const [approvalHistory, setApprovalHistory] = useState<any>();
  const [remarks, setRemarks] = useState<any>();

  useEffect(() => {
    setApprovalHistory(approvalHistoryList);
    // eslint-disable-next-line
  }, [approvalHistoryList]);

  useEffect(() => {
    setRemarks(remarkList);
    // eslint-disable-next-line
  }, [remarkList]);

  const isCanceled = !!submitData?.canceledBy;

  return {
    isCanceled,
    approvalHistory,
    uploadApprovalProgress,
    remarks,
    setApprovalHistory,
    setUploadApprovalProgress,
    setRemarks,
  };
};

import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './add-apoointment-dialog.style';
import { useAddApoointmentDialog } from './add-apoointment-dialog.hook';
import { EventData } from '../../../types/appointment-types';
import { FormMode } from 'src/app/common/utils';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  open: boolean;
  onClose: () => void;
  data?: EventData;
  mode: FormMode;
  prospect: any;
  actionRef?: any;
};

export const AddApoointmentDialogComponent: React.FC<ComponentProps> = memo(
  ({ open, onClose, data, mode, prospect, actionRef }) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });
    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const { onConfirm, formData, setFormData, errors } = useAddApoointmentDialog({
      onClose,
      data,
      mode,
      prospect,
      actionRef,
    });

    const purposeLabels = [
      'prospect.appoinment.detail.purpose.select1',
      'prospect.appoinment.detail.purpose.select2',
      'prospect.appoinment.detail.purpose.select3',
      'prospect.appoinment.detail.purpose.select5',
    ];
    const outcomeLables = [
      'prospect.appoinment.detail.outcome.select1',
      'prospect.appoinment.detail.outcome.select2',
      'prospect.appoinment.detail.outcome.select3',
      'prospect.appoinment.detail.outcome.select4',
      'prospect.appoinment.detail.outcome.select5',
      'prospect.appoinment.detail.outcome.select6',
    ];
    // custom-hook
    return (
      <div>
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
          <div>
            <DialogTitle>
              {mode === FormMode.CREATE
                ? Translation('prospect.appoinment.detail.title.add')
                : Translation('prospect.appoinment.detail.title.edit')}
            </DialogTitle>
          </div>
          <DialogContent>
            <FormControl fullWidth style={{ marginBottom: 24 }} error={errors['purpose']} required>
              <InputLabel id="purpose-label">{Translation('prospect.appoinment.title.purpose')}</InputLabel>
              <Select
                labelId="purpose-label"
                id="purpose"
                value={formData?.purpose}
                label={Translation('prospect.appoinment.title.purpose')}
                onChange={(e) => {
                  setFormData({ ...formData, purpose: e.target.value });
                }}
                size="medium"
                required
              >
                {purposeLabels.map((item, index) => (
                  <MenuItem value={Translation(item)} key={index}>
                    {Translation(item)}
                  </MenuItem>
                ))}
              </Select>
              {errors['purpose'] && <FormHelperText>{Translation('common.mandatoryField')}</FormHelperText>}
            </FormControl>

            <FormControl fullWidth style={{ marginBottom: 24 }} error={errors['startDate']} required>
              <DateTimePicker
                label={Translation('prospect.appoinment.detail.title.updatedTime') + ' *'}
                ampm={false}
                slotProps={{ textField: { variant: 'outlined', size: 'medium' } }}
                onChange={(newValue) => {
                  if (newValue) {
                    setFormData({ ...formData, startDate: newValue.toISOString() });
                  }
                }}
                value={formData?.startDate ? dayjs(formData?.startDate) : undefined}
              />
              {errors['startDate'] && <FormHelperText>{Translation('common.mandatoryField')}</FormHelperText>}
            </FormControl>

            {mode === FormMode.EDIT && (
              <FormControl fullWidth style={{ marginBottom: 24 }} error={errors['outcome']}>
                <InputLabel id="outcome-label">{Translation('prospect.appoinment.detail.title.outcome')}</InputLabel>
                <Select
                  labelId="outcome-label"
                  id="outcome"
                  value={formData?.outcome}
                  label={Translation('prospect.appoinment.detail.title.outcome')}
                  onChange={(e) => {
                    setFormData({ ...formData, outcome: e.target.value });
                  }}
                  size="medium"
                  required
                >
                  {outcomeLables.map((item, index) => (
                    <MenuItem value={Translation(item)} key={index}>
                      {Translation(item)}
                    </MenuItem>
                  ))}
                </Select>
                {errors['outcome'] && <FormHelperText>{Translation('common.mandatoryField')}</FormHelperText>}
              </FormControl>
            )}
            <div>
              <TextField
                fullWidth
                size="medium"
                id="location"
                label={Translation('prospect.appoinment.title.location')}
                variant="outlined"
                value={formData?.location}
                onChange={(e) => {
                  setFormData({ ...formData, location: e.target.value });
                }}
                style={{ marginBottom: 24 }}
              />
            </div>
            <div>
              <TextField
                fullWidth
                size="medium"
                id="notes"
                label={Translation('prospect.appoinment.detail.title.notes')}
                variant="outlined"
                value={formData?.notes}
                onChange={(e) => {
                  setFormData({ ...formData, notes: e.target.value });
                }}
                style={{ marginBottom: 24 }}
              />
            </div>
          </DialogContent>

          <DialogActions style={{ paddingBottom: 20 }}>
            <Button variant="outlined" onClick={onClose}>
              {Translation('app.button.cancel')}
            </Button>
            <Button variant="contained" color="secondary" onClick={onConfirm}>
              {Translation('app.button.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  },
);

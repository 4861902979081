import { ProColumns } from 'src/app/common/components/ProTable';
import { Prospect, ProspectCategory } from '../../../types/prospect-types';
import {
  renderCategory,
  renderDate,
  renderDateTime,
  renderDeleteDate,
  renderGender,
  renderProspectName,
  renderSource,
  renderTag,
} from '../../../util/table-column.util';

export const getColumns = ({
  t,
  prospectCategories,
  keyword,
  styles,
}: {
  t: (val: string) => string;
  prospectCategories?: ProspectCategory[];
  keyword?: string;
  styles: Record<string, any>;
}): ProColumns<Prospect>[] => [
  {
    title: t('pruleads.prospect.list.column.name'),
    dataIndex: 'displayName',
    width: '290px',
    render: renderProspectName,
  },
  {
    title: t('pruleads.prospect.purgelist.column.deleteDate'),
    dataIndex: 'updatedAt',
    width: '176px',
    render: renderDeleteDate,
  },
  {
    title: t('pruleads.prospect.list.column.gender'),
    dataIndex: 'gender',
    width: '176px',
    render: (currValue: 'F' | 'M') => renderGender(currValue, t),
  },
  {
    title: t('pruleads.prospect.list.column.prospectType'),
    dataIndex: 'types',
    width: '176px',
    render: renderTag,
  },
  {
    title: t('pruleads.prospect.list.column.source'),
    dataIndex: 'sourceType',
    width: '176px',
    render: (currValue: string) => renderSource(currValue, t),
  },
  {
    title: t('pruleads.prospect.list.column.category'),
    dataIndex: 'prospectCategory',
    width: '176px',
    render: (currValue: string) => renderCategory(currValue, prospectCategories),
  },
  {
    title: t('pruleads.prospect.list.column.leadInProgress'),
    dataIndex: 'activeLeads',
    width: '176px',
  },
  {
    title: t('pruleads.prospect.list.createDate'),
    dataIndex: 'createdAt',
    width: '176px',
    render: renderDate,
  },
  {
    title: t('pruleads.prospect.list.column.lastUpdateTime'),
    dataIndex: 'updatedAt',
    width: '176px',
    render: renderDateTime,
  },
  {
    title: t('pruleads.prospect.list.column.lastViewedAt'),
    dataIndex: 'lastViewedAt',
    width: '176px',
    render: renderDate,
  },
];

import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchActivityStatusFeedback } from 'src/app/modules/Campaign/network/campaignCurd';
import { ServicingCampaignContext } from '../detail-page.hook';

interface HookProps {
  campaignId: string;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

type ActivityStatus = {
  key: string;
  value: string;
};

export const useUpdateServicingCampaignDialog = (props: HookProps) => {
  const { campaignId } = props;
  const { activityStatusFeedback } = useContext(ServicingCampaignContext);
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const onSelectStatus = (key: string) => {
    setSelectedStatus(key);
  };

  return {
    selectedStatus,
    activityStatusFeedback,
    onSelectStatus,
  };
};

import React, { FC, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './participant-eligibility.style';
import { useParticipantEligibility } from './participant-eligibility.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { ImportFailedList } from '../../../Details/application/components/ImportFailedList';
import { AgentItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';

type ComponentProps = ParamsProps & {
  dataList: any[];
  onCancel: () => void;
  onProcess: () => void;
  onFinish: () => void;
};

export const ParticipantEligibilityComponent: React.FC<ComponentProps> = memo(
  ({ dataList, onCancel, onProcess, onFinish }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    const { processing, setProcessing } = useParticipantEligibility({ dataList });
    return (
      <div className={styles.container}>
        <div className={styles.warning}>{Translation('agencyCampaign.create.belowFailEligibilityCheck')}</div>
        <ImportFailedList dataSource={dataList} />
        <div className={classes.btnGroupWrap}>
          <Button
            className={classes.btnGroup}
            variant="outlined"
            color="inherit"
            onClick={async () => {
              onCancel();
            }}
          >
            {Translation('app.button.cancel')}
          </Button>
          <Button
            className={classes.btnGroup}
            variant="contained"
            disabled={processing}
            color="secondary"
            onClick={async () => {
              setProcessing(true);
              await onProcess();
              setProcessing(false);
              onFinish();
            }}
          >
            {Translation('app.button.removeAndProceed')}
          </Button>
        </div>
      </div>
    );
  },
);

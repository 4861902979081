import React, { memo, useImperativeHandle, useMemo, useState } from 'react';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './appointment.style';
import { useAppointment } from './appointment.hook';
import ProFilterTable from 'src/app/common/components/ProTable';
import { AddApoointmentDialogComponent } from './add-apoointment-dialog/add-apoointment-dialog.component';
import { FormMode } from 'src/app/common/utils';
import { Dialog, DialogTitle, DialogContent, Button, DialogActions } from '@mui/material';
import { Translation } from 'src/app/i18n';
import PruDialog from 'src/app/common/components/PruDialog';

type ComponentProps = ParamsProps & {
  /* otherProp: string */
  prospect: any;
  onLoaded?: (sum: number) => void;
};

export const AppointmentComponent = memo(
  React.forwardRef((props: ComponentProps, ref) => {
    const {
      columns,
      getData,
      deleteDialogVisible,
      editDialogVisible,
      addDialogVisible,
      setDeleteDialogVisible,
      setEditDialogVisible,
      setAddDialogVisible,
      targetAppointment,
      onConfirmDelete,
      actionRef,
    } = useAppointment(props);

    useImperativeHandle(ref, () => ({
      addAppointment: () => setAddDialogVisible(true),
    }));

    return (
      <div>
        <ProFilterTable
          filterConfigs={[]}
          showFilter={false}
          rowKey="_id"
          columns={columns}
          request={getData}
          enableRefresh={false}
          showActionBar={false}
          showSelect={false}
          actionRef={actionRef}
        />
        <AddApoointmentDialogComponent
          open={editDialogVisible}
          onClose={() => setEditDialogVisible(false)}
          data={targetAppointment}
          mode={FormMode.EDIT}
          prospect={props.prospect}
          actionRef={actionRef}
        />
        <AddApoointmentDialogComponent
          open={addDialogVisible}
          onClose={() => setAddDialogVisible(false)}
          mode={FormMode.CREATE}
          prospect={props.prospect}
          actionRef={actionRef}
        />
        <PruDialog
          onCancel={() => setDeleteDialogVisible(false)}
          onOk={onConfirmDelete}
          open={deleteDialogVisible}
          dialogTitle={Translation('app.button.delete')}
          children={Translation('prospect.appoinment.delete.msg')}
          canncelBtnText={Translation('app.button.cancel')}
          confirmBtnText={Translation('app.button.confirm')}
        />
      </div>
    );
  }),
);

import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { getColumns } from './util/lead-column.util';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { CampaignLeadItem, MassCampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { generateLeadsCompareFunction } from 'src/app/modules/Campaign/util/campaign-list.util';

interface HookProps {
  campaginDetail: MassCampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
}

export const useMassCampaignLeadList = ({ campaginDetail, cacheParams }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [keyword, setKeyword] = useState('');
  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
      }),
    [Translation],
  );
  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);
      setKeyword(keyword);
    },
    [cacheParams],
  );
  // useRequest to wrap api request.`
  // DO NOT use useDataProvider except for <PruFilter />
  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      if (campaginDetail.length > 0) {
        if (sort) {
          const compareFunction = generateLeadsCompareFunction(sort);
          campaginDetail = campaginDetail.sort(compareFunction);
        }
        const sliceData = campaginDetail.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);
        return {
          success: true,
          data: sliceData ?? [],
          total: campaginDetail?.length ?? 0,
        };
      } else {
        return {
          success: false,
          data: [],
          total: 0,
        };
      }
    },
    [campaginDetail],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {},
      getCheckboxProps: (row: CampaignLeadItem, rows: CampaignLeadItem[]) => {
        if (rows && rows.length > 0) {
          const r = rows[0];
          return {
            disabled: r.status !== row.status,
          };
        }
        return {
          disabled: false,
        };
      },
    }),
    [],
  );

  return {
    getData,
    columns,
    rowSelection,
    params: {},
    onFilterChange,
  };
};

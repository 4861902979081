import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { Appointment, EventData } from '../../../types/appointment-types';
import { patchProspectAppointment, postProspectAppointment } from '../../../network/appointmentCrud';
import { FormMode } from 'src/app/common/utils';
import { PruToast } from 'src/app/common/components/pru-toast';
import { AlertType, appendAlertItem } from 'src/redux/common/commonSlice';
import { LeadCategoryEnum } from '../../../types/lead-category-types';
import moment from 'moment';

interface HookProps {
  onClose: () => void;
  data?: EventData;
  mode: FormMode;
  prospect: any;
  actionRef?: any;
}

export const useAddApoointmentDialog = ({ onClose, data, mode, prospect, actionRef }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [formData, setFormData] = useState<Partial<Appointment> | undefined>({
    startDate: moment().toDate().toString(),
  });
  const [errors, setErrors] = useState<any>({});
  const mandatoryField = [`purpose`, `startDate`, `outcome`];
  const createMandatoryField = [`purpose`, `startDate`];
  // redux
  const dispatch = useDispatch();
  useEffect(() => {
    if (mode === FormMode.EDIT) {
      setFormData(data?.content);
    }
    setErrors({});
  }, [mode, data]);

  const postAppointment = async (appointment: any) => {
    const body = {
      ...appointment,
      name: prospect.displayName ?? '',
      // leadId: route.params?.leadId ?? '',
      prospectId: prospect._id ?? '',
      source: 'AGENT',
      leadCategory: LeadCategoryEnum.SALES_LEAD,
    };
    const res = await postProspectAppointment(body, dispatch);
  };

  const editAppointment = async (id: string, appointment: any) => {
    const body = { ...appointment };
    body.agentCode = appointment.agentCode;
    body.content = appointment;
    body.date = moment(appointment.startDate).toDate();
    const res = await patchProspectAppointment(id, body, dispatch);

    // ------  lead appointment update outcome will update lead status ------

    // const leadId = get(appointment, 'content.leadId');
    // const outcome = get(appointment, 'content.outcome');
    // const leadCategory = get(appointment, 'content.leadCategroy', LeadCategoryEnum.SALES_LEAD);
    // if (outcome && leadId && leadCategory === LeadCategoryEnum.SALES_LEAD) {
    //   const body = { status: '', submissionDate: moment().toISOString() };
    //   body.status = mapOutcome(outcome);
    //   if (body.status) {
    //     updateLeadStatus.refetch({
    //       leadId: leadId,
    //       body: body,
    //     });
    //   }
    // }
  };

  const validateField = (fields: string[]) => {
    //validate mandatory
    const newErrorState: any = {};
    fields.forEach((field) => {
      if (!formData?.[field as keyof Appointment]) {
        newErrorState[field] = true;
      }
    });
    setErrors(newErrorState);
    return Object.values(newErrorState).some((r) => r === true);
  };

  const onConfirm = async () => {
    if (mode === FormMode.CREATE) {
      if (validateField(createMandatoryField)) return;
      if (formData) {
        try {
          await postAppointment(formData);
          onClose();
          actionRef.current?.reload();
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.SUCCESS,
                title: Translation('global.submit.success'),
                content: Translation('agentProfile.ap_created_successfully_label'),
              },
            ]),
          );
        } catch (err) {
          dispatch(
            appendAlertItem([
              {
                severity: AlertType.ERROR,
                content: Translation('common.serverError'),
              },
            ]),
          );
        }
      }
    } else {
      if (validateField(mandatoryField)) return;
      if (formData) {
        if (data?.eventId) {
          try {
            await editAppointment(data?.eventId, formData);
            onClose();
            actionRef.current?.reload();
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.SUCCESS,
                  title: Translation('global.submit.success'),
                  content: Translation('common.updateSuccessfully'),
                },
              ]),
            );
          } catch (err) {
            dispatch(
              appendAlertItem([
                {
                  severity: AlertType.ERROR,
                  content: Translation('common.serverError'),
                },
              ]),
            );
          }
        }
      }
    }
  };

  return { onConfirm, formData, setFormData, errors };
};

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchActivityStatusFeedback, fetchCampaignDetail } from '../../../network/campaignCurd';
import { CampaignLeadItem, FollowUpStatusEnum, CampaignDetail } from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';

interface HookProps {
  id: string;
  // other params
}

type ActivityStatus = {
  key: string;
  value: string;
};

type ServicingCampaignContextProps = {
  activityStatusFeedback: ActivityStatus[];
  refresh: () => void;
};

export const ServicingCampaignContext = React.createContext<ServicingCampaignContextProps>({
  activityStatusFeedback: [],
  refresh: () => {},
});

export const useDetailPage = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const history = useHistory();

  // redux
  const dispatch = useDispatch();
  // const saleskit = useSelector<RootState>((state) => state.saleskit);

  // useRequest to wrap api request.
  // DO NOT use useDataProvider except for <PruFilter />
  const { data, error, loading, run, runAsync } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: true,
  });

  const activityStatusFeedback = useRequest(() => fetchActivityStatusFeedback(id), {
    manual: false,
  });

  const convertLeadData = (data: any): CampaignLeadItem[] => {
    if (!data) return [];
    const leads: CampaignLeadItem[] = [];
    data.leads.forEach((lead: any) => {
      const isDead = [FollowUpStatusEnum.dead, FollowUpStatusEnum.contactFailure].includes(lead.followUpStatus);
      const isNew = lead.agentStatus === FollowUpStatusEnum.pending;
      let remark = '';
      if (isDead) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.reasons;
      } else if (lead.followUpStatus === FollowUpStatusEnum.policy) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.issuedPolicy;
      }

      leads.push({
        _id: lead._id,
        name: lead.prospectDetail.alias,
        labels: lead.labels,
        campaignName: data.name,
        sla: lead?.expiredAt,
        leadSource: data.name,
        status: isNew ? FollowUpStatusEnum.pending : lead.followUpStatus,
        remark: remark,
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt,
        feedback: lead.feedback,
      });
    });
    return leads;
  };

  const convertCampaignInfo = (data: any): CampaignDetail => {
    const newCampaignData: CampaignDetail = { ...data } as CampaignDetail;
    return newCampaignData;
  };

  useEffect(() => {
    run();
  }, []);

  const navToList = () => {
    history.goBack();
  };

  const refresh = () => {
    run();
  };

  return {
    loading,
    data: data,
    leadsData: convertLeadData(data),
    activityStatusFeedback: activityStatusFeedback.data,
    run,
    navToList,
    refresh,
  };
};

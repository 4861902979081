import React, { memo, useState } from 'react';
import { Box, Paper } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useCampaignInfo } from './campaign-info.hook';
import { CampaignStructureItem } from 'src/app/modules/AgencyCampaign/types/campaign-types';

type ComponentProps = ParamsProps & {
  campaign: any;
  campaignTypeStructureData?: CampaignStructureItem;
  formDispatch: (data: any) => void;
  formState: any;
  errorState: any;
  campaignStatus?: string;
  disabledEdit?: boolean;
  disabled?: boolean;
  validationHandler: any;
  campaignEndDateEqualToStartDate?: any;
  campaignEndDateGreaterThanTarget?: any;
  onSaveCampaign: (val: any) => void;
  formStateUpdated?: boolean;
  isCampaignOwner: boolean;
  campaignObjId?: string;
  checkStepHaveError: () => Record<string, boolean>;
  onSaveInfo: () => void;
};

export const CampaignInfoComponent: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { onSaveInfo } = props;

  // custom-hook
  const { _renderSection } = useCampaignInfo(props);
  const { campaignTypeStructureData, formStateUpdated, campaign } = props;

  const onSave = async () => {
    setIsEdit(false);
    if (formStateUpdated) {
      await onSaveInfo();
    }
  };

  const onEdit = () => {
    setIsEdit(true);
  };

  const [isEdit, setIsEdit] = useState(campaign.campaignId === undefined);

  return (
    <Paper elevation={3}>
      <Box sx={{ padding: '24px' }}>
        {campaignTypeStructureData?.sections
          .filter((item) => item.key !== 'participant' && item.key !== 'attachments' && item.key !== 'postDocuments')
          .map((sectionItem) => {
            return _renderSection(sectionItem, { isEdit, onSave, onEdit });
          })}
      </Box>
    </Paper>
  );
});

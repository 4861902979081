import React, { FC, memo, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Button, FormControlLabel, LinearProgress, Radio, RadioGroup, TextField } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './tab-lead-filter.style';
import { CategorySelectorComponent } from 'src/app/common/components/category-selector/category-selector.component';
import { LeadFilterProps } from '../../campaign-lead-filter-table/campaign-lead-filter-table.component';
import { SelectDownline } from 'src/app/common/components/select-downline/select-downline.component';
import { FilterProps } from 'src/app/common/components/ProTable/ProFilter';
import { GlobalHelper } from 'src/app/common/helpers/GlobalHelpers';
import { Option } from 'src/app/common/components/cascading-selector/cascading-selector.component';
import moment from 'moment';
import { constants } from 'src/app/common/constants';

type ComponentProps = LeadFilterProps & FilterProps<any>;

export const TabLeadFilterComponent: React.FC<LeadFilterProps> = memo(
  ({
    campaignId,
    agentCode,
    filterState,
    cacheParams,
    campaignOwner,
    acmAddLeadSetting,
    isACMApproved,
    isInSchedule,
    setFilterState,
    onSave,
  }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);
    const [agentCodes, setAgentCodes] = React.useState<string[]>([agentCode]);

    useEffect(() => {
      if (filterState.agentCodes) {
        setAgentCodes(filterState.agentCodes);
      }
    }, []);

    return (
      <>
        {campaignOwner === GlobalHelper.getAgentCode() ? (
          <div className={styles.mb_24}>
            <SelectDownline
              campaignId={campaignId}
              currentOption={
                cacheParams?.current?.filterState.downline ?? [
                  { label: '', value: '' },
                  { label: '', value: GlobalHelper.getAgentCode() },
                ]
              }
              onChange={(option: Array<Option>, codeArr: Array<string>) => {
                const updatedLeadState = { ...filterState };
                updatedLeadState.downline = option;
                updatedLeadState.agentCodes = codeArr;
                setAgentCodes(codeArr);
                setFilterState(updatedLeadState);
                onSave(updatedLeadState);
              }}
            />
          </div>
        ) : null}

        {!isACMApproved || !isInSchedule ? (
          <div className={`${styles.mb_24} ${styles.addLeadWarning}`}>
            {!isInSchedule &&
              `${Translation('agencyCampaign.lead.schdule')}: ${moment(acmAddLeadSetting.startDate).format(
                constants.Default_Date_Format,
              )} - ${moment(acmAddLeadSetting.endDate).format(constants.Default_Date_Format)}`}
            {!isACMApproved && !isInSchedule && '; '}
            {!isACMApproved && `${Translation('campaign_should_be_approved_to_start_the_section')}`}
          </div>
        ) : null}

        <div className={styles.mb_24}>
          <CategorySelectorComponent
            campaignId={campaignId}
            agentCodes={agentCodes}
            onChange={(e) => {
              const updatedLeadState = { ...filterState };
              updatedLeadState.leadState = e;
              setFilterState(updatedLeadState);
              onSave(updatedLeadState);
            }}
            value={cacheParams?.current?.filterState.leadState ?? 'total'}
          />
        </div>
      </>
    );
  },
);

import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, FormControlLabel, FormGroup, FormLabel, Grid } from '@mui/material';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useStyles } from './list-filter-checkbox-group.style';
import { ListFilterConfig } from '../list-filter/list-filter.component';

type ComponentProps = ParamsProps & {
  value?: string[];
  filterConfig: ListFilterConfig;
  onChange?: (value: string[]) => void;
};

export const ListFilterCheckboxGroupComponent: React.FC<ComponentProps> = memo(
  ({ filterConfig, value, onChange }: ComponentProps) => {
    // i18n
    const intl = useIntl();
    const Translation = (id: string) => intl.formatMessage({ id });

    // style
    const { classes } = useStyles();
    const styles = useMemo(() => classes, [classes]);

    // custom-hook
    return (
      <div className={styles.mt_24}>
        <FormLabel classes={{ root: styles.formLabel }}>{filterConfig.title}</FormLabel>
        <FormGroup>
          <Grid container spacing={1} classes={{ root: `${styles.fullWidth} ${styles.mt_8} ${styles.ml_8}` }}>
            {filterConfig.items.map((item) => {
              if (item.extraCompo) {
                // TBD
                console.warn('[Agent Portal]: extraCompo is currently not supported.');
              }
              return (
                <Grid item xs={6} key={item.itemKey}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={(value && value.includes(item.itemKey)) ?? false}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          if (e.target.checked) {
                            onChange?.(value ? [...value, item.itemKey] : [item.itemKey]);
                          } else {
                            onChange?.(value?.filter((option) => option !== item.itemKey) || []);
                          }
                        }}
                        value={item.itemKey}
                      />
                    }
                    label={item.itemLabel}
                  />
                </Grid>
              );
            })}
          </Grid>
        </FormGroup>
      </div>
    );
  },
);

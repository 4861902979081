import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';
import { deleteProspectAppointment, fetchProspectAppointments } from '../../network/appointmentCrud';
import { useStyles } from './appointment.style';
import { RequestData } from 'src/app/common/components/ProTable';
import { Appointment, EventData } from '../../types/appointment-types';
import { getColumns } from './util/appointment.util';
import { appendAlertItem, AlertType } from 'src/redux/common/commonSlice';
import { ActionType } from 'src/app/common/components/ProTable';

interface HookProps {
  prospect: any;
  onLoaded?: (sum: number) => void;
}

export const useAppointment = ({ prospect, onLoaded }: HookProps) => {
  // i18n
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [targetAppointment, setTargetAppointment] = useState<EventData>();
  const actionRef = useRef<ActionType>();
  // redux
  const dispatch = useDispatch();
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
        styles: styles,
        onDelete: setDeleteDialogVisible,
        onEdit: setEditDialogVisible,
        setTargetAppointment,
      }),
    [Translation, styles],
  );

  const getData = useCallback(
    async (params: {
      page: number;
      pageSize: number;
      current?: number | undefined;
      keyword?: string | undefined;
    }): Promise<RequestData<EventData>> => {
      const res = await fetchProspectAppointments(prospect._id, dispatch);
      if (res) {
        const sliceData = res.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);
        onLoaded && onLoaded(res?.length ?? 0);
        return {
          success: true,
          data: sliceData ?? [],
          total: res?.length ?? 0,
        };
      }
      onLoaded && onLoaded(0);
      return {
        success: false,
        data: [],
        total: 0,
      };
    },
    [prospect._id],
  );

  const onConfirmDelete = async () => {
    if (targetAppointment?.eventId) {
      try {
        await deleteProspectAppointment(targetAppointment.eventId, dispatch);
        setDeleteDialogVisible(false);
        actionRef.current?.reload();
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.SUCCESS,
              title: Translation('app.button.delete'),
              content: Translation('prospect.deleteSuccessfully'),
            },
          ]),
        );
      } catch (error) {
        dispatch(
          appendAlertItem([
            {
              severity: AlertType.ERROR,
              title: Translation('prospect.deleteFailed'),
              content: Translation('common.pleaseTryAgain'),
            },
          ]),
        );
      }
    }
  };

  return {
    columns,
    getData,
    deleteDialogVisible,
    editDialogVisible,
    addDialogVisible,
    setDeleteDialogVisible,
    setEditDialogVisible,
    setAddDialogVisible,
    targetAppointment,
    onConfirmDelete,
    actionRef,
  };
};

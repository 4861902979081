import { CampaignLead } from '../../../../../types/campaign-types';
import { ProColumns } from '../../../../../../../common/components/ProTable';
import { DateFormat, formatDate, reconstuctNameByLastName } from '../../../../../../../common/utils';
import { ProTableOperationBtnComponent } from 'src/app/common/components/ProTable/components/pro-table-operation-btn/pro-table-operation-btn.component';
import { isEmptyArray } from 'formik';
import { LeadTypesTagComponent } from '../../../../components/lead-types-tag/lead-types-tag.component';

const noActionColumns = ({
  t,
  styles,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
}): ProColumns<CampaignLead>[] => {
  return [
    {
      title: t('component.formLabel.name'),
      dataIndex: 'displayName',
      sorter: true,
      width: '290px',
      render: (currValue: string, item) => {
        return (
          <div className={styles.itemText}>
            {currValue || reconstuctNameByLastName(item.prospect.firstName, item.prospect.lastName)}
          </div>
        );
      },
    },
    {
      title: t('agencyCampaign.formTitle.lead.leadStatus'),
      dataIndex: 'followUpStatus',
      width: '176px',
      render: (currValue: string) => {
        return <div className={styles.itemText}>{currValue}</div>;
      },
    },
    {
      title: t('agencyCampaign.field.campaignName'),
      dataIndex: 'name',
      width: '466px',
      render: (currValue: string, item) => {
        return <div className={styles.itemText}>{item.campaign.name || ''}</div>;
      },
    },
    {
      title: t('agencyCampaign.approval.remark'),
      dataIndex: 'description',
      width: '290px',
      render: (currValue, item) => {
        if (!isEmptyArray(item.issuedPolicy)) {
          return (
            <div className={styles.itemText}>
              {item.issuedPolicy.map((v, idx) => {
                return (
                  <div key={idx}>
                    {t('lead_remark_policy')}
                    {v.policyNumber}
                  </div>
                );
              })}
            </div>
          );
        }
        if (item.followUpStatus === 'dead') {
          return (
            <div className={styles.itemText}>
              <div>{t('lead_remark_deadreason')}</div>
              {item.followUp.filter((v) => v.status === 'dead')[0].reason}
            </div>
          );
        }
        return '';
      },
    },
    {
      title: t('agencyCampaign.formTitle.lead.leadType'),
      dataIndex: 'labels',
      width: '176px',
      render: (currValue: string[], item) => {
        return item ? <LeadTypesTagComponent currValue={item.labels} /> : '';
      },
    },
    {
      title: t('agencyCampaign.common.leadSource'),
      dataIndex: 'leadSouce',
      width: '176px',
      sorter: true,
      render: (currValue, item) => {
        return <div className={styles.itemText}>{item.leadSource ?? ''}</div>;
      },
    },
    {
      title: t('agencyCampaign.common.lastUpdatedTime'),
      sorter: true,
      dataIndex: 'updatedAt',
      width: '176px',
      render: (currValue: string) => {
        return <div className={styles.itemText}>{formatDate(currValue, DateFormat.datetime)}</div>;
      },
    },
    {
      title: t('agencyCampaign.formTitle.lead.lastView'),
      sorter: true,
      dataIndex: 'lastViewedAt',
      width: '176px',
      render: (currValue: string) => {
        return <div className={styles.itemText}>{formatDate(currValue, DateFormat.datetime)}</div>;
      },
    },
  ];
};

export const getNoActionColumns = ({
  t,
  styles,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
}): ProColumns<CampaignLead>[] => {
  return noActionColumns({ t, styles });
};

export const getActionColumns = ({
  t,
  styles,
  onAppt,
  onUpdate,
}: {
  t: (val: string) => string;
  styles: Record<string, any>;
  onAppt?: (prospect: string) => void;
  onUpdate?: (prospect: string) => void;
}): ProColumns<CampaignLead>[] => {
  return [
    ...noActionColumns({ t, styles }),
    {
      title: t('component.formLabel.operation'),
      dataIndex: '',
      width: '176px',
      sticky: true,
      render: (currValue, item) => {
        if (item.agentStatus === 'pedning') {
          return (
            <>
              <ProTableOperationBtnComponent label={t('app.button.reject')} />
              <ProTableOperationBtnComponent label={t('app.button.accept')} />
            </>
          );
        }
        return (
          <>
            <ProTableOperationBtnComponent
              onClick={() => {
                onAppt?.(item._id);
              }}
              label={t('app.button.listOperation.appt')}
            />
            <ProTableOperationBtnComponent
              onClick={() => {
                onUpdate?.(item._id);
              }}
              label={t('app.button.listOperation.update')}
            />
          </>
        );
      },
    },
  ];
};

export const i18nKeyMapping = {
  contactSuccess: {
    i18nKey: 'Sales:contactSuccess',
    query: { agentStatus: 'accepted', lastFollowUpStatus: 'contactSuccess' },
  },
  met: { i18nKey: 'Sales:met', query: { agentStatus: 'accepted', lastFollowUpStatus: 'met' } },
  quote: { i18nKey: 'Sales:quote', query: { agentStatus: 'accepted', lastFollowUpStatus: 'quote' } },
  proposal: { i18nKey: 'Sales:proposal', query: { agentStatus: 'accepted', lastFollowUpStatus: 'proposal' } },
  policy: { i18nKey: 'Sales:policy', query: { agentStatus: 'accepted', lastFollowUpStatus: 'policy' } },
  dead: { i18nKey: 'Sales:dead_lead', query: { agentStatus: 'accepted', lastFollowUpStatus: 'dead' } },
  pendingAccept: { i18nKey: 'Sales:pending_accept', query: { agentStatus: 'pending' } },
  pendingContact: {
    i18nKey: 'Sales:follow_up_state_pending',
    query: { agentStatus: 'accepted', lastFollowUpStatus: 'null' },
  },
};

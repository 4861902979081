import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { getColumns } from './util/lead-column.util';
import { CampaignLeadItem, FollowUpStatusEnum } from 'src/app/modules/Campaign/types/campaign-types';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { generateLeadsCompareFunction } from 'src/app/modules/Campaign/util/campaign-list.util';
import { TranslationWithParams } from '../../../../../../i18n/utils/i18n-utils';
import { useRequest } from 'ahooks';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchMarketingCampaignList } from 'src/app/modules/Campaign/network/campaignCurd';
import { i18nKeyMapping } from 'src/app/modules/AgencyCampaign/pages/Campaign/detail/campaign-lead-filter-table/util/campaign-lead-filter-table.util';
import { getLeadList } from 'src/app/modules/AgencyCampaign/network/campaignCurd';

interface HookProps {
  campaginLeads: CampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  campaignId: string;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useCampaignLeadList = ({ campaginLeads, cacheParams, campaignId }: HookProps) => {
  // i18n
  const user: any = useSelector<any>((state) => state.auth.user);
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [keyword, setKeyword] = useState('');
  // redux
  const dispatch = useDispatch();

  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
      }),
    [Translation],
  );
  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);

      setKeyword(keyword);
    },
    [cacheParams],
  );

  const mapLeadStatus = (lead: any) => {
    if (lead.followUp.length < 1) {
      return FollowUpStatusEnum.pendingToContact;
    } else {
      return lead.followUp[lead.followUp.length - 1].status;
    }
  };

  const convertLeadData = (data: any): CampaignLeadItem[] => {
    if (!data) return [];
    const leads: CampaignLeadItem[] = [];
    data.forEach((lead: any) => {
      const isDead = [FollowUpStatusEnum.dead, FollowUpStatusEnum.contactFailure].includes(lead.followUpStatus);
      const isNew = lead.agentStatus === FollowUpStatusEnum.pending;
      let remark = '';
      if (isDead) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.reasons;
      }
      if (lead.followUpStatus === FollowUpStatusEnum.issued) {
        remark = lead.followUp?.[lead?.followUp.length - 1]?.issuedPolicy;
      }

      leads.push({
        _id: lead._id,
        name: lead.prospectDetail.alias,
        labels: lead.labels,
        campaignName: lead.campaign.name,
        sla: lead?.expiredAt ?? '',
        leadSource: lead.leadSource,
        status: isNew ? FollowUpStatusEnum.pending : mapLeadStatus(lead),
        remark: remark ?? [],
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt ?? '',
      });
    });
    return leads;
  };

  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      const { page, pageSize, keyword: searchkey } = params;
      let sortBy = 'leadStatus';
      let flag = '';
      const searchAgentCode = filter?.agentCode ? filter?.agentCode : [user.agentCode];
      let leadQuery: any = i18nKeyMapping[filter?.leadState as keyof typeof i18nKeyMapping]?.query;
      if (sort && Object.keys(sort).length > 0) {
        const [key, val] = Object.entries(sort)[0];

        if (val === 'desc') {
          flag = '-';
        }

        if (key === 'displayName') {
          if (flag) {
            sortBy = '-nameWeight -firstName';
          } else {
            sortBy = 'nameWeight firstName';
          }
        } else {
          sortBy = `${flag}${key}`;
        }
        leadQuery = { ...leadQuery, sortBy: sortBy };
      }

      const res = await getLeadList(
        {
          campaignId,
          agentCode: searchAgentCode,
          page: page + 1,
          leadQuery,
          pageSize,
          sortBy: sortBy,
          sortOrder: flag === '-' ? -1 : 1,
        },
        dispatch,
      );

      if (res) {
        const { docs, totalDocs, ...rest } = res;
        return {
          success: true,
          data: convertLeadData(docs) ?? [],
          total: totalDocs,
          ...rest,
        };
      }

      return { success: false, data: [], total: 0 };
    },
    [],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {},
      getCheckboxProps: (row: CampaignLeadItem, rows: CampaignLeadItem[]) => {
        if (rows && rows.length > 0) {
          const r = rows[0];
          return {
            disabled: r.status !== row.status,
          };
        }
        return {
          disabled: false,
        };
      },
    }),
    [],
  );

  return {
    getData,
    columns,
    rowSelection,
    params: {},
    onFilterChange,
  };
};

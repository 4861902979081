import { LOCAL_STORAGE_KEYS } from '../constants';

let _env: EnvData = {};

export type EnvData = {
  toastRef?: any;
  modalRef?: any;
  intlRef?: any;
  authState?: any;
  agentCode?: string;
};

function setEnv(env: EnvData): void {
  _env = env;
}

function setSafetyEnv(env: EnvData): void {
  _env = { ..._env, ...(env || {}) };
}

function getEnv(): EnvData {
  return _env;
}

function setGlobalToastRef(toastRef: any): void {
  _env = { ..._env, toastRef };
}

function getGlobalToastRef(): any {
  return _env.toastRef || {};
}

function setGlobalModalRef(modalRef: any): void {
  _env = { ..._env, modalRef };
}

function getGlobalModalRef(): any {
  return _env.modalRef || {};
}

function setGlobalIntlRef(intlRef: any): void {
  _env = { ..._env, intlRef };
}

function getGlobalIntlRef(): any {
  return _env.intlRef || {};
}
function setAuthState(state: any) {
  try {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.agentInfo, JSON.stringify(state));
    _env = { ..._env, authState: state };
  } catch (err) {
    console.error('setAuthState error: ', err);
  }
}

function getAuthState(): any {
  try {
    let authState = _env.authState;
    if (!authState) {
      const e = window.localStorage.getItem(LOCAL_STORAGE_KEYS.agentInfo);
      authState = e ? JSON.parse(e) : e;
      _env = { ..._env, authState };
    }
    return authState;
  } catch (err) {
    console.error('getAuthState error: ', err);
  }
}
function getAgentCode(): string {
  const auth = getAuthState();
  _env.agentCode = auth?.agentCode || _env.agentCode;
  return _env.agentCode || '';
}

export const GlobalHelper = {
  // setter
  setEnv,
  setSafetyEnv,
  setGlobalToastRef,
  setGlobalModalRef,
  setGlobalIntlRef,
  setAuthState,

  //getter
  getEnv,
  getGlobalToastRef,
  getGlobalModalRef,
  getGlobalIntlRef,
  getAuthState,
  getAgentCode,
};

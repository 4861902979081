import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLang } from 'src/app/i18n';

import { getColumns, getFilterConfig } from './util/lead-column.util';
import { CampaignLeadItem } from 'src/app/modules/Campaign/types/campaign-types';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { ActionType } from 'src/app/common/components/ProTable';
import { patchLeadDetail } from 'src/app/modules/Campaign/network/campaignCurd';
import { ServicingCampaignContext } from '../detail-page.hook';
import {
  filterData,
  generateCompareFunction,
  generateSortFunction,
} from 'src/app/modules/Campaign/util/util/campaign-list.util';

interface HookProps {
  campaginDetail: CampaignLeadItem[];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  // other params
}

const defaultParam = {
  page: 1,
  pageSize: 20,
  limit: 5,
};

export const useCampaignLeadList = ({ campaginDetail, cacheParams }: HookProps) => {
  // i18n
  const locale = useLang();
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const [keyword, setKeyword] = useState('');
  // redux
  const dispatch = useDispatch();
  const actionRef = useRef<ActionType>();

  const { activityStatusFeedback, refresh } = useContext(ServicingCampaignContext);
  const [updatingCampaignLead, setUpdatingCampaignLead] = useState<CampaignLeadItem | null>();
  const [showUpdatingDialog, setShowUpdatingDialog] = useState(false);

  const update = useCallback((item: CampaignLeadItem) => {
    setUpdatingCampaignLead(item);
    setShowUpdatingDialog(true);
  }, []);

  // const saleskit = useSelector<RootState>((state) => state.saleskit);
  const columns = useMemo(
    () =>
      getColumns({
        t: Translation,
        update: update,
        activityStatusFeedback: activityStatusFeedback,
      }),
    [Translation],
  );
  const onFilterChange = useCallback(
    (filterState: FilterState) => {
      const { keyword } = filterState;
      cacheParams && (cacheParams.current = filterState);
      setKeyword(keyword);
    },
    [cacheParams],
  );
  // useRequest to wrap api request.`
  // DO NOT use useDataProvider except for <PruFilter />
  const getData = useCallback(
    async (
      params: {
        page: number;
        pageSize: number;
        current?: number | undefined;
        keyword?: string | undefined;
      },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<any> => {
      if (campaginDetail.length > 0) {
        let sliceData = campaginDetail.slice(params.page * params.pageSize, (params.page + 1) * params.pageSize);
        if (sort) {
          const compareFunction = generateSortFunction<CampaignLeadItem>(sort);
          sliceData.sort(compareFunction);
        }
        if (filter) {
          sliceData = filterData(campaginDetail, filter);
        }
        return {
          success: true,
          data: sliceData ?? [],
          total: campaginDetail?.length ?? 0,
        };
      } else {
        return {
          success: false,
          data: [],
          total: 0,
        };
      }
    },
    [campaginDetail],
  );
  const filterConfig = useMemo(
    () => getFilterConfig(Translation, activityStatusFeedback),
    [Translation, activityStatusFeedback],
  );

  const rowSelection = useMemo(
    () => ({
      onChange: (rows: any) => {},
      getCheckboxProps: () => ({
        disabled: false,
      }),
    }),
    [],
  );

  const onDialogCancelClick = () => {
    setUpdatingCampaignLead(null);
    setShowUpdatingDialog(false);
  };

  const onDialogConfirmClick = (selectedStatus: string) => {
    if (updatingCampaignLead && selectedStatus) {
      setShowUpdatingDialog(false);
      patchLeadDetail(updatingCampaignLead?._id, { feedback: selectedStatus }).then((res) => {
        refresh();
      });
    }
  };

  return {
    filterConfig,
    getData,
    columns,
    rowSelection,
    params: {},
    showUpdatingDialog,
    onFilterChange,
    actionRef,
    onDialogConfirmClick,
    onDialogCancelClick,
  };
};

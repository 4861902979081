import { DateFormat, formatDate } from 'src/app/common/utils';
import { ProColumns } from 'src/app/common/components/ProTable';
import { CampaignElement } from 'src/app/modules/Campaign/types/campaign-types';
import { HighlightMatchedText } from 'src/app/common/components/highlight-matched-text/highlight-matched-text.component';

export const getColumns = ({
  t,
  keyword,
  styles,
}: {
  t: (val: string) => string;
  keyword?: string;
  styles: Record<string, any>;
}): ProColumns<CampaignElement>[] => [
  {
    title: '',
    dataIndex: 'lastViewedTime',
    width: '39px',
    render: (currValue: string) => {
      return (
        !currValue && (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-center">
            <span className={styles.newTag}>{t('common.new')}</span>
          </div>
        )
      );
    },
  },
  {
    title: t('campaign.list.campaignName'),
    dataIndex: 'name',
    width: '290px',
    render: (currValue: string, item: CampaignElement) => {
      return (
        <div className="tw-flex tw-flex-row tw-items-center">
          <img src={item.image.url} height={41} width={41} alt="" className="tw-rounded-md tw-object-cover" />
          <span className="tw-ml-4">
            <HighlightMatchedText highLightText={keyword ?? ''}>
              {currValue ? String(currValue) : ''}
            </HighlightMatchedText>
          </span>
        </div>
      );
    },
  },

  {
    title: t('campaign.list.startDate'),
    dataIndex: 'startDate',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('campaign.list.endDate'),
    dataIndex: 'endDate',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
  {
    title: t('campaign.list.leads'),
    dataIndex: 'leads',
    width: '176px',
    sorter: true,
  },
  {
    title: t('campaign.list.lastView'),
    dataIndex: 'lastViewedTime',
    width: '176px',
    sorter: true,
    render: (currValue: string) => {
      return formatDate(currValue, DateFormat.datetime);
    },
  },
];

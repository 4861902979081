import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { FilterProps } from '../../ProFilter';
import { useStyles } from './pro-search-filter.style';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'src/app/common/hooks/translation.hook';

export const useProSearchFilter = <T>({ filterState, setFilterState, onSave, loading }: FilterProps<T>) => {
  const setFilterStateRef = useRef(setFilterState);
  const onSaveRef = useRef(onSave);
  setFilterStateRef.current = setFilterState;
  onSaveRef.current = onSave;
  const [value, setValue] = useState(filterState?.keyword ?? '');
  const loadingRef = useRef(-1);
  const [filterModalVisible, setFilterModalVisible] = useState(false);

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);

  const { t: Translation } = useTranslation();

  const update = useCallback(
    (val: string) => {
      const updateVal = { ...filterState, keyword: val };
      setFilterStateRef.current(updateVal);
      onSaveRef.current(updateVal);
    },
    [filterState],
  );

  useMemo(() => {
    if (!loading && loadingRef.current !== -1) {
      loadingRef.current = 1;
    }
  }, [loading]);

  const handleInputChange = useMemo(() => {
    return _debounce((event) => {
      update(event.target.value);
    }, 500);
  }, [update]);

  const onClear = () => {
    setValue('');
    update('');
  };

  return {
    update,
    value,
    handleInputChange,
    onClear,
    styles,
    Translation,
    loadingRef,
    setValue,
    filterModalVisible,
    setFilterModalVisible,
  };
};

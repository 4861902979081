import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRequest } from 'ahooks';
import { useStyles } from './list.style';
import { getColumns, getFilterConfig } from './util/list.util';
import { RequestData } from 'src/app/common/components/ProTable';
import { FilterState } from 'src/app/common/components/ProTable/ProFilter';
import { CampaignItem } from '../../../types/campaign-types';
import { RouteComponentProps } from 'react-router-dom';
import { getCampaignList, getCampaignTypeList } from './util/api.util';
import { useTranslation } from 'src/app/common/hooks/translation.hook';
import { AGENCY_CAMPAIGN_LIST_PATH } from '../../../constants';

export type ListPageProps = {
  history: RouteComponentProps['history'];
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
};

export const useList = ({ cacheParams, history }: ListPageProps) => {
  const { t: Translation } = useTranslation();

  const { classes } = useStyles();
  const styles = useMemo(() => classes, [classes]);
  const [keyword, setKeyword] = useState('');
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);

  const dispatch = useDispatch();

  const { data: campaignType } = useRequest(async () => getCampaignTypeList(dispatch), { manual: false });

  const columns = useMemo(() => getColumns({ t: Translation, keyword, styles }), [Translation, keyword, styles]);

  const filterConfig = useMemo(() => getFilterConfig(Translation, campaignType), [campaignType, Translation]);

  const getData = useCallback(
    async (
      params: { page: number; pageSize: number; current?: number | undefined; keyword?: string | undefined },
      sort?: { [key: string]: any },
      filter?: { [key: string]: any },
    ): Promise<RequestData<CampaignItem>> => getCampaignList(dispatch, params, sort, filter),
    [dispatch],
  );

  const onFilterChange = useCallback(
    (params: FilterState) => {
      const { keyword } = params.filterState;
      cacheParams && (cacheParams.current = params);
      setKeyword(keyword);
    },
    [cacheParams],
  );

  const onTypeSelected = (data: any) => {
    handleModalVisible(false);
    history.push(`${AGENCY_CAMPAIGN_LIST_PATH}/create/${data}`);
  };

  const onClickRow = (row: CampaignItem) => history.push(`${AGENCY_CAMPAIGN_LIST_PATH}/detail/${row.campaignId}`);

  return {
    Translation,
    filterConfig,
    cacheParams: cacheParams?.current,
    onFilterChange,
    columns,
    getData,
    createModalVisible,
    handleModalVisible,
    campaignTypeList: campaignType,
    onTypeSelected,
    onClickRow,
  };
};

import React, { memo, useMemo } from 'react';
import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { useProspectFilterTable } from './prospect-filter-table.hook';
import ProFilterTable, {
  ActionNodeType,
  ActionNodeTypeProps,
  CheckType,
  RowSelection,
} from 'src/app/common/components/ProTable';
import { Prospect } from '../../../types/prospect-types';
import { ProSearchFilter } from 'src/app/common/components/ProTable/components/pro-search-filter/pro-search-filter.component';
import { ActionNodeProps } from '../../list/components/action-items/action-items.hook';

type ComponentProps = ParamsProps & {
  cacheParams?: React.MutableRefObject<Record<string, any> | undefined>;
  rowSelection?: RowSelection<Prospect>;
  ActionNode?: React.ComponentType<ActionNodeProps>;
  defaultSelectedRows?: Prospect[];
  checkType?: CheckType;
  showActionBar?: boolean;
  onClickRow?: (row: Prospect) => void;
};

export const ProspectFilterTable: React.FC<ComponentProps> = memo((props: ComponentProps) => {
  const { filterConfig, cacheParams, onFilterChange, columns, getData, prospectCategories } = useProspectFilterTable({
    cacheParams: props.cacheParams,
  });
  const { ActionNode: InitActionNode, onClickRow } = props;

  const ActionNode: ActionNodeType<Prospect> = useMemo(() => {
    return (props: ActionNodeTypeProps<Prospect>) =>
      InitActionNode ? <InitActionNode {...props} prospectCategories={prospectCategories} /> : null;
  }, [prospectCategories, InitActionNode]);

  return (
    <ProFilterTable
      filterConfigs={filterConfig}
      filterState={cacheParams}
      rowKey="_id"
      rowSelection={props.rowSelection}
      Filter={ProSearchFilter}
      columns={columns}
      request={getData}
      enableRefresh={false}
      ActionNode={ActionNode}
      onFilterChange={onFilterChange}
      defaultSelectedRows={props.defaultSelectedRows}
      checkType={props.checkType}
      showActionBar={props.showActionBar}
      onClickRow={onClickRow}
    />
  );
});

import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useLang } from 'src/app/i18n';
import { useRequest } from 'ahooks';

import { ParamsProps } from 'src/app/common/components/ParamsProvider';
import { fetchCurrentAgentInfo } from 'src/app/modules/Auth/network/authCrud';
import { fetchCampaignDetail } from '../../../network/campaignCurd';
import {
  CampaignLeadItem,
  FollowUpStatusEnum,
  CampaignDetail,
  MassCampaignLeadItem,
} from '../../../types/campaign-types';
import { useHistory } from 'react-router-dom';

interface HookProps {
  id: string;
  // other params
}

export const useDetailPage = ({ id }: HookProps) => {
  const intl = useIntl();
  const Translation = (id: string) => intl.formatMessage({ id });
  const locale = useLang();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, error, loading, run, runAsync } = useRequest(() => fetchCampaignDetail(id, dispatch), {
    manual: true,
  });

  const convertLeadData = (data: any): MassCampaignLeadItem[] => {
    if (!data) return [];
    const leads: MassCampaignLeadItem[] = [];
    data.leads.forEach((lead: any) => {
      leads.push({
        _id: lead._id,
        name: lead.prospectDetail.alias,
        campaignName: data.name,
        status: lead.followUpStatus,
        updatedAt: lead.updatedAt,
        lastViewedAt: lead.lastViewedAt,
        feedback: lead.feedback,
      });
    });
    return leads;
  };

  const convertCampaignInfo = (data: any): CampaignDetail => {
    const newCampaignData: CampaignDetail = { ...data } as CampaignDetail;
    return newCampaignData;
  };

  useEffect(() => {
    run();
  }, []);

  const navToList = () => {
    history.goBack();
  };

  return {
    loading,
    data: convertCampaignInfo(data),
    leadsData: convertLeadData(data),
    run,
    navToList,
  };
};

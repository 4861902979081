import { CampaignElement } from '../../types/campaign-types';

export const queryData = (keyword: string, campaigns: CampaignElement[]) => {
  let res: CampaignElement[] = [];
  campaigns.forEach((item) => {
    if (item.name.includes(keyword)) res.push(item);
  });
  return res;
};

export const generateCompareFunction = (sortParams: {
  [key: string]: any;
}): ((a: CampaignElement, b: CampaignElement) => number) => {
  return function (a: CampaignElement, b: CampaignElement): number {
    for (const field in sortParams) {
      const sortOrder = sortParams[field];

      if (a[field as keyof CampaignElement] < b[field as keyof CampaignElement]) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (a[field as keyof CampaignElement] > b[field as keyof CampaignElement]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
};

export const generateSortFunction = <T>(sortParams: { [key: string]: any }): ((a: T, b: T) => number) => {
  return function (a: T, b: T): number {
    for (const field in sortParams) {
      const sortOrder = sortParams[field];

      if (a[field as keyof T] < b[field as keyof T]) {
        return sortOrder === 'asc' ? -1 : 1;
      } else if (a[field as keyof T] > b[field as keyof T]) {
        return sortOrder === 'asc' ? 1 : -1;
      }
    }
    return 0;
  };
};

export const filterData = (data: any[], filters: { [keys: string]: string[] }) => {
  return data.filter((item) => {
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const filterValues = filters[key];
        if (!filterValues.includes(item[key])) {
          return false;
        }
      }
    }
    return true;
  });
};

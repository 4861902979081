import { IntlShape } from 'react-intl';
import { ModuleHierachy } from '../../common/module/types';
import {
  PROSPECT_TITLE,
  PROSPECT_BASE_PATH,
  PROSPECT_NAMESPACE,
  PROSPECT_ADD_PROSPECT_PATH,
  SOURCE_TYPE,
  PROSPECT_DETAIL_PATH,
} from './constants';
import ProspectRoutes from './pages/ProspectRoutes';
import { isPermitted } from '../../common/permissions/permission-utils';

export const prospectModuleConfig = (permissions: string[], intl: IntlShape): ModuleHierachy => {
  const Translation = (id: string) => intl.formatMessage({ id });
  return {
    title: Translation(PROSPECT_TITLE),
    path: PROSPECT_BASE_PATH,
    component: ProspectRoutes,
    namespace: PROSPECT_NAMESPACE,
    disableMenu: false,
    disableRoleControl: true,
    enableRead: true,
    enableCreate: true,
    enableUpdate: true,
    page: [
      {
        title: Translation('prospect.add_new_prospect'),
        path: `${PROSPECT_BASE_PATH}${PROSPECT_ADD_PROSPECT_PATH}/${SOURCE_TYPE.NEW_PROSPECT}`,
      },
      {
        title: Translation('prospect.import_from_customers'),
        path: `${PROSPECT_BASE_PATH}${PROSPECT_ADD_PROSPECT_PATH}/${SOURCE_TYPE.EXISTING_CUSTOMER}`,
      },
      {
        title: 'Prospect detail',
        path: `${PROSPECT_BASE_PATH}${PROSPECT_DETAIL_PATH}`,
      },
    ],
  };
};
